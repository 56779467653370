<template>
    <div class="u-date-rage-picker">
         <date-range-picker
            v-model="dateRange"
            opens="right"
            :locale-data="localeDate"
            :timePicker="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            :linkedCalendars="true"
            :ranges="false"
            :append-to-body="appendToBody"
            @update="updateValues"
        >
            <template v-slot:input>
                <div :class="['u-date-rage-picker-input', { 'u-date-rage-picker-input-placeholder': dateRange.startDate == null || dateRange.endDate == null }]">
                    <p>{{ currentRange }}</p>
                    <i class="fas fa-caret-down"></i>
                </div>
            </template>
        </date-range-picker>
    </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment'
import 'moment/locale/pt-br';

export default {
    props: {
        value: {
            required: false
        },
        appendToBody: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: 'Início - Fim',
        },
    },

    components: {
        DateRangePicker
    },

    mounted() {
        if (this.value && this.value != '%%empty_data%%') {
            this.dateRange = this.value
        }
    },

    data() {
        return {
            dateRange: {
                startDate: null,
                endDate: null
            }
        }
    },

    computed: {
        localeDate() {
            return {
                direction: 'ltr',
                format: 'mm/dd/yyyy',
                separator: ' - ',
                weekLabel: 'W',
                daysOfWeek: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
                monthNames: [
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro',
                ],
                firstDay: 0,
            }
        },

        currentRange() {
            if (this.dateRange.startDate == null || this.dateRange.endDate == null) {
                return this.placeholder
            }

            return moment(this.dateRange.startDate).format('DD/MM/YY') + ' - ' + moment(this.dateRange.endDate).format('DD/MM/YY')
        }
    },

    methods: {
        updateValues(range) {
            this.dateRange = range
            this.$emit('change', this.dateRange)
        },

        diffInDays(startDate, endDate) {
            return moment(endDate).diff(startDate, 'days')
        },

        clear() {
            this.dateRange = {
                startDate: null,
                endDate: null
            }
        }
    }
}
</script>

<style lang="scss">

.u-date-rage-picker-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px; 
    padding: 0 12px;
    border: 1px solid $grey1;
    border-radius: 4px;
    background-color: $white-color;
    box-sizing: border-box;

    &.u-date-rage-picker-input-placeholder p {
        color: $grey4;
    }
}

.u-date-rage-picker .form-control {
    padding: 0;
    border: none;
}

.daterangepicker  {
    border: 1px solid $grey2;
    border-radius: 8px;

    &:before, &:after {
        display: none;
    }

    .calendar-table thead th {
        font-size: 13px !important;
    }

    .calendar-table td, 
    .calendar-table th {
        font-size: 11px;
    }

    &.show-ranges .drp-calendar.left {
        border-left: 1px solid $grey2;
    }

    td.in-range {
        background-color: $blue1;
    }

    td.active, 
    td.active:hover {
        background-color: $blue5;
    }

    .calendar-table th.prev.available span,
    .calendar-table th.next.available span {
        border-color: $grey6;
    }

    .drp-calendar.left .calendar-table th.next,
    .drp-calendar.right .calendar-table th.prev {
        opacity: 0;
        border: none !important;
    }
}

.fa-caret-down {
    color: $grey7;
}

</style>