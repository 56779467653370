<template>
    <div :class="['u-select', { 'u-select-active' : showOptions }]" v-on-clickaway="closeOptions">
        <div :class="['u-select-field', { 'u-select-field-disabled': disabled }]" :style="styleSelectField" @click="openOptions">
            <div class="u-select-field-value">
                <div class="w-100">
                    <div v-if="showOptions">
                        <input 
                            v-model="search"
                            :placeholder="placeholder" 
                            @input="searchItem"
                            ref="refAutocompleteInput" 
                        />
                    </div>
                    <p class="u-select-text" v-else-if="isObjectList && optionSelected" ellipsis>{{ optionSelected.text }}</p>
                    <p class="u-select-text" v-else-if="!isObjectList && optionSelected" ellipsis>{{ optionSelected }}</p>
                    <p class="u-select-text u-select-text-placeholder" v-else ellipsis>{{ placeholder }}</p>
                </div>

                <div v-if="optionSelected" @click.stop="selectOption(null)">
                    <u-icon
                        icon="close"
                        colorClass="grey6"
                        :width="16"
                        :height="16"
                    ></u-icon>
                </div>
            </div>
            <i v-if="!disabled" class="icon icon-down-arrow-filled icon8 grey7 ml-5"></i>
        </div>

        <transition name="fade">
            <div v-if="showOptions" class="u-select-options">
                <div v-if="isObjectList">
                    <div class="u-select-options-scroll">
                        <div 
                            v-for="(option, index) in optionsFilter" 
                            :key="index" 
                            @click="selectOption(option)" 
                            class="u-select-options-item"
                        >
                            <p class="u-select-text">{{ option.text }}</p>
                        </div>
                    </div>
                    <div v-if="fixedOption" @click="selectOption(fixedOption)" class="u-select-options-item u-select-options-item-fixed">
                        <p class="u-select-text">{{ fixedOption.text }}</p>
                    </div>
                </div>
                <div v-else>
                    <div class="u-select-options-scroll">
                        <div 
                            v-for="(option, index) in simpleOptionsFilter" 
                            :key="index" 
                            @click="selectOption(option)" 
                            class="u-select-options-item"
                        >
                            <p class="u-select-text">{{ option }}</p>
                        </div>
                    </div>
                    <div v-if="fixedOption" @click="selectOption(fixedOption)" class="u-select-options-item u-select-options-item-fixed">
                        <p class="u-select-text">{{ fixedOption }}</p>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {
    mixins: [clickaway],

    props: {
        value: {
            type: [String, Number],
            default: '',
        },

        options: {
            type: Array,
            default:() => null
        },

        simpleOptions: {
            type: Array,
            default:() => null
        },

        fixedOption: {
            type: [Object, String],
            default:() => null
        },

        width: {
            type: String,
            default: '100%',
        },
        
        placeholder: {
            type: String,
            default: '',
        },

        forceSelectValue: {
            type: Boolean,
            default: true
        },

        disabled: {
            type: Boolean,
            default: false
        },

        required: {
            type: Boolean,
            default: false
        }
    },

    mounted() {
        this.setCurrentValue()
    },

    data() {
        return {
            search: null,
            optionSelected: null,
            showOptions: false,
            timeout: null,
            simpleOptionsFilter: [],
        }
    },

    computed: {
        styleSelectField() {
            return `width:${this.width};`
        },
        
        isObjectList() {
            return this.options != null
        },
    },

    methods: {
        setCurrentValue() {
            if (this.isObjectList) {
                let value = this.options.find(option => option.value == this.value)
                this.optionSelected = value ? value : this.optionSelected
                this.search = this.optionSelected && typeof this.optionSelected == 'object' ? this.optionSelected.text : null
                this.optionsFilter = this.options
                return
            }

            this.optionSelected = this.value
            this.search = this.value
            this.simpleOptionsFilter = this.simpleOptions
        },

        selectOption(option) {
            this.optionSelected = option
            this.showOptions = false
            let value = this.isObjectList && this.optionSelected ? this.optionSelected.value : this.optionSelected
            this.$emit('input', value)
            this.$emit('change', value)
        },

        openOptions() {
            if (this.disabled) return
            this.showOptions = true

            this.$nextTick(() => {
                this.$refs.refAutocompleteInput.focus()
            })
        },

        closeOptions() {
            if (!this.showOptions) return
            this.showOptions = false

            if ((!this.forceSelectValue && !this.required) || (!this.forceSelectValue && this.required && this.search)) {
                this.$emit('input', this.search)
                this.$emit('change', this.search)
            }

            if (this.required) {
                this.setCurrentValue()
            }
        },

        filterList(option) {
            if (!option || !this.search) return 1

            let currentOption = this.isObjectList ? option.text : option

            const hasValue = (val) => (val != null ? val : '')

            const text = hasValue(currentOption)
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
            const query = hasValue(this.search)
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')

            return (
                text
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1
            )
        },

        searchItem() {
            clearTimeout(this.timeout)

            if (this.isObjectList) {
                this.timeout = setTimeout(() => {
                    this.optionsFilter = this.options.filter(this.filterList)
                }, 500)   

                return
            }

            this.timeout = setTimeout(() => {
                this.simpleOptionsFilter = this.simpleOptions.filter(this.filterList)
            }, 500)
        },

        clear() {
            this.selectOption(null)
        }
    },

    watch: {
        value() {
            this.setCurrentValue()
        },
    }
}
</script>

<style lang="scss" scoped>

input {
    margin: 0;
    outline: none;
    box-shadow: none;
    border: none !important;
    box-sizing: border-box;

    &::placeholder {
        color: $grey4;
    }
}

.u-select {
    position: relative;
    cursor: pointer;

    &.u-select-active .u-select-field {
        border-color: $grey3;
    }
}

.u-select-field {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: $white-color;
    transition: all .2s;
    box-sizing: border-box;

    &:hover {
        border-color: $grey3;
    }

    &.u-select-field-disabled:hover {
        border-color: transparent !important;
    }

    .u-select-field-value {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;

        input {
            font-size: 15px;
        }
    }
}

.u-select-options-scroll {
    width: 100%;
    max-height: 240px;
    overflow-y: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        margin-top: 3px;
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #e4e6e7;
    }

    &::-webkit-scrollbar-thumb {
        background: #aaadaf;
        border-radius: 50px;
        background-clip: padding-box;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #a5aab0;
    }
}

.u-select-options {
    position: absolute;
    width: 100%;
    box-shadow: $shadow-3;
    background-color: $white-color;
    border-radius: 4px;
    z-index: 99999;

    .u-select-options-item {
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        transition: all .2s;
        box-sizing: border-box;

        &:hover {
            background-color: $grey0;
        }

        &.u-select-options-item-fixed {
            border-top: 1px solid $grey2;
        }
    }
}

.u-select-text {
    font-size: 15px;

    &.u-select-text-placeholder {
        color: $grey4;
    }
}

.fa-caret-down {
    color: $grey7;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

</style>