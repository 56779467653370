<template>
    <div class="u-audio-player">
        <span class="u-audio-player-controls" @click="handlePlay" ref="refPlay">
            <svg v-if="!playing" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0)"><path d="M22.043 11.175a.952.952 0 010 1.65L2.91 23.87a.953.953 0 01-1.429-.825V.954A.953.953 0 012.91.129l19.133 11.046z" class="u-audio-player-controls-icons"/></g><defs><clipPath id="clip0"><path fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>
            <svg v-else width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.966.342a.32.32 0 01.32-.32h3.36a.32.32 0 01.32.32V23.68a.32.32 0 01-.32.32h-3.36a.32.32 0 01-.32-.32V.342zM15.998.342a.32.32 0 01.32-.32h3.36a.32.32 0 01.32.32V23.68a.32.32 0 01-.32.32h-3.36a.32.32 0 01-.32-.32V.342z" class="u-audio-player-controls-icons"/></svg>
        </span>

        <div class="w-100">
            <div class="w-100 d-flex">
                <p v-if="currentTime" class="u-audio-player-time mr-3">{{ currentTime }}</p>

                <input
                    type="range"
                    step="1"
                    class="seekbar"
                    value="0"
                    min="0"
                    max="100"
                    @input="handleSeekBar"
                    ref="refSeekbar"
                />
                <audio class="music-element" ref="refMusicElement">
                    <source :src="file.link" :type="mimeType" />
                </audio>

                <p v-if="duration" class="u-audio-player-time ml-3">{{ duration }}</p>
            </div>

            <p v-if="fileName" class="u-audio-player-name">{{ fileName }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        file: {
            required: true,
        },
    },

    data() {
        return {
            music: null,
            playBtn: null,
            seekbar: null,
            currentTime: '0:00',
            duration: null,
            playing: false,
        }
    },

    mounted() {
        this.startPlayer()
    },

    destroyed() {
        this.music.removeEventListener('timeupdate', this.timeUpdateListener, false)
        this.music.removeEventListener('ended', this.endedListener)
    },

    computed: {
        mimeType() {
            if (this.file.type == 'mp3') {
                return 'audio/mpeg'
            }

            if (this.file.type == 'mp4') {
                return 'audio/mp4'
            }

            if (this.file.type == 'wav') {
                return 'audio/wav'
            }

            if (this.file.type == 'aac') {
                return 'audio/aac'
            }

            if (this.file.type == 'm4a') {
                return 'audio/x-m4a'
            }

            return 'audio/*'
        },

        fileName() {
            if (!this.file.name) {
                return ''
            }

            let name =
                this.file.name.length < 50
                    ? this.file.name
                    : this.file.name.substr(0, 50) + '..'
            let type = this.file.type || ''

            return `${name}.${type}`
        },
    },

    methods: {
        startPlayer() {
            let vm = this

            this.music = this.$refs.refMusicElement
            this.playBtn = this.$refs.refPlay
            this.seekbar = this.$refs.refSeekbar

            this.music.onloadeddata = function () {
                vm.seekbar.max = vm.music.duration
                let ds = vm.formatSeconds(vm.music.duration)       
                let dm = parseInt((vm.music.duration / 60) % 60)
                vm.duration = dm + ':' + ds
            }

            this.music.ontimeupdate = function () {
                vm.seekbar.value = vm.music.currentTime
            }

            this.music.addEventListener('timeupdate', this.timeUpdateListener, false)
        },

        handlePlay() {
            let vm = this

            if (this.music.paused) {
                this.music.play()
                this.playing = true
            } else {
                this.music.pause()
                this.playing = false
            }

            this.music.addEventListener('ended', this.endedListener)
        },

        handleSeekBar() {
            this.music.currentTime = this.seekbar.value
        },

        timeUpdateListener() {
            let cs = this.formatSeconds(this.music.currentTime)
            let cm = parseInt((this.music.currentTime / 60) % 60)
            this.currentTime = cm + ':' + cs
        },

        endedListener() {
            this.playing = false
            this.music.currentTime = 0
        },

        formatSeconds(time) {
            let seconds = parseInt(time % 60)
            return seconds < 10 ? `0${seconds}` : seconds
        }
    },
}
</script>

<style lang="scss" scoped>

.u-audio-player {
    height: 72px;
    display: flex;
    align-items: center;
    padding: 12px;
    margin: 12px 24px 0 24px;
    border-radius: 8px;
    background-color: $grey1;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;

    .u-audio-player-controls {
        padding-left: 4px;
        margin-right: 12px;
        cursor: pointer;

        .u-audio-player-controls-icons {
            fill: $grey6;
        }
    }

    input[type='range'] {
        width: 80%;
        margin: 0px;
        padding: 0px;
        background: $grey6; 
        height: 4px;
        outline: none;
        cursor: pointer;
        overflow: hidden;
        border-radius: 5px;
        transition: all .3s;
        -webkit-appearance: none !important;
    }

    input[type='range']::-ms-fill-lower,
    input[type='range']::-ms-fill-upper,
    input[type='range']::-moz-range-track {
        border: none;
        background: $blue5;
    }

    input[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none !important;
        background: $white-color;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        box-shadow: -100vw 0 0 100vw $blue5;
    }

    input[type='range']::-moz-range-thumb,
    input[type='range']::-ms-thumb {
        -webkit-appearance: none !important;
        background: $white-color;
        height: 8px;
        width: 8px;
        border-radius: 100%;
    }

    .u-audio-player-time {
        font-size: 14px;
        color: $grey6;
        min-width: 1.8rem;
        word-break: initial;
    }

    .u-audio-player-name {
        font-size: 12px;
        color: $grey6;
    }
}

.mr-3 {
    margin-right: 8px;
}

.ml-3 {
    margin-left: 8px;
}
</style>