<template>
    <div class="register">
        <div v-if="appSettings" class="register-container">
            <!-- CAPA -->
            <div class="register-cover" :style="coverBackground">
                <div class="mask-logo">
                    <u-avatar
                        :size="2"
                        :organization="appSettings"
                    ></u-avatar>
                </div>

                <p v-if="hasPreference('loginScreen', 'title') && preferences.loginScreen.show"
                    class="register-welcome"
                >
                    {{ preferences.loginScreen.title }}
                </p>

                <p v-if="hasPreference('loginScreen', 'description') && preferences.loginScreen.show"
                    class="register-description"
                >
                    {{ preferences.loginScreen.description }}
                </p>
            </div>

            <!-- REGISTER -->
            <div class="register-form">
                <div class="app-register">
                    <div class="app-register-header">
                        <p> Cadastre-se na área do cliente </p>
                    </div>

                    <div v-if="!showCode" class="app-register-fields" v-shortkey="['enter']" @shortkey="toCode">
                        <p class="register-tip">
                            Insira seu nome e e-mail para continuar
                        </p>

                        <u-input
                            :model="true"
                            @getModel="getName"
                            placeholder="Insira seu nome"
                        ></u-input>

                        <u-input
                            :model="true"
                            @getModel="getEmail"
                            style="margin: 12px 0;"
                            placeholder="Insira seu e-mail"
                        ></u-input>

                        <p
                            class="error-text"
                            v-if="errorMsg"
                            v-html="errorMsg"
                        ></p>

                        <div class="button-terms">
                            <u-checkbox
                                :theme="'mini'"
                                id="term-checker"
                                @input="checkTerm"
                            ></u-checkbox>

                            <p>
                                Li e concordo com os <span @click="dialogTerms = true">Termos de uso e políticas de
                                privacidade e proteção de dados pessoais</span> da
                                <strong>{{ !hasPreference('organization', 'name') ? appSettings.company_name : preferences.organization.name }}</strong>
                            </p>
                        </div>

                        <div @click="toCode">
                            <u-button
                                text="Continuar"
                            ></u-button>
                        </div>
                    </div>

                    <div v-if="showCode" class="app-register-fields" v-shortkey="['enter']" @shortkey="validateCode">
                        <p class="register-tip">
                            Digite o código que enviamos para o seu e-mail.
                        </p>

                        <u-code
                            class="app-code"
                            @getNumber="getNumber"
                        ></u-code>

                        <div class="button-resend-code" @click="resendCode">
                            <p> Reenviar o código </p>
                        </div>

                        <p
                            class="error-text"
                            v-if="errorMsg"
                            v-html="errorMsg"
                        ></p>

                        <div @click="validateCode">
                            <u-button
                                text="Continuar"
                            ></u-button>
                        </div>
                    </div>

                    <div class="app-register-footer">
                        <div class="title-new-user">
                            <div></div>

                            <p>
                                Já possui cadastro?
                            </p>

                            <div></div>
                        </div>
                        
                        <router-link :to="{ name: 'login' }">
                            <u-button
                                type="slim"
                                text="Acesse sua conta"
                                color="#D4EDFA"
                                textColor="#038DD2"
                            ></u-button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="storeLinks.appStoreLink && storeLinks.googlePlayLink" class="register-footer">
            <u-text :size="1"> Também disponível: </u-text>
            
            <div class="footer-images">
                <a :href="storeLinks.appStoreLink" target="_blank">
                    <img class="left" src="/images/app-store.png"/>
                </a>

                <a :href="storeLinks.googlePlayLink" target="_blank">
                    <img class="right" src="/images/google-play.png"/>
                </a>
            </div>
        </div>

        <modal-terms
            v-if="dialogTerms"
            :dialog="dialogTerms"
            @acceptTerms="acceptTerms"
            @closeModal="dialogTerms = false"
        ></modal-terms>

        <loading
            :active.sync="isLoading"
            color="#208fcf"
            :width="100"
            :height="100"
        ></loading>
    </div>
</template>

<script>
    import { hasPreference } from "@/assets/js/helpers/index.js";
    import { mapGetters, mapActions } from 'vuex'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import UAvatar from '@/assets/js/components/UI/avatar/UAvatar'
    import UInput from '@/assets/js/components/UI/input/UInput'
    import UCode from '@/assets/js/components/UI/input/UCode'
    import UCheckbox from '@/assets/js/components/UI/button/UCheckbox'
    import UButton from '@/assets/js/components/UI/button/UButton'
    import ModalTerms from './ModalTerms'

    export default {
        components: {
            Loading,
            UAvatar,
            UInput,
            UCode,
            UCheckbox,
            UButton,
            ModalTerms
        },

        data() {
            return {
                registerName: null,
                registerEmail: null,
                showCode: false,
                validateNumber: null,
                isLoading: false,
                errorMsg: null,
                dialogTerms: false,
                termChecked: false,
            }
        },

        computed: {
            ...mapGetters("Auth", {
                appSettings: "getAppSettings",
                slug: "getSlug",
                preferences: "getPreferences",
                storageUrl: 'getStorageUrl',
                orgId: 'getOrgId',
                storeLinks: 'getStoreLinks' 
            }),

            coverBackground() {
                let preferences = this.preferences.appearance
                let defaultBackground = 'background: url(../images/cover-image-small.jpg)'

                if (!preferences) {
                    return defaultBackground
                }

                if (preferences.hasCustomAppearance) {
                    return 'background: url("https://ummense-objects.s3.amazonaws.com/uapp/custom/org_' + this.orgId + '_login_bg.jpg")'
                }

                if (preferences.solidColor) {
                    return 'background-color: ' + preferences.backgroundHex
                }

                if (!preferences.backgroundHex || !preferences.backgroundIndex) {
                    return defaultBackground
                }

                return 'background: url(' + this.storageUrl +
                    preferences.backgroundIndex + '/portrait_' + preferences.backgroundColor + '.jpg)'
            }
        },

        created() {
            this.checkRegisterAllow()
        },

        methods: {
            hasPreference,

            ...mapActions("Auth", [
                "register",
                "checkCode",
                "resendValidationCode",
                "validateTerm"
            ]),

            checkRegisterAllow() {
                if (this.hasPreference('app', 'allowNewUsers') && !this.preferences.app.allowNewUsers) {
                    this.$router.push({name: 'login'})
                }
            },

            toCode() {
                if (this.isLoading) return

                if (!this.termChecked) {
                    return this.errorMsg = 'Aceite os termos e políticas do aplicativo'
                }

                this.isLoading = true
                this.errorMsg = null

                let payload = {
                    email: this.registerEmail,
                    name: this.registerName
                }

                this.register(payload).then(() => {
                    this.isLoading = false
                    this.showCode = true
                }).catch(() => {
                    this.isLoading = false
                    this.errorMsg = 'Preencha os campos corretamente'
                })
            },

            validateCode() {
                if (this.isLoading) return

                this.isLoading = true

                let payload = {
                    email: this.registerEmail,
                    code: this.validateNumber,
                    isRegister: true
                }

                let homeScreen =
                        hasPreference('app', 'homeScreen') && this.preferences.app.homeScreen == 'form'
                        ? 'make-requests'
                        : 'feed'

                this.checkCode(payload).then(() => {
                    this.isLoading = false
                    this.$router.push({name: homeScreen})
                }).catch(() => {
                    this.isLoading = false
                    this.errorMsg = 'Código inválido'
                })
            },

            resendCode() {
                this.isLoading = true

                this.resendValidationCode(this.registerEmail).then(() => {
                    this.$toast.open({
                        message: 'Código enviado com sucesso'
                    })

                    this.isLoading = false
                })
            },

            getNumber(number) {
                this.validateNumber = number
            },

            getName(value) {
                this.registerName = value
                this.errorMsg = null
            },

            getEmail(value) {
                this.registerEmail = value
                this.errorMsg = null
            },

            checkTerm(bool) {
                this.termChecked = bool
            },

            acceptTerms(terms) {
                if (!this.termChecked) {
                    document.getElementById("term-checker").click()
                }

                this.dialogTerms = false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .register {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: $grey1;

        @media (max-width: 900px) {
            justify-content: flex-start;
        }

        .register-container {
            display: flex;
            align-items: center;

            @media (max-width: 900px) {
                flex-direction: column;
                max-width: 100%;
                max-height: 80%;
            }

            .register-cover {
                width: 360px;
                height: 550px;
                padding: 30px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                border-radius: 8px 0px 0px 8px;
                margin-right: 3px;
                background-size: cover !important;
                background-position-x: center !important;
                background-repeat: no-repeat !important;

                @media (max-width: 900px) {
                    padding: 0;
                    width: 100%;
                    border-radius: 8px 8px 0 0;
                    min-height: 265px;
                }

                .mask-logo {
                    border: 2px solid #FFF;
                    border-radius: 50%;
                }

                .register-welcome {
                    margin-top: 12px;
                    font-size: 22px;
                }

                .register-welcome,
                .register-description {
                    max-width: 100%;
                    word-break: break-word;
                }

                p {
                    text-align: center;
                    padding: 10px;
                    color: $white-color;
                }
            }

            .register-form {
                width: 420px;
                height: 575px;
                display: flex;
                align-items: center;
                background-color: #FFF;
                box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1);

                @media (max-width: 900px) {
                    background-color: transparent;
                    box-shadow: none;
                    max-width: 100%;
                }

                .app-register {
                    width: 100%;
                    padding: 50px;

                    @media (max-width: 900px) {
                        padding: 20px 0 0 0 !important;
                    }

                    .app-register-header {
                        border-bottom: 1px solid #D3D8DE;

                        p {
                            font-size: 18px;
                            padding-bottom: 8px;
                        }
                    }

                    .app-register-fields {
                        display: flex;
                        flex-direction: column;
                        margin: 14px 0;

                        .register-tip {
                            margin-top: 14px;
                            margin-bottom: 18px;
                            font-size: 14px;
                        }

                        .app-code {
                            margin: 12px 0;
                        }

                        .button-resend-code {
                            cursor: pointer;
                            margin-bottom: 12px;
                            display: flex;
                            align-items: center;

                            p {
                                color: $blue5;

                                &:hover {
                                    color: $blue3;
                                }
                            }
                        }

                        .button-terms {
                            display: flex;
                            align-items: flex-start;
                            margin-bottom: 12px;

                            p {
                                font-size: 14px;
                                margin-left: 8px;
                                max-width: 90%;

                                span {
                                    color: $blue5;
                                    cursor: pointer;
                                }
                            }
                        }

                        .error-text {
                            background-color: $pink1;
                            border: 1px solid $pink5;
                            color: $pink5;
                            border-radius: 5px;
                            padding: 5px 10px;
                            margin-bottom: 12px;
                            font-size: 14px;
                        }
                    }

                    .app-register-footer{
                        margin-top: 24px;
                    
                        p {
                            font-size: 14px;
                            color: $grey5;
                        }

                        .title-new-user { 
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 16px;
                            
                            >div { 
                                height: 2px;
                                width: 30%;
                                background-color: $grey2;
                                border-radius: 4px;
                            }
                        }

                        a {
                            cursor: pointer;
                            color: $blue5;
                            text-decoration: none;

                            &:hover {
                                color: $grey8;
                            }
                        }
                    }
                }
            }
        }

        .register-footer {
            position: absolute;
            bottom: 10px;

            .footer-images { 
                display: flex;
                align-items: center;

                img { 
                    cursor: pointer;
                    width: 98px;
                    margin: 0 3px;

                    &.left { 
                        padding-top: 6px;
                    }
                }
            }
        }
    }
</style>