<template>
    <div class="side-menu-filter-empty">
        <div class="side-menu-filter-empty-icon">
            <i class="icon icon-magnifier-error grey3"></i>
        </div>

        <u-title size="2" color="grey8" class="mb-3">Nenhum resultado encontrado</u-title>
        <u-text size="2" color="grey6">Revise a palavra-chave e tente novamente.</u-text>

        <div class="d-flex cursor-pointer mt-11" @click="$emit('clear-search')">
            <i class="icon icon-left-arrow-2 icon12 blue5 mr-3"></i>
            <u-text color="blue5">Voltar</u-text>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

.side-menu-filter-empty {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: $spacing-4;
    padding-bottom: $spacing-5;
}

.side-menu-filter-empty-icon {
    height: 72px;
    width: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $spacing-5;
    background-color: $grey1;
    border-radius: 50%;

    i {
        font-size: 48px;
    }
}

</style>