import store from '@/store/index'
import axios from 'axios'

export default {
    uploadFile: ({}, payload) => {
        return new Promise((resolve, reject) => {
            axios.delete(store.state.Auth.baseAppUri + '/u-drive/' + store.state.Auth.orgId + '/' + payload.form,
                {file: payload.file},
                {headers: {'Authorization': 'Bearer ' + store.state.Auth.token}}
            ).then(() => {
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },

    /**
     * Deleta um arquivo da base de dados
     * @version 0.0.1
     * @param {Object} file arquivo à ser deletado
     * @return {Promise}
     */
    deleteUploadedFile: ({}, payload) => {
        return new Promise((resolve, reject) => {
            axios.delete(store.state.Auth.baseAppUri + '/u-drive/' + payload + '/delete',
                {headers: {'Authorization': 'Bearer ' + store.state.Auth.token}}
            ).then(() => {
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },

    /**
    * Adiciona um novo arquivo na lista do Vuex
    * @version 0.0.1
    * @param {Object} payload arquivo à ser adicionado
    */
    addFile: ({ commit }, payload) => {
        commit('addFile', payload)
    },

    /**
    * Remove um arquivo da lista do Vuex
    * @version 0.0.1
    * @param {Object} payload arquivo à ser removido
    */
    removeFile: ({ commit }, payload) => {
        commit('removeFile', payload)
    },


    /**
    * Atualiza o filtro de arquivos atual
    * @version 0.0.1
    * @param {Array} payload dados do filtro
    */
    updateFilesFilter: ({ commit }, payload) => {
        commit('setFilesFilter', payload)
    },

    /**
    * Limpa o filtro de arquivos e atribui os valores iniciais
    * @version 0.0.1
    * @param {Array} payload dados do filtro
    */
    clearFilesFilter: ({ commit }) => {
        commit('clearFilesFilter')
    },

    /**
    * Faz o download do arquivo atual através do Axios
    * @version 0.0.1
    * @param {Object} payload arquivo atual
    * @return {Promise}
    */
    downloadFile: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios.get('/u-drive/d/' + payload.uuid,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                responseType: 'blob'
            })
            .then((response) => {
                let url = window.URL.createObjectURL(new Blob([response.data])),
                    link = document.createElement('a');

                link.href = url;
                link.setAttribute('download', payload.name + '.' + payload.type)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

                resolve()
            }).catch(({response}) => {
                commit('setAlertMessage', { message: response.data.message, error: true }, { root: true })
                reject()
            })
        })
    },

    /**
    * Atualiza o arquivo atual
    * @version 0.0.1
    * @param {Object} payload = arquivo atual
    * @return {Promise}
    */
    updateFile: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios.patch('/api/organization/files/' + payload.uuid, payload)
            .then(() => {
                resolve()
            }).catch(({response}) => {
                commit('setAlertMessage', { message: response.data.message, error: true }, { root: true })
                reject()
            })
        })
    },

    /**
    * Comentário da funcionalidade
    * @version 0.0.1
    * @param {Array} payload payload.file = arquivo atual | payload.fileCategories = categorias do arquivo
    * @return {Promise}
    */
   syncFileCategories: ({ commit }, payload) => {
       return new Promise((resolve, reject) => {
           axios.patch('/api/organization/files/' + payload.file.uuid + '/sync-categories', payload)
           .then(() => {
               resolve()
           }).catch(() => {
               reject()
           })
       })
   },

   /**
   * Atualiza os dados do arquivo atual no Vuex
   * @version 0.0.1
   * @param {Object} payload = dados do arquivo atual
   * @return {Promise}
   */
   updateVuexFile: ({ commit }, payload) => {
       return new Promise((resolve) => {
           commit('updateFileData', payload)
           resolve()
       })
   },

    /**
    * Atualiza o status da modal de limite de espaço atingido (caso estiver aberta ela fecha, do contrário abre)
    * @author Lucas de Melo Aguirre
    * @version 0.0.1
    */
    updateModalLimitReached: ({ commit }) => {
        commit('setModalLimitReached')
    },
}