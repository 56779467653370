<template>
    <button
        @click="sendClick"
        :class="['button-default', { 'button-border' : hasBorder }, buttonType]"
        :style="buttonStyle"
        :disabled="disabled"
    >
        <i :class="icon" v-if="hasIcon"></i>

        <span v-if="!loading">{{ text }}</span>

        <div v-else class="button-default-loading">
            <img src="/images/loading-ummense.gif" />
        </div>
    </button>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: 'Pressione',
        },

        textColor: {
            type: String,
            default: '#FFF',
        },

        color: {
            type: String,
            default: '#208fcf',
        },

        hasIcon: {
            default: false,
            type: Boolean,
        },

        icon: {
            type: String,
            required: false,
        },

        hasBorder: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        loading: {
            type: Boolean,
            default: false,
        },

        type: { 
            type: String,
            default: 'regular'
        }
    },

    computed: {
        buttonStyle() {
            let background = 'background-color: ' + this.color + ';'

            let text = 'color: ' + this.textColor + ';'

            let borderColor = 'border-color: ' + this.textColor + ';'

            return background + text
        },

        buttonType() {
            if (this.type != 'regular' 
                && this.type != 'slim'
                && this.type != 'large'
            ) { 
                return 'regular'
            } 

            return this.type
        }
    },

    methods: {
        sendClick() {
            this.$emit('click')
        },
    },
}
</script>

<style lang="scss" scoped>
.button-default {
    cursor: pointer;
    width: 100%;
    height: 48px;
    border-radius: 4px;
    border: none;
    outline: none !important;
    padding: 10px 20px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.slim { 
        height: 28px;
        font-size: 13px;
    }

    &.button-border {
        border: 1px solid;
    }

    &:disabled {
        background-color: $grey2;
    }

    svg {
        margin-right: 0.6rem;
    }

    &:hover {
        opacity: 0.8;
    }

    .button-default-loading {
        width: 24px;
        height: 24px;

        img {
            width: 100%;
        }
    }
}
</style>