<template>
    <div class="my-requests-filter">
        <p class="my-requests-filter-label">Status</p>
        <div class="my-requests-filter-status">
            <div 
                @click="filter.status = ['active', 'waiting', 'completed', 'canceled']" 
                :class="['my-requests-filter-status-btn', { 
                    'my-requests-filter-status-btn-active': filter.status.length == 4 && filter.status.includes('active', 'waiting', 'completed', 'canceled') 
                }]">
                <p>TODOS</p>
            </div>
            <div 
                @click="filter.status = ['active', 'waiting']" 
                :class="['my-requests-filter-status-btn', { 
                    'my-requests-filter-status-btn-active': filter.status.length == 2 && filter.status.includes('active', 'waiting') 
                }]">
                <p>ATIVO</p>
            </div>
            <div 
                @click="filter.status = ['completed']" 
                :class="['my-requests-filter-status-btn', { 
                    'my-requests-filter-status-btn-active': filter.status.length == 1 && filter.status.includes('completed') 
                }]">
                <p>CONCLUÍDO</p>
            </div>
            <div 
                @click="filter.status = ['canceled']" 
                :class="['my-requests-filter-status-btn', { 
                    'my-requests-filter-status-btn-active': filter.status.length == 1 && filter.status.includes('canceled') 
                }]">
                <p>CANCELADO</p>
            </div>
        </div>

        <div class="my-requests-filter-columns">
            <div>
                <p class="my-requests-filter-label">Data de solicitação</p>
                <u-date-range-picker @change="setDateRange" placeholder="Selecionar período" ref="refUDateRangePicker"></u-date-range-picker>
            </div>
            <div>
                <p class="my-requests-filter-label">Formulário</p>
                <u-autocomplete 
                    v-model="filter.form"
                    placeholder="Selecionar formulário"
                    :options="forms"
                    ref="refFormField"
                ></u-autocomplete>
            </div>
        </div>

        <p class="my-requests-filter-label" style="margin-top: 16px;">Feed</p>
        <u-autocomplete 
            v-model="filter.project"
            placeholder="Selecionar feed"
            :options="cards" 
            ref="refFeedField"
        ></u-autocomplete>
        
        <div class="my-requests-filter-buttons">
            <div @click="clearFilter">
                <u-button text="Limpar" color="#FFFFFF" text-color="#76828B"></u-button>
            </div>
            <div @click="applyFilter">
                <u-button text="Filtrar"></u-button>
            </div>
        </div>
    </div>
</template>

<script>
import UAutocomplete from '@/assets/js/components/UI/input/UAutocomplete'
import UDateRangePicker from "@/assets/js/components/UI/pickers/UDateRangePicker"
import UButton from '@/assets/js/components/UI/button/UButton'

export default {
    props: {
        openFilter: {
            type: Boolean,
            default: false
        },

        formResponses: {
            type: Array,
            default: () => []
        }
    },

    components: {
        UAutocomplete,
        UDateRangePicker,
        UButton,
    },

    computed: {
        filterCounter() {
            let status = this.filter.status.length != 4 ? 1 : 0
            let date = this.filter.startDate != null && this.filter.endDate != null ? 1 : 0
            let project = this.filter.project != null ? 1 : 0
            let form = this.filter.form != null ? 1 : 0

            return status + date + project + form
        }
    },

    data() {
        return {
            cards: [],
            forms: [],
            filter: {
                status: ['active', 'waiting', 'completed', 'canceled'],
                startDate: null,
                endDate: null,
                project: null,
                form: null,
            }
        }
    },

    methods: {
        setFormsAndCards() {
            this.formResponses.map(formResponse => {
                let cardIndex = this.cards.findIndex(card => (formResponse.model != null && formResponse.model.type == 'projects' && formResponse.model.id == card.value))
                let formIndex = this.forms.findIndex(form => (formResponse.postFormResponse && formResponse.postFormResponse.formId == form.value))

                if (cardIndex < 0 && formResponse.model) {
                    this.cards.push({ text: formResponse.model.name, value: formResponse.model.id })
                }

                if (formIndex < 0) {
                    this.forms.push({ text: formResponse.postFormResponse.uWebFormName, value: formResponse.postFormResponse.formId })
                }
            })
        },

        setDateRange(dateRange) {
            this.filter.startDate = dateRange.startDate
            this.filter.endDate = dateRange.endDate
        },

        clearFilter() {
            this.filter.status = ['active', 'waiting', 'completed', 'canceled']
            this.filter.startDate = null
            this.filter.endDate = null
            this.filter.project = null
            this.filter.form = null
            this.$emit('update-responses')

            if (this.$refs.refUDateRangePicker) {
                this.$refs.refUDateRangePicker.clear()
            }

            if (this.$refs.refFormField) {
                this.$refs.refFormField.clear()
            }

            if (this.$refs.refFeedField) {
                this.$refs.refFeedField.clear()
            }            
        },

        applyFilter() {
            this.$emit('update-responses')
        },
    },

    watch: {
        openFilter() {
            if (!this.openFilter) return
            this.setFormsAndCards()
        }
    }
}
</script>

<style lang="scss" scoped>

.my-requests-filter {
    padding: 16px 8px;
    margin-right: 24px;
    margin-left: 24px;
    margin-bottom: 32px;
    border-radius: 8px;
    background-color: $grey1;
}

.my-requests-filter-columns {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    margin-top: 16px;

    > div {
        width: 49%;

        @media (max-width: 600px) {
            width: 100%;

            &:last-of-type {
                margin-top: 16px;
            }
        }
    }
}

.my-requests-filter-label {
    font-size: 13px;
    color: $grey6;
    margin-bottom: 4px;
}

.my-requests-filter-status {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 600px) {
        flex-wrap: wrap;
    }
}

.my-requests-filter-status-btn {
    height: 24px;
    width: 23.9%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $grey5;
    border: 1px solid transparent;
    border-radius: 20px;
    background-color: $white-color;
    cursor: pointer;

    @media (max-width: 600px) {
        width: 48%;

        &:nth-of-type(3),
        &:nth-of-type(4) {
            margin-top: 8px;
        }
    }

    &.my-requests-filter-status-btn-active {
        color: $blue5;
        border-color: $blue5;
        background-color: $blue0;
    }

    p {
        font-size: 13px;
    }
}

.my-requests-filter-buttons {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-top: 16px;

    > div {
        width: 100%;
        max-width: 137.5px;
        @media (max-width: 600px) {
            max-width: 110px;
        }

        &:last-of-type {
            margin-left: 24px;
            @media (max-width: 600px) {
                margin-left: 8px;
            }
        }
    }
}

</style>