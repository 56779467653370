<template>
    <div class="contact-notification-preferences">
        <p class="notification-title"> 
            Preferências de notificações
        </p>

        <div class="label-options">
            <p> E-mail </p>

            <p style="margin-left: 12px;"> Notificações </p>
        </div>

        <div class="preference-option">
            <p> Atualizações sobre {{ !hasPreference('organization', 'name') ? appSettings.company_name : preferences.organization.name }} </p>

            <div class="actions">
                <u-checkbox
                    :theme="'mini'"
                    id="term-checker"
                ></u-checkbox>

                <u-checkbox
                    :theme="'mini'"
                    id="term-checker"
                    v-model="alo"
                ></u-checkbox>
            </div>
        </div>

        <div class="preference-option">
            <p> Promoções da {{ !hasPreference('organization', 'name') ? appSettings.company_name : preferences.organization.name }} </p>

            <div class="actions">
                <u-checkbox
                    :theme="'mini'"
                    id="term-checker"
                ></u-checkbox>

                <u-checkbox
                    :theme="'mini'"
                    id="term-checker"
                    v-model="alo"
                ></u-checkbox>
            </div>
        </div>

        <div class="preference-option">
            <p> Upload de novos arquivos no U-drive </p>

            <div class="actions">
                <u-checkbox
                    :theme="'mini'"
                    id="term-checker"
                ></u-checkbox>

                <u-checkbox
                    :theme="'mini'"
                    id="term-checker"
                    v-model="alo"
                ></u-checkbox>
            </div>
        </div>

        <div class="preference-option">
            <p> Novas publicações </p>

            <div class="actions">
                <u-checkbox
                    :theme="'mini'"
                    id="term-checker"
                ></u-checkbox>

                <u-checkbox
                    :theme="'mini'"
                    id="term-checker"
                    v-model="alo"
                ></u-checkbox>
            </div>
        </div>

        <div class="preference-option">
            <p> Respostas de publicações </p>

            <div class="actions">
                <u-checkbox
                    :theme="'mini'"
                    id="term-checker"
                ></u-checkbox>

                <u-checkbox
                    :theme="'mini'"
                    id="term-checker"
                    v-model="alo"
                ></u-checkbox>
            </div>
        </div>

        <div class="preference-option">
            <p> Atualização no sstatus de solicitações </p>

            <div class="actions">
                <u-checkbox
                    :theme="'mini'"
                    id="term-checker"
                ></u-checkbox>

                <u-checkbox
                    :theme="'mini'"
                    id="term-checker"
                    v-model="alo"
                ></u-checkbox>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import UCheckbox from '@/assets/js/components/UI/button/UCheckbox'
    import { hasPreference } from "@/assets/js/helpers/index.js";

    export default {
        components: { 
            UCheckbox
        },

        data() { 
            return { 
                alo: true,
            }
        },

        computed: { 
            ...mapGetters("Auth", { 
                appSettings: "getAppSettings",
                preferences: "getPreferences",
            })
        },

        methods: { 
            hasPreference,
        }
    }
</script>

<style lang="scss" scoped>
    .contact-notification-preferences {
        margin-top: 24px;
        background-color: $white-color;
        padding: 24px;
        border-radius: 8px;
        width: 560px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        -webkit-touch-callout: none;  
        -webkit-user-select: none;  
        -khtml-user-select: none;    
        -moz-user-select: none;     
        -ms-user-select: none;        
        user-select: none;    

        .notification-title { 
            color: $grey8;
            font-weight: 600;
            font-size: 19px;
        }

        .label-options { 
            width: 100%;
            display: flex;
            justify-content: flex-end;
            font-size: 16px;
            color: $grey6;
        }

        .preference-option {
            margin-top: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .actions { 
                width: 21%;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
</style>