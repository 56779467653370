<template>
    <div class="side-menu-header-filter">
        <div class="side-menu-header-filter-header">
            <div class="side-menu-header-filter-header-back" @click="$emit('close-filter')">
                <i class="icon icon-left-arrow-2 icon12"></i>
            </div>

            <u-title size="2" color="grey8" :margin="false">Filtro</u-title>
        </div>

        <u-title size="3" color="grey6" class="mt-4 mb-5">Status</u-title>

        <div class="side-menu-header-filter-header-status">
            <div 
                :class="[
                    'side-menu-header-filter-header-status-item mr-2', 
                    { 'side-menu-header-filter-header-status-item-active': filterCardStatus.includes('active') }
                ]" 
                @click="toggleStatus('active')"
            >
                <u-text size="3" class="pb-1">Ativo</u-text>
            </div>
            <div 
                :class="[
                    'side-menu-header-filter-header-status-item ml-2 mr-2', 
                    { 'side-menu-header-filter-header-status-item-active': filterCardStatus.includes('completed') }
                ]" 
                @click="toggleStatus('completed')"
            >
                <u-text size="3" class="pb-1">Concluído</u-text>
            </div>
            <div 
                :class="[
                    'side-menu-header-filter-header-status-item ml-2', 
                    { 'side-menu-header-filter-header-status-item-active': filterCardStatus.includes('canceled') }
                ]" 
                @click="toggleStatus('canceled')"
            >
                <u-text size="3" class="pb-1">Cancelado</u-text>
            </div>
        </div>

        <!-- <div v-if="companies.length" class="mt-6">
            <u-title size="3" color="grey6" class="mb-4">Empresa</u-title>

            <div class="side-menu-header-filter-companies">
                <div v-for="company in companies" :key="company.id" class="side-menu-header-filter-companies-row" @click="toogleExceptCompanies(company.id)">
                    <div class="side-menu-header-filter-companies-item">
                        <div class="side-menu-header-filter-companies-item-avatar">
                            <img v-if="company.logo" :src="company.logo" :alt="company.trade_name" class="w-100">
                            <i v-else class="icon icon-company icon16 white1"></i>
                        </div>
                        <u-text color="grey6">{{ company.trade_name }}</u-text>
                    </div>
                    
                    <i :class="['icon icon-check-fill icon16', { 'white1': exceptCompanies.includes(company.id) }, { 'blue5': !exceptCompanies.includes(company.id) }]"></i>
                </div>
            </div>
        </div>         -->

        <div class="d-flex justify-between mt-5">
            <div class="side-menu-header-filter-header-button side-menu-header-filter-header-button-cancel" @click="$emit('close-filter')">
                <u-text color="grey5" class="pb-1">Cancelar</u-text>
            </div>

            <div class="d-flex">
                <div class="side-menu-header-filter-header-button side-menu-header-filter-header-button-clear" @click="$emit('clear-filter')">
                    <u-text color="grey6">Limpar filtro</u-text>
                </div>

                <div class="side-menu-header-filter-header-button side-menu-header-filter-header-button-apply ml-3" @click="applyFilter">
                    <u-text color="white1">Aplicar</u-text>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { hasPreference } from '@/assets/js/helpers/index.js'
import { mapMutations, mapState, mapGetters } from 'vuex'

export default {
    props: {
        companies: {
            type: Array,
            default: () => []
        },
    },

    computed: {
        ...mapState('Feed', ['filterCardStatus', 'exceptCompanies']),

        ...mapGetters('Auth', {
            preferences: 'getPreferences'
        }),
    },

    methods: {
        ...mapMutations('Feed', ['setFilterCardStatus', 'toogleExceptCompanies']),

        toggleStatus(status) {
            let listStatus = this.filterCardStatus

            if (this.filterCardStatus.includes(status)) {
                listStatus.splice(this.filterCardStatus.indexOf(status), 1)
                this.setFilterCardStatus(listStatus)
                return
            }

            listStatus.push(status)
            this.setFilterCardStatus(listStatus)
        },

        applyFilter() {
            this.$emit('refresh-list')
            this.$emit('close-filter')
        },
    }
}
</script>

<style lang="scss" scoped>

.side-menu-header-filter {
    position: absolute;
    top: 0;
    left: -16px;
    right: 0;
    bottom: 0;
    height: 100%;
    min-height: 200px;
    width: 380px;
    padding-right: $spacing-5;
    padding-left: $spacing-5;
    background-color: $white-color;
    border-radius: 16px;
}

.side-menu-header-filter-header {
    display: flex;
    align-items: center;

    .side-menu-header-filter-header-back {
        height: 28px;
        width: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $grey6;
        margin-right: $spacing-3;
        border-radius: 4px;
        cursor: pointer;
        transition: all .2s;

        &:hover {
            background-color: $grey1;
        }

        &:active {
            color: $white-color;
            background-color: $grey1;
        }
    }
}

.side-menu-header-filter-header-status {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .side-menu-header-filter-header-status-item {
        height: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $grey5;
        border-radius: 20px;
        border: 1px solid $grey3;
        cursor: pointer;

        &.side-menu-header-filter-header-status-item-active {
            color: $blue5;
            border-color: $blue5;
            background-color: $blue0;
        }
    }
}

.side-menu-header-filter-companies {
    max-height: 240px;
    overflow: auto;

    .side-menu-header-filter-companies-row {
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

    .side-menu-header-filter-companies-item {
        height: 36px;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 $spacing-3;
        border-radius: 50px;
        background-color: $grey1;

        .side-menu-header-filter-companies-item-avatar {
            height: 36px;
            width: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: $spacing-3;
            margin-left: -8px;
            border-radius: 50%;
            background-color: $grey3;
        }
    }
}

.side-menu-header-filter-header-button {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 $spacing-5;
    border-radius: 4px;
    cursor: pointer;
    transition: all .2s;

    &.side-menu-header-filter-header-button-cancel:hover {
        background-color: $grey1;
    }

    &.side-menu-header-filter-header-button-clear {
        background-color: $grey1;

        &:hover {
            background-color: $grey2;
        }
    }

    &.side-menu-header-filter-header-button-apply {
        background-color: $blue5;

        &:hover {
            background-color: $blue6;
        }
    }
}

</style>