<template>
    <div class="unavailable-app">
        <div class="unavailable-container">
            <!-- CAPA -->
            <div class="unavailable-cover" :style="coverBackground">
                <div class="mask-logo">
                    <u-avatar
                        :size="1"
                        :organization="appSettings"
                    ></u-avatar>
                </div>
            </div>

            <!-- AVISO -->
            <div class="unavailable-form">
                <div class="app-unavailable">
                    <div class="app-unavailable-header">
                        <p> Ambiente do cliente indisponível </p>
                    </div>

                    <p class="unavailable-text"> 
                        No momento este canal não está disponível. 
                        Para mais informações sobre seus projetos 
                        favor entrar em contato conosco 
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import UAvatar from '@/assets/js/components/UI/avatar/UAvatar'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            UAvatar
        },

        computed: {
            ...mapGetters("Auth", {
                appSettings: "getAppSettings"
            }),

            coverBackground() { 
                // if (!this.appSettings.app_background) {
                //     return 'background: url(../images/cover-image-small.jpg)'
                // } 

                // return 'background: url(' + this.appSettings.app_background + ')'

                return 'background: url(../images/cover-image-small.jpg)'
            }
        }
    }
</script>

<style lang="scss" scoped>
    .unavailable-app {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: $grey1;

        @media (max-width: 900px) { 
            justify-content: flex-start;
        }

        .unavailable-container {
            display: flex;
            align-items: center;

            @media (max-width: 900px) { 
                flex-direction: column;
                max-width: 100%;
                max-height: 80%;
            }

            .unavailable-cover {
                width: 300px;
                height: 490px;
                padding: 30px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                border-radius: 8px 0px 0px 8px;
                margin-right: 3px;
                background-size: cover !important;
                background-position-x: center !important;
                background-repeat: no-repeat !important;

                @media (max-width: 900px) { 
                    padding: 0;
                    width: 100%;
                    border-radius: 8px 8px 0 0;
                    min-height: 265px;
                }

                .mask-logo { 
                    border: 2px solid #FFF;
                    border-radius: 50%;
                }
            }

            .unavailable-form {
                width: 420px;
                height: 575px;
                display: flex;
                align-items: center;
                background-color: #FFF;
                box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1);

                @media (max-width: 900px) { 
                    background-color: transparent;
                    box-shadow: none;
                    max-width: 100%;
                }

                .app-unavailable {
                    width: 100%;
                    padding: 50px;

                    @media (max-width: 900px) {
                        padding: 20px 0 0 0 !important;
                    }

                    .app-unavailable-header {
                        border-bottom: 1px solid #D3D8DE;

                        p {
                            font-size: 18px;
                            padding-bottom: 8px;
                        }
                    }
                }

                .unavailable-text { 
                    margin-top: 2rem;
                    font-size: 15px;
                    line-height: 20px;
                    color: $grey8;
                }
            }
        }
    }
</style>