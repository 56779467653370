<template>
    <div class="app">
        <div v-if="!userIsBlocked" :class="['body', bodyClass]">
            <header-menu v-if="haveMenu"></header-menu>

            <main-nav mobile v-if="haveMenu"></main-nav>
            
            <div :class="['app-body', $route.name]">
                <div class="app-body-content">
                    <side-menu v-if="haveSideMenu"></side-menu>

                    <div class="app-body-content-view">
                        <router-view/>
                    </div> 
                </div>               
            </div>
        </div>

        <modal-terms
            v-if="dialogTerms"
            :dialog="dialogTerms"
            :isLogged="true"
            @acceptTerms="acceptTerms"
            @closeModal="dialogTerms = false"
        ></modal-terms>
        
        <modal-change-password ref="refModalChangePassword"></modal-change-password>
    </div>
</template>

<script>
    import { hasPreference } from "@/assets/js/helpers/index.js";
    import SideMenu from '@/assets/js/components/UI/menu/sideMenu/SideMenu'
    import HeaderMenu from '@/assets/js/components/UI/menu/HeaderMenu'
    import MainNav from '@/assets/js/components/UI/menu/MainNav'
    import ModalTerms from '@/assets/js/modules/auth/ModalTerms'
    import ModalChangePassword from '@/assets/js/modules/contact/management/ModalChangePassword'
    import { mapGetters, mapActions, mapState } from 'vuex'

    export default {
        components: {
            SideMenu,
            HeaderMenu,
            MainNav,
            ModalTerms,
            ModalChangePassword,
        },

        data() { 
            return { 
                dialogTerms: false,
            }
        },
        
        computed: { 
            ...mapState('Auth', ['organizationUpdatePassword']),

            ...mapGetters("Auth", {
                appSettings: "getAppSettings",
                contact: "getContact",
                currentTerms: "getTerms",
                userIsBlocked: "getUserIsBlocked",
                preferences: "getPreferences"
            }),

            haveSideMenu() { 
                let pagesWithMenu = ['feed', 'post', 'my-requests', 'make-requests', 'organization-drive', 'drive-album']

                return pagesWithMenu.includes(this.$route.name)
            },

            haveMenu() { 
                return this.$route.name != 'loading' 
                    && this.$route.name != 'login'
                    && this.$route.name != 'register'
                    && this.$route.path != '/'
            },

            bodyClass() { 
                if (this.$isMobile() && this.haveMenu) return 'menu-body'

                return this.haveMenu ? 'menu-body' : 'padding-body'
            },
        },

        methods: { 
            hasPreference,

            ...mapActions("Auth", [
                "checkIsNewTerm",
                "validateTerm"
            ]),

            acceptTerms() { 
                this.validateTerm().then(() => {
                    this.dialogTerms = false
                    this.openChangePasswordModal()
                }) 
            },

            openChangePasswordModal() {
                if (this.organizationUpdatePassword && this.$refs.refModalChangePassword) {
                    this.$refs.refModalChangePassword.openModal()
                }
            }
        },

        watch: { 
            contact() { 
                this.checkIsNewTerm().then((terms) => {
                    if (!terms) {
                        this.dialogTerms = true
                        return
                    }

                    if (!('version' in terms)) { 
                        if (terms.length <= 0) { 
                            this.dialogTerms = true
                            return
                        }
    
                        this.dialogTerms = terms.find(term => term.version == this.currentTerms.version) ? false : true
                        return
                    } 
                    
                    if (('version' in terms)) { 
                        if (terms.length <= 0) { 
                            this.dialogTerms = true
                            return
                        }

                        if (terms.version != this.currentTerms.version) { 
                            this.dialogTerms = true
                            return
                        }
                    }

                    this.openChangePasswordModal()
                })
            },
            
            preferences() {
                document.title = !hasPreference('organization', 'name') 
                    ? this.appSettings.company_name 
                    : (this.preferences.organization.name != null ? this.preferences.organization.name : this.appSettings.company_name) 
            }
        }
    }
</script>

<style lang="scss" scoped>
.app { 
    height: 100%;
    background-color: $grey1;    
}

.app-body {
    height: calc(100vh - 56px);
    overflow-y: scroll;
    position: relative;

    @media (min-width: 768px) {
        &::-webkit-scrollbar {
            width: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background: $grey3;
            border-radius: 10px;
        }
    }    

    &.login,
    &.register,
    &.loading,
    &.contact-data,
    &.contact-data-management,
    &.settings {
        .app-body-content-view {
            max-width: none;
            margin: 0 auto;
        }
    }

    &.loading .app-body-content {
        height: 100vh;
        align-items: center;
    }
}

.app-body-content {
    max-width: 1080px;
    display: flex;
    align-items: flex-start;
    margin: 0 auto;
    padding-bottom: 40px;
}

.app-body-content-view {
    width: 100%;
    max-width: 668px;
    margin-top: $spacing-7;
    margin-left: 412px;   

    @media (max-width: 1024px) {
        margin: 0 auto;
        padding: 0 $spacing-4;
    }
}

</style>
