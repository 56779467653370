export default {
    /**
     * Retorna os arquivos da organização
     * @version 0.0.1
     * @return {Array}
     */
    getFiles: state => {
        return state.files
    },

    /**
     * Retorna o arquivo que sera carregado
     * @version 0.0.1
     * @return {Array}
     */
    getFileUpload: state => {
        return state.fileUpload
    },

    getDeletedFiles: state => {
        return state.deleted_files
    },

    /**
     * Retorna a quantidade de arquivos que a organização possui
     * @version 0.0.1
     * @return {Number}
     */
    getTotalOrganizationFiles: state => {
        return state.totalOrganizationFiles
    },

    /**
    * Retorna os filtros atuais de arquivos
    * @version 0.0.1
    * @return {Array}
    */
    getFilesFilter: state => {
        return state.filesFilter
    },

    getModalLimitReached: (state) => {
        return state.modalLimitReached
    },

    getFileModal: (state) => {
        return state.fileModal
    },
}