import axios from 'axios'
import { VMoney } from 'v-money'
import store from '../../../store/index'

const ummenseFilesFormat = {
    'image' : '.bmp, .gif, .jfif, .jpg, .jpeg, .png, .raw, .svg, .tif, .tiff, .webp',
    'video' : '.amv, .avi, .f4a, .f4b, .f4v, .flv, .m4b, .m4r, .m4v, .mkv, .mov, .mp2, .mp4, .mpe, .mpeg, .mpg, .mpg2, .mpg4, .mpv, .vob, .webm, .wmv',
    'audio' : '.3gp, .aa, .aac, .aax, .act, .amr, .ape, .au, .awb, .dct, .dss, .dvf, .flac, .gsm, .iklax, .ivs, .m4a, .m4b, .m4p, .mmf, .mp3, .mpc, .msv, .nmf, .nsf, .ogg, .vox, .wav, .wma, .wv, .webm, .8svx',
    'documents' : '.7z, .ai, .cdr, .csv, .doc, .eps, .docx, .indd, .odp, .ods, .odt, .otp, .pdf, .pot, .pps, .ppt, .pptx, .psd, .rar, .rtf, .tar.gz, .tgz, .txt, .xls, .xlsb, .xlsx, .xml, .xps, .zip, .zipx, .rem, .ofx, .ppsx',
}


export function isNewTerm() {
    if (store.contact) {

    }
}

/**
 * Formata o tamanho dos arquivos (de bytes para uma forma mais legível)
 * @version 0.0.1
 * @param {Number} size tamanho do arquivo
 * @return {String}
 */
export function formatFileSizeForHumans(bytes, decimals = 2) {
    if (bytes < 1024) return bytes + ' Bytes'
    else if (bytes < 1048576) return (bytes / 1024).toFixed(2) + ' KB'
    else if (bytes < 1073741824) return (bytes / 1048576).toFixed(2) + ' MB'
    else return (bytes / 1073741824).toFixed(1) + ' GB'
}

/**
 * Retorna a thumbnail para o arquivo atual
 * @param {Object} file arquivo, pode ser nulo caso tenha sido deletado
 * @param {Object} organization organização proprietária do arquivo
 * @param {String} type tipo do arquivo
 * @param {Boolean} tinyIcon verifica se é um icone e se exibe a miniatura do mesmo
 * @return {String}
 */
export function getThumbnailPath(file, organization, type = null, tinyIcon = false, card = false) {
    // Se for uma imagem, a thumb se torna a própria imagem
    if (getFileFormat(type) === 'image') {
        if (file && file.has_thumbnail == 1 && type != 'gif') {
            const format = webpIsSupported() ? '.webp' : '.jpg'
            return 'https://storage.ummense.com/' + organization.uuid + '/thumbnail_' + file.path.split('.')[0] + format
        } else {
            return 'https://storage.ummense.com/' + organization.uuid + '/' + file.path
        }
    } else {
        if (getFileFormat(type) === 'video') {
            return window.location.origin + '/images/u-drive/file-icons/video.jpg'
        }
        if (getFileFormat(type) === 'audio') {
            return window.location.origin + '/images/u-drive/file-icons/audio.jpg'
        }
        if (getFileFormat(type) === 'documents') {
            let fileType = null
            switch (type) {
                case '7z': case 'tgz': case 'zip':
                    fileType = 'zip.jpg'
                    break
                case 'rar':
                    fileType = 'rar.jpg'
                    break
                case 'eps':
                    fileType = 'eps.jpg'
                    break
                case 'csv': case 'ods': case 'xls': case 'xlsx':
                    fileType = 'excel.jpg'
                    break
                case 'doc': case 'odt': case 'xps':
                    fileType = 'text.jpg'
                    break
                case 'docx':
                    fileType = 'word.jpg'
                    break
                case 'pdf':
                    fileType = 'pdf.jpg'
                    break
                case 'odp': case 'ppt': case 'pps': case 'pptx': case 'pot':
                    fileType = 'powerpoint.jpg'
                    break
                case 'indd':
                    fileType = 'in-design.jpg'
                    break
                case 'ai':
                    fileType = 'ai.jpg'
                    break
                case 'cdr':
                    fileType = 'cdr.jpg'
                    break
                case 'psd':
                    fileType = 'psd.jpg'
                    break
                case 'xd':
                    fileType = 'xd.jpg'
                    break
            }

            if (fileType) {
                if (card) {
                    return window.location.origin + '/images/u-drive/file-icons/270x150/' + fileType
                } else if (tinyIcon) {
                    return window.location.origin + '/images/u-drive/file-icons/65x65/' + fileType
                } else {
                    return window.location.origin + '/images/u-drive/file-icons/' + fileType
                }
            }
        }
    }

    if (card) {
        return window.location.origin + '/images/u-drive/file-icons/270x150/archive.jpg'
    } else if (tinyIcon) {
        return window.location.origin + '/images/u-drive/file-icons/65x65/archive.jpg'
    } else {
        return window.location.origin + '/images/u-drive/file-icons/archive.jpg'
    }
}

export function getFileFormat (format) {
    if (!format) {
      return undefined
    }
  
    if (format.indexOf('/') !== -1) {
      format = format.split('/')[1]
    }
  
    if (ummenseFilesFormat.image.includes(format)) {
      return 'image'
    }
    if (ummenseFilesFormat.video.includes(format)) {
      return 'video'
    }
    if (ummenseFilesFormat.audio.includes(format)) {
      return 'audio'
    }
    if (ummenseFilesFormat.documents.includes(format)) {
      return 'documents'
    }
  
    return undefined
}

export function audioPlayerAccepted(format) {
    if (!format) {
        return false
    }

    let supportedFormats = [
        'm4a',
        'mp3',
        'mp4',
        'wav',
        'aac',
    ]

    return supportedFormats.includes(format)
}

export function videoPlayerAccepted(format) {
    if (!format) {
        return false
    }

    let supportedFormats = [
        'mp4',
        'webm',
    ]

    return supportedFormats.includes(format)
}

/**
 * Deleta um arquivo da base de dados
 * @version 0.0.1
 * @param {Object} file arquivo à ser deletado
 * @return {Promise}
 */
export function deleteUploadedFile(file) {
    return new Promise((resolve, reject) => {
        axios.delete(process.env.VUE_APP_API_BASE_URI + '/u-drive/' + file.id,
            {headers: {'Authorization': 'Bearer ' + store.state.Auth.token}}
        ).then(() => {
            resolve()
        }).catch(() => {
            reject()
        })
    })
}

/**
 * Verifica se o browser possui compatibilidade com imagem webp
 * @version 0.0.1
 * @return {Boolean}
 */
export function webpIsSupported() {
    if (!self.createImageBitmap) {
        return false
    }

    return true
}

/**
* Retorna os valores padrões do filtro de arquivos
* @version 0.0.1
* @return {Array}
*/
export function filesFilterDefaultValues() {
    return {
        fileName: null,
        category: 'all',
        model: null,
        model_id: null,
    }
}

export function hasPreference(preferenceType, preferenceKey) {
    let preferences = store.state.Auth.preferences

    if (preferences.hasOwnProperty(preferenceType) && preferences[preferenceType].hasOwnProperty(preferenceKey)) {
        return true
    }

    return false
}

/**
* Retorna a mascara de um campo
* @version 0.0.1
* @return {String}
*/
export function getFieldMask(fieldMaskName) {
    const masks =  {
        cellphone: "(##) #####-####",
        phone: "(##) ####-####",
        cnpj: "##.###.###/####-##",
        cpf: "###.###.###-##",
        date: "##/##/####",
        cep:"######-###",
    }
    return masks[fieldMaskName]
}

export function getMoneyMask(fieldMaskName) {
    if (fieldMaskName == 'decimal') {
        return {  decimal: '.', thousands: '',  prefix: '', suffix: '', precision: 2, masked: false }
    }

    if (fieldMaskName == 'percent') {
        return {  decimal: '.', thousands: '',  prefix: '', suffix: '%', precision: 2, masked: false }
    }

    const masks =  {
        real: "R$ ",
        dolar: "US$ ",
        euro: "€ ",
        libra: "£ ",
        yen: "¥ ",
        monetary:"$ ",
    }

    if (masks[fieldMaskName] == null) {
        return null
    }

    return {  decimal: ',', thousands: '.',  prefix: masks[fieldMaskName], suffix: '', precision: 2, masked: false }
}

export function hasCategoryForms(formList,categoryId) {
    let hasCategory = false
    formList.forEach((form) => {
        if(form.formCategoryId === categoryId)
            hasCategory = true
    })
    return hasCategory
}

export function hasChildrenCategories(categoriesList,categoryId) {
    let hasChildren = false
    categoriesList.forEach((category) => {
        if(category.parentId === categoryId)
            hasChildren = true
    })
    return hasChildren
}
