<template>
    <div class="u-upload-file">
        <input
            v-show="false"
            type="file"
            :multiple="multiple"
            @change="selectFiles"
            ref="refUploadField"
        />

        <div v-if="!formField" @click="openFileExplorer">
            <slot></slot>
        </div>

        <u-upload-form-field
            v-if="formField && formField"
            :formField="formField"
            :filesSent="formFilesSent"
            :organization="organization"
            @openFileExplorer="openFileExplorer"
            @removeFile="removeFile"
        ></u-upload-form-field>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { S3MultiUpload } from "./s3upload.js";
import UUploadFormField from './UUploadFormField'

export default {
    components: {
        UUploadFormField
    },

    props: {
        multiple: {
            type: Boolean,
            default: false
        },

        formField: {
            type: Object,
            default: null
        },

        model: {
            type: String,
            default: null
        },

        modelId: {
            type: Number,
            default: null
        },

        logCategory: {
            type: Number,
            default: null
        },

        logMessage: {
            type: String,
            default: null
        },

        isPrint: {
            type: Boolean,
            default: false
        },

        attachCategories: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapState("Files", [
            "filesSent",
            "filesFilter"
        ]),

       ...mapGetters("Auth", {
            user: "getContact"
        }),

        ...mapGetters("Auth", {
            organization: "getOrgId"
        }),

        filesUploadActiveSending() {
            return this.filesSent.filter(file => file.status == 'sending' || file.status == 'pending')
        },

        formFieldLabel() {
            return this.formField && this.formField.placeholder
                ? this.formField.placeholder
                : "Anexar arquivo";
        },

        fileSizeLimitText() {
            return this.formField ? '3GB' : '2GB'
        }
    },

    data() {
        return {
            s3upload: null,
            fileMeta: {},
            progressPercent: 0,
            progressFile: 0,
            progressSpeed: 0,
            draggindFile: false,
            formFilesSent: [],
            loading: false,
        };
    },

    methods: {
        openFileExplorer() {
            this.$refs.refUploadField.click();
        },

        selectFiles(event) {
            if (!this.canUploadFiles()) return

            let fileList = event.target.files;

            this.uploadMultipleFilesInit(fileList);
        },

        selectFile(file) {
            if (!this.canUploadFiles()) return

            this.upload(file);
        },

        canUploadFiles() {
            if (!this.browserIsCompatible()) {
                this.showErrorMessage('Não é possível enviar o arquivo. Por gentileza atualize seu browser.')
                return false
            }

            return true
        },

        uploadMultipleFilesInit(files) {
            if (Array.from(files).length > 5) {
                return this.showErrorMessage('Só é permitido o upload de 5 arquivos simultâneos')
            }

            Array.from(files).forEach(file => {
                this.upload(file);
            });

            let fileField = this.$refs.refUploadField;
            fileField.value = ""
        },

        setMeta(key, value) {
            this.fileMeta[key] = value;
        },

        setFileMeta() {
            this.fileMeta.created_by = this.user ? this.user.id : null;
            this.fileMeta.organizationId = this.organization;
            this.fileMeta.model = this.fileMeta.model || this.model;
            this.fileMeta.modelId = this.fileMeta.modelId || this.modelId;
            this.fileMeta.logCategory =
                this.fileMeta.logCategory || this.logCategory;
            this.fileMeta.logMessage =
                this.fileMeta.logMessage || this.logMessage;
            this.fileMeta.isPrint = this.isPrint;
            this.fileMeta.fileCategories = this.getFileCategories()
        },

        getFileCategories() {
            let categories = []

            if (!this.attachCategories) {
                return categories
            }

            if (this.filesFilter.categories && Array.isArray(this.filesFilter.categories)) {
                categories = this.filesFilter.categories.map(category => category.id);
            }

            if (this.filesFilter.category && typeof this.filesFilter.category == 'object') {
                categories.push(this.filesFilter.category.id)
            }

            return categories
        },

        upload(file) {
            this.$emit('changeLoadingStatus', true)
            let vm = this

            if (!this.fileSizeIsValid(file)) {
                this.$emit('changeLoadingStatus', false)
                this.showErrorMessage('Não foi possível enviar o arquivo ' + file.name + 
                    ' - Limite de arquivos excedido. Máximo: ' + this.fileSizeLimitText)
                return
            }

            this.setFileMeta()
            this.s3upload = new S3MultiUpload(file, this.fileMeta)

            this.s3upload.uploadSuccess = function(uploadedFile) {
                vm.$emit("uploadSuccess", uploadedFile)

                if (vm.formField) {
                    vm.formFilesSent.push(uploadedFile)
                }
            };

            this.s3upload.uploadError = function() {
                vm.$emit("uploadError")
            };

            this.s3upload.start()
        },

        fileSizeIsValid(file) {
            let uploadLimit = 2148576000 // 2GB
            let uploadFormLimit = 3221225472 // 3GB
            let fileLimit = this.formField ? uploadFormLimit : uploadLimit

            return file.size <= fileLimit
        },

        browserIsCompatible() {
            return (
                window.File &&
                window.FileReader &&
                window.FileList &&
                window.Blob &&
                window.Blob.prototype.slice
            );
        },

        removeFile(file) {
            let fileIndex = this.formFilesSent.findIndex(
                currentFile => currentFile.id == file.id
            );

            if (fileIndex > -1) {
                this.formFilesSent.splice(fileIndex, 1);
            }

            this.$emit("removeFile", file);
        },

        showErrorMessage(message) {
            this.$toast.open({
                message: message,
                type: 'error',
            });
        }
    },
};
</script>

<style lang="scss" scoped>
    .u-upload-file { 
        width: calc(100% - 24px);
    }
</style>