<template>
    <div @click="openFile" class="u-select-file">
        <div class="file-info">
            <div :class="['file-mask', {'is-document' : !image}]">
                <img v-if="image == true" :src="urlImg"/>
                <i class="far fa-file" v-else></i>
            </div>

            <p class="ml-2">
                <span class="u-select-file-name">{{ file.name }}</span>
            </p>

            <span class="grey8">({{ formatFileSizeForHumans(file.size) }})</span>
        </div>

        <div @click.stop.prevent="removeFile" class="clear-files">
            <u-icon
                icon="close"
                colorClass="hover-grey5-red5"
                class="clear-button"
                :width="14"
                :height="14"
            ></u-icon> 
        </div>
    </div>
</template>

<script>
import { formatFileSizeForHumans } from "../../../helpers/index.js";

export default {
    props: {
        file: {
            type: Object,
            required: true
        },
        
        organization: {
            type: Number,
            required: true
        }
    },

    computed: { 
        urlImg() {
            return this.file.uri
        },

        image() { 
            if (this.file.type == 'png' 
            || this.file.type == 'jpg' 
            || this.file.type == 'png'
            || this.file.type == 'gif'
            || this.file.type == 'svg'
            || this.file.type == 'jpeg'
            || this.file.type == 'bmp') {
                return true
            } 
            
            return false
        }
    },

    methods: {
        formatFileSizeForHumans,
        
        openFile() {
            let link = "https://app.ummense.com/u-drive/s/" + this.file.uuid;
            window.open(link, "_blank");
        },

        removeFile() {
            this.$emit("removeFile", this.file);
        }
    }
};
</script>

<style lang="scss" scoped>
    .u-select-file {
        margin-top: 12px;
        width: 100%;
        max-width: calc(100% - 2.8rem);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.8rem 2rem 0.8rem 0.8rem;
        margin-bottom: 0.8rem;
        background-color: $grey2;
        cursor: pointer;

        .file-info { 
            display: flex;
            align-items: center;
            max-width: 95%;

            p {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                max-width: 60%;
            }
        }

        .file-mask { 
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 40px;
            min-height: 40px;
            max-height: 40px;
            max-width: 40px;
            overflow: hidden;

            &.is-document {
                background-color: $blue5;

                svg { 
                    color: $grey8;
                }
            }

            img { 
                max-width: 100%;
            }
        }

        .u-select-file-thumb {
            height: 4.6rem;
            width: 4.6rem;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .u-select-file-name {
            font-size: 18px;
            font-weight: 500;
            color: $blue5;
            margin-right: 0.6rem;
            margin-left: 1rem;
        }

        .clear-files svg { 
            color: $grey5;
            font-size: 18px;

            &:hover { 
                color: $red5;
            }
        }
    }
</style>