<template>
    <div v-if="!model" class="u-input">
        <input :type="type" :placeholder="placeholder"/>
    </div>

    <div v-else class="u-input">
        <input :name="name" :type="type" :autocomplete="autocomplete" v-model="currentModel" :placeholder="placeholder" ref="refInput"/>
    </div>
</template>

<script>
    export default {
        props: { 
            placeholder: { 
                type: String,
                default: 'Insira seu texto'
            },

            model: {
                type: Boolean,
                required: false
            },

            autocomplete : {
                type: Boolean,
                required: false
            },
            
            name : {
                type: String,
                required: false
            },

            type : {
                type: String,
                default: 'text'
            }
        },

        data() {
            return { 
                currentModel: null,
            }
        },

        watch: { 
            currentModel() { 
                this.$emit('getModel', this.currentModel)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .u-input {
        display: flex;

        input {
            height: 48px;
            border: 1px solid $grey2;
            border-radius: 4px;
            width: 100%;
            padding: 14px;
            outline: none;

            &:focus { 
                border-color: $blue5;
            }
        }
    }
</style>