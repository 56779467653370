<template>
    <div class="warn-contact-deleted">
        <u-avatar
            :size="2"
            :organization="appSettings"
            class="warn-contact-logo"
        ></u-avatar>

        <p class="warn-contact-title"> Sua solicitação de anonimização de dados foi enviada </p>

        <p class="warn-contact-description"> 
            Caso a solicitação seja aceita, seus dados serão excluídos ou anonimizados do nosso banco de dados, 
            conforme os termos da lei geral de proteção de dados pessoais. Em caso de dúvidas, entre em 
            contato conosco.
        </p>

        <div class="warn-contact-actions">
            <router-link :to="{name: 'login'}">
                <button>
                    Voltar ao início
                </button>
            </router-link>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import UAvatar from '@/assets/js/components/UI/avatar/UAvatar'

    export default { 
        components: { 
            UAvatar
        },

        computed: { 
            ...mapGetters("Auth", {
                appSettings: "getAppSettings"
            }),
        },
    }
</script>

<style lang="scss" scoped>
    .warn-contact-deleted { 
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .warn-contact-logo { 
            margin-bottom: 36px;
        }

        .warn-contact-title {
            font-size: 19px;
            color: $grey8;
            font-weight: 600;
            margin-bottom: 20px;
            max-width: 600px;
        }

        .warn-contact-description { 
            font-size: 16px;
            color: $grey5;
            margin-bottom: 100px;
            max-width: 600px;
            text-align: center;
        }

        .warn-contact-actions { 
            button { 
                cursor: pointer;
                background-color: transparent;
                border: 0;
                outline: none;
                color: $blue5;
                font-size: 16px;

                &:hover { 
                    color: $blue3;
                }
            }
        }
    }
</style>