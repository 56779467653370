<template>
    <div class="modal-terms">
        <div class="modal-terms-content">
            <div v-if="hasCloseButton" @click="closeModal" class="button-close-modal">
                <u-icon
                    icon="close"
                    colorClass="hover-grey5-red5"
                    class="clear-button"
                    :width="16"
                    :height="16"
                ></u-icon> 
            </div>

            <div class="modal-terms-header">
                <p> TERMOS DE USO E POLÍTICAS DE PRIVACIDADES E PROTEÇÃO DE DADOS PESSOAIS </p>
            </div>

            <p class="modal-terms-version">
                VERSÃO {{currentTerms.version}}
            </p>

            <div v-for="(term, index) in currentTerms.terms" :key="index" class="term">
                <p 
                    v-html="term.title" 
                    class="term-title"
                ></p>

                <p 
                    class="term-description"
                    v-html="term.body"
                ></p>
            </div>

            <div v-if="!loading" class="modal-terms-actions">
                <u-button
                    v-if="isLogged"
                    class="decline-button"
                    text="Recusar"
                    color="#FCE1DE"
                    @click="dialogDeclineTerm = true"
                ></u-button>

                <u-button
                    v-if="!onlyDecline"
                    class="accept-button"
                    text="Aceitar Termos e Políticas"
                    color="#038DD2"
                    @click="acceptTerms"
                ></u-button>
            </div>

            <div v-if="loading" class="modal-terms-actions">
                <img src="/images/loading-ummense.gif"/>
            </div>
        </div>

        <modal-decline-term-confirm
            v-if="dialogDeclineTerm"
            :dialog="dialogDeclineTerm"
            :isOnlyDecline="onlyDecline"
            @closeModal="dialogDeclineTerm = false"
            @acceptTerms="acceptTerms"
            @declineCurrentTerm="declineCurrentTerm"
            @onlyDecline="declineWithoutAnonim"
        ></modal-decline-term-confirm>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import UButton from '@/assets/js/components/UI/button/UButton'
    import ModalDeclineTermConfirm from './ModalDeclineTermConfirm'

    export default { 
        props: { 
            dialog: { 
                required: true,
                type: Boolean
            },

            isLogged: { 
                required: false,
                type: Boolean
            },

            onlyDecline: { 
                default: false,
                type: Boolean
            },

            hasCloseButton: { 
                default: false,
                type: Boolean
            }
        },

        components: {
            UButton,
            ModalDeclineTermConfirm
        },

        data() { 
            return {
                dialogDeclineTerm: false,
                loading: false,
            }
        },

        computed: { 
            ...mapGetters("Auth", {
                appSettings: "getAppSettings",
                currentTerms: "getTerms"
            }),
        },

        methods: { 
            ...mapActions("Auth", [
                "getTerms",
                "validateTerm",
                "declineTerm",
                "revokeTerm"
            ]),

            closeModal() { 
                this.$emit('closeModal')
            },

            acceptTerms() { 
                this.$emit('acceptTerms')
            },

            declineWithoutAnonim() { 
                this.loading = true
                
                this.declineTerm().then(() => { 
                    this.$router.push({name: 'login'})
                    this.$emit('closeModal')
                    this.loading = false
                })
            },

            declineCurrentTerm() { 
                this.loading = true
                
                if (this.onlyDecline) { 
                    this.revokeTerm().then(() => {
                        this.$router.push({name: 'contact-deleted'})
                        this.$emit('closeModal')
                        this.loading = false
                    })

                    return
                }

                this.declineTerm().then(() => { 
                    this.$router.push({name: 'contact-deleted'})
                    this.$emit('closeModal')
                    this.loading = false
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
    .modal-terms { 
        z-index: 200;
        position: fixed;
        width: 100%;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.7);

        .modal-terms-header { 
            width: 100%;
            text-align: center;
            margin-bottom: 30px;

            p { 
                font-size: 19px;
                font-weight: 600;
            }
        }

        .modal-terms-version { 
            margin-bottom: 20px;
            font-weight: 600;
            font-size: 18px;
        }

        .modal-terms-content { 
            background-color: $white-color;
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 30px;
            border-radius: 8px;
            max-width: 800px;
            width: 60%;
            height: 90%;
            overflow-y: auto;
            
            @media (max-width: 900px) { 
                width: 80%;
            }

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-thumb {
                background: $grey2;
                border-radius: 10px;
            }

            .button-close-modal { 
                position: absolute;
                right: 20px;
                top: 15px;
                cursor: pointer;
                color: $grey8;

                &:hover { 
                    color: $red5;
                }
            }

            .term { 
                max-width: 100%;

                .term-title { 
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 15px;
                }

                .term-description { 
                    font-size: 16px;
                    margin-bottom: 40px;
                }
            }
        }

        .modal-terms-actions { 
            width: 100%;
            display: flex;
            justify-content: flex-end;

            img { 
                width: 40px;
                height: 40px;
            }
            
            .decline-button { 
                max-width: 10rem;
                margin-right: 12px;
                color: $red5;
                font-weight: 600;
            }

            .accept-button { 
                max-width: 17rem;
                font-weight: 600;
            }
        }
    }
</style>