<template>
    <transition name="fade">
        <div v-if="showSelect" class="feed-editor-project-select">
            <div class="feed-editor-project-select-header">
                <div class="d-flex">
                    <a href="" @click.stop.prevent="closeSelect" class="feed-editor-project-select-back">
                        <i class="icon icon-left-arrow-2 icon12 grey6 cursor-pointer"></i>
                    </a>

                    <u-title size="2" color="grey6" :margin="false">Vincular publicação em...</u-title>
                </div>

                <a href="" @click.stop.prevent="closeSelect" class="mr-2">
                    <i class="icon icon-close-x icon12 grey6 cursor-pointer"></i>
                </a>        
            </div>

            <div class="feed-editor-project-select-search">
                <div class="feed-editor-project-select-search-field">
                    <i class="icon icon-search-1 icon16 grey4 ml-4"></i>
                    <input type="text" v-model="search" placeholder="Encontrar card" @input="searchItem">
                </div>
            </div>

            <div v-if="loading" class="feed-editor-project-select-center">
                <img src="/images/loading-ummense.gif"/>
            </div>

            <div v-else-if="search && !projects.length" class="feed-editor-project-select-center">
                <u-title size="2" color="grey8" class="mb-3">Nenhum resultado encontrado</u-title>
            </div>

            <div v-else class="feed-editor-project-select-list">
                <div v-if="(search == null) && canPostOnGeneralFeed" @click="setProject(null)" class="feed-editor-project-select-item">
                    <u-text color="grey7">Não vincular </u-text>
                </div>


                <div v-if="canPostOnCards">
                    <div v-for="project in projects" :key="project.id" @click="setProject(project)" class="feed-editor-project-select-item">
                        <u-text color="grey7" class="mb-1">{{ project.name }}</u-text>
                        <div v-if="project.ticket && showTicketOnCard" class="d-flex">
                            <i class="icon icon-ticket icon8 grey4 mr-2"></i>
                            <u-text size="4" color="grey4">{{ project.ticket }}</u-text>
                        </div>
                    </div>
                </div>

                <div v-if="page > 1" @click="fetchProjects(true)" class="feed-editor-project-select-pagination">
                    <u-text v-if="!loadingPagination" size="3" color="blue5">Ver mais</u-text>
                    <img v-else class="feed-editor-project-select-pagination-loading" src="/images/loading-ummense.gif"/>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    computed: {
        ...mapState('Auth', [
            'preferences',
        ]),

        ...mapState('Feed', [
            'showTicketOnCard',
            'filterCardStatus',
        ]),

        canPostOnCards() {
            return this.preferences.user.canPostToCards
        },

        canPostOnGeneralFeed() {
            return this.preferences.user.canPostToContact
        },
    },

    data() {
        return {
            showSelect: false,
            page: 1,
            search: null,
            loading: false,
            loadingPagination: false,
            projects: [],
            sortBy: 'lastInteraction',
            timeout: null,
        }
    },

    mounted() {
        this.fetchProjects()
    },

    methods: {
        ...mapActions('Feed', [
            'getProjects'
        ]),

        fetchProjects(isPagination = false) {
            if (isPagination) {
                this.loadingPagination = true
            } else {
                this.page = 1
                this.projects = []
                this.loading = true
            }

            this.getProjects({
                page: this.page,
                search: this.search,
                status: this.filterCardStatus,
                sortBy: 'lastInteraction',
            }).then(data => {
                this.page = data.pagination.nextPage
                this.projects.push(...data.projects)
            }).finally(() => {
                this.loading = false
                this.loadingPagination = false
            })
        },

        openSelect() {
            this.showSelect = true
        },

        closeSelect() {
            this.showSelect = false
        },

        setProject(project) {
            this.$emit('set-project', project)
            this.closeSelect()
        },

        searchItem() {
            clearTimeout(this.timeout)

            this.timeout = setTimeout(() => {
                this.fetchProjects()
            }, 500)
        },
    }
}
</script>

<style lang="scss" scoped>

.feed-editor-project-select {
    position: absolute;
    top: -55px;
    right: 0;
    left: 0;
    background-color: $white-color;
    border-radius: 16px;
    z-index: 11;
}

.feed-editor-project-select-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-4;
    border-bottom: 1px solid $grey1;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: $white-color;
}

.feed-editor-project-select-back {
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: $spacing-4;
    border-radius: 4px;
    transition: all .2s;

    &:hover {
        background-color: $grey1;
    }
}

.feed-editor-project-select-search {
    padding-top: $spacing-6;
    padding-bottom: $spacing-4;
    padding-right: $spacing-7;
    padding-left: $spacing-7;

    .feed-editor-project-select-search-field {
        height: 36px;
        width: 100%;
        display: flex;
        align-items: center;
        background-color: $grey1;
        border: 1px solid transparent;
        border-radius: 4px;
        transition: all .2s;

        &:hover {
            background-color: $grey2;
        }

        &.side-menu-header-input-focus {
            border-color: $blue5;
            background-color: $white-color;
        }

        input {
            width: 100%;
            border: none;
            outline: none;
            padding: 0;
            margin-left: $spacing-4;
            background-color: transparent;

            &::placeholder {
                color: $grey4;
            }
        }
    }   
}

.feed-editor-project-select-list {
    max-height: 70vh;
    overflow: auto;

    &:hover::-webkit-scrollbar-thumb{
        background: $grey2;
    }
    
    &::-webkit-scrollbar {
        margin-top: 3px;
        width: 8px;
        height: 1.2rem !important;
    }

    &::-webkit-scrollbar-track {
        background: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 50px;
        background-clip: padding-box;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $grey5;
    }
}

.feed-editor-project-select-item {
    padding-top: $spacing-4;
    padding-bottom: $spacing-4;
    padding-right: $spacing-7;
    padding-left: $spacing-7;
    cursor: pointer;

    &:hover {
        background-color: $grey1;
    }
}

.feed-editor-project-select-center {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 40px;
    }
}

.feed-editor-project-select-pagination {
    text-align: center;
    padding-top: $spacing-4;
    padding-bottom: $spacing-5;
    cursor: pointer;

    .feed-editor-project-select-pagination-loading {
        height: 18px;
        width: 18px;
    }
}

</style>