<template>
    <div :class="['header-menu', { 'has-title': hasTitle }]">
        <div class="d-flex">
            <side-menu-mobile></side-menu-mobile>

            <div v-if="hasTitle" class="header-title">
                <router-link class="button-back" :to="{ name: whereGo }">
                    <i class="icon icon-left-arrow-2 grey7"></i>
                </router-link>

                <u-title size="2" :margin="false">{{ headerTitle }}</u-title>
            </div>

            <div v-else class="header-title">
                <u-avatar :organization="appSettings"></u-avatar>
                <u-title size="2" :margin="false" class="header-title-text ml-4">{{ preferences && preferences.organization ? preferences.organization.name : null }}</u-title>
            </div>
        </div>
        
        <main-nav v-if="showNav"></main-nav>

        <div class="header-actions">
            <notifications-modal :notifications="notifications"></notifications-modal>

            <user-menu></user-menu>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'
import UAvatar from '@/assets/js/components/UI/avatar/UAvatar'
import UserMenu from './UserMenu'
import MainNav from './MainNav'
import SideMenuMobile from './sideMenu/SideMenuMobile'
import NotificationsModal from '@/assets/js/components/notifications/NotificationsModal'
import { hasPreference } from "@/assets/js/helpers/index.js";

export default {
    mixins: [clickaway],

    components: {
        UAvatar,
        UserMenu,
        MainNav,
        SideMenuMobile,
        NotificationsModal,
    },

    data() {
        return {
            filter: null,
            notifications: [],
        }
    },

    computed: {
        ...mapGetters('Auth', {
            user: 'getContact',
            appSettings: "getAppSettings",
            preferences: "getPreferences"
        }),

        hasTitle() {
            return (
                this.$route.name == 'contact-data-management' ||
                this.$route.name == 'contact-data'
            )
        },

        headerTitle() {
            if (this.$route.name == 'contact-data-management') {
                return 'Dados pessoais'
            }

            if (this.$route.name == 'contact-data') {
                return 'Minha conta'
            }
        },

        whereGo() {
            if (this.$route.name == 'contact-data-management') {
                return 'contact-data'
            }

            if (this.$route.name == 'contact-data') {
                return 'feed'
            }
        },

        showNav() {
            return this.$route.name != 'contact-data' && this.$route.name != 'contact-data-management'
        }
    },

    methods: {
        hasPreference,
    },
}
</script>

<style lang="scss" scoped>

.header-menu {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: $spacing-7;
    padding-left: $spacing-7;
    background-color: $white-color;
    z-index: 10;
}

.header-actions {
    display: flex;
    align-items: center;
}

.header-title {
    display: flex;
    align-items: center;

    .header-title-text {
        @media (max-width: 1024px) {
            display: none;
        }
    }

    .button-back {
        margin-right: 12px;
        cursor: pointer;

        svg {
            font-size: 20px;
            color: $grey6;

            &:hover {
                color: $blue5;
            }
        }
    }
}

</style>