<template>
    <div class="modal-contact-reason">
        <div class="content">
            <div @click="closeModal" class="button-close-modal">
                <u-icon
                    icon="close"
                    colorClass="hover-grey5-red5"
                    class="clear-button"
                    :width="16"
                    :height="16"
                ></u-icon> 
            </div>

            <p class="content-title"> 
                Tem certeza que deseja solicitar esta alteração? 
            </p>

            <p class="content-description"> 
                Este é um dado que pode estar sendo utilizado para documentos e interações 
                legais, sua alteração poderá prejudicar essa utilização. A 
                {{ !hasPreference('organization', 'name') ? appSettings.company_name : preferences.organization.name }}
                pode levar até 30 dias para responder à sua solicitação.
            </p>

            <div class="content-change-area">
                <p class="label"> Insira o motivo para alterar seus dados </p>

                <textarea-autosize
                    v-model="reason"        
                ></textarea-autosize>
            </div>

            <div class="content-actions">
                <u-button
                    text="Solicitar alteração"
                    :hasBorder="true"
                    :disabled="loading"
                    @click="sendChanges"
                ></u-button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import UButton from '@/assets/js/components/UI/button/UButton'
    import { hasPreference } from "@/assets/js/helpers/index.js";

    export default { 
        components: {
            UButton,
        },
        
        props: { 
            dialog: { 
                required: true,
                type: Boolean
            }, 

            loading: { 
                type: Boolean,
                default: false
            }
        },

        data() { 
            return { 
                reason: null,
            }
        },

        computed: {
            ...mapGetters("Auth", {
                appSettings: "getAppSettings",
                preferences: "getPreferences",
            }),
        },

        methods: {
            hasPreference,

            sendChanges() { 
                this.$emit('sendChanges', this.reason)
            },

            closeModal() { 
                this.$emit('closeModal')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-contact-reason { 
        z-index: 200;
        position: fixed;
        width: 100%;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.8);

        .content {
            background-color: $white-color;
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 30px;
            border-radius: 8px;
            max-width: 800px;
            width: 60%;
            height: fit-content;
            overflow-y: auto;
        }

        .content-title { 
            color: $grey8;
            font-size: 19px;
            font-weight: 600;
            margin-bottom: 24px;
        }

        .content-description { 
            color: $grey8;
            font-size: 16px;
        }

        .button-close-modal { 
            position: absolute;
            right: 20px;
            top: 15px;
            cursor: pointer;
            color: $grey8;

            &:hover { 
                color: $red5;
            }
        }

        .content-change-area { 
            margin-top: 24px;

            .label { 
                color: $grey5;
                font-size: 15px;
                position: relative;
                top: 8px;
                left: 8px;
                background-color: $white-color;
                width: fit-content;
                padding: 0 5px;
                border-radius: 8px;
            }

            textarea { 
                min-height: 200px;
                width: calc(100% - 24px);
                outline: none;
                border: 2px solid $grey2;
                padding: 12px;
                font-size: 16px;

                &:focus { 
                    border-color: $blue5;
                }
            }
        }

        .content-actions { 
            margin-top: 24px;
            display: flex;
            justify-content: flex-end;

            button { 
                width: fit-content;
            }
        }
    }
</style>