<template>
    <div class="u-radio-button-container">
        <div :class="['u-radio-button', {'selected' : selected}]">
            <div v-if="selected" class="center"></div>
        </div>

        <p
            v-if="label"
            v-html="label"
        ></p>
    </div>
</template>

<script>
    export default {
        props: { 
            selected: { 
                type: Boolean, 
                default: false
            },

            label: { 
                type: String,
                default: null
            }
        }
    }
</script>

<style lang="scss" scoped> 
    .u-radio-button-container { 
        display: flex;
        align-items: center;
    }

    p { 
        cursor: pointer;
        margin-left: 8px;
        font-size: 14px;
    }

    .u-radio-button { 
        cursor: pointer;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid $grey2;
        display: flex;
        align-items: center;
        justify-content: center;

        .center { 
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $blue5;
        }

        &.selected {
            border-color: $blue5;
        }

        &:hover { 
            border-color: $blue5;
        }
    }
</style>