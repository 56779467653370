<template>
    <div class="org-settings">
        <u-title size="2" color="grey8" class="mb-4">Sobre</u-title>

        <div class="org-settings-container">
            <div v-if="noSettings" class="mt-7">
                <u-text>Nenhuma informação configurada.</u-text>
            </div>

            <div v-if="hasPreference('organization', 'description')" class="settings-box">
                <u-text size="3" color="grey6" class="mb-5">Descrição</u-text>
                <u-text color="grey8">{{ orgSettings.description }}</u-text>
            </div>

            <div v-if="hasPreference('organization', 'phone')" class="settings-box">
                <u-text size="3" color="grey6" class="mb-5">Telefone</u-text>
                <u-text color="grey8">{{ orgSettings.phone }}</u-text>
            </div>

            <div v-if="hasPreference('organization', 'email')" class="settings-box">
                <u-text size="3" color="grey6" class="mb-5">E-mail</u-text>
                <u-text color="grey8">{{ orgSettings.email }}</u-text>
            </div>

            <div v-if="hasPreference('organization', 'address')" class="settings-box">
                <u-text size="3" color="grey6" class="mb-5">Endereço</u-text>
                <u-text color="grey8">{{ orgSettings.address }}</u-text>
            </div>

            <div v-if="hasPreference('organization', 'website')" class="settings-box">
                <u-text size="3" color="grey6" class="mb-5">Site</u-text>
                <u-text color="grey8">{{ orgSettings.website }}</u-text>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { hasPreference } from "@/assets/js/helpers/index.js";

    export default {
        data() {
            return { 
                orgSettings: [],
                emptyMessage: false,
            }
        },

        computed: { 
            ...mapGetters("Auth", {
                preferences: "getPreferences"
            }),

            noSettings() { 
                if (!this.orgSettings.address 
                    && !this.orgSettings.website 
                    && !this.orgSettings.description
                    && !this.orgSettings.email
                    && !this.orgSettings.name
                    && !this.orgSettings.phone
                ) {
                    return true
                } 

                return false
            }
        },

        created() { 
            this.updateSettings()
        },

        methods: {
            hasPreference,

            updateSettings() { 
                if (!this.preferences.organization) {
                    this.emptyMessage = true
                }

                this.orgSettings = this.preferences.organization
            }
        }
    }
</script>

<style lang="scss" scoped>
.org-settings { 
    width: 100%;
    max-width: 782px;
    margin: 0 auto;
}

.org-settings-container {
    background-color: $white-color;
    padding: $spacing-7;
    border-radius: 16px;
}

.settings-box { 
    width: 100%;
    padding-bottom: $spacing-5;
    margin-bottom: $spacing-5;
    border-bottom: 1px solid $grey1;
}

</style>