import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const slugs = {
    state: {
        storageUrl: 'https://ummense-objects.s3.amazonaws.com/uapp/images/',
        baseAppUri: process.env.VUE_APP_API_BASE_URI + '/api/u-app/v14',
        slug: null,
        token: null,
        uAppSettings: null,
        contact: null,
        orgId: null,
        terms: null,
        contact: {},
        blockedUser: false,
        preferences: null,
        contactCustomFields: null,
        organizationUpdatePassword: false,
        contactMeta: [],
        appStoreLink: null,
        googlePlayLink: null,
    },
    namespaced: true,
    mutations,
    actions,
    getters,
}

export default slugs