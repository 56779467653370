var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-requests-filter"},[_c('p',{staticClass:"my-requests-filter-label"},[_vm._v("Status")]),_c('div',{staticClass:"my-requests-filter-status"},[_c('div',{class:['my-requests-filter-status-btn', { 
                'my-requests-filter-status-btn-active': _vm.filter.status.length == 4 && _vm.filter.status.includes('active', 'waiting', 'completed', 'canceled') 
            }],on:{"click":function($event){_vm.filter.status = ['active', 'waiting', 'completed', 'canceled']}}},[_c('p',[_vm._v("TODOS")])]),_c('div',{class:['my-requests-filter-status-btn', { 
                'my-requests-filter-status-btn-active': _vm.filter.status.length == 2 && _vm.filter.status.includes('active', 'waiting') 
            }],on:{"click":function($event){_vm.filter.status = ['active', 'waiting']}}},[_c('p',[_vm._v("ATIVO")])]),_c('div',{class:['my-requests-filter-status-btn', { 
                'my-requests-filter-status-btn-active': _vm.filter.status.length == 1 && _vm.filter.status.includes('completed') 
            }],on:{"click":function($event){_vm.filter.status = ['completed']}}},[_c('p',[_vm._v("CONCLUÍDO")])]),_c('div',{class:['my-requests-filter-status-btn', { 
                'my-requests-filter-status-btn-active': _vm.filter.status.length == 1 && _vm.filter.status.includes('canceled') 
            }],on:{"click":function($event){_vm.filter.status = ['canceled']}}},[_c('p',[_vm._v("CANCELADO")])])]),_c('div',{staticClass:"my-requests-filter-columns"},[_c('div',[_c('p',{staticClass:"my-requests-filter-label"},[_vm._v("Data de solicitação")]),_c('u-date-range-picker',{ref:"refUDateRangePicker",attrs:{"placeholder":"Selecionar período"},on:{"change":_vm.setDateRange}})],1),_c('div',[_c('p',{staticClass:"my-requests-filter-label"},[_vm._v("Formulário")]),_c('u-autocomplete',{ref:"refFormField",attrs:{"placeholder":"Selecionar formulário","options":_vm.forms},model:{value:(_vm.filter.form),callback:function ($$v) {_vm.$set(_vm.filter, "form", $$v)},expression:"filter.form"}})],1)]),_c('p',{staticClass:"my-requests-filter-label",staticStyle:{"margin-top":"16px"}},[_vm._v("Feed")]),_c('u-autocomplete',{ref:"refFeedField",attrs:{"placeholder":"Selecionar feed","options":_vm.cards},model:{value:(_vm.filter.project),callback:function ($$v) {_vm.$set(_vm.filter, "project", $$v)},expression:"filter.project"}}),_c('div',{staticClass:"my-requests-filter-buttons"},[_c('div',{on:{"click":_vm.clearFilter}},[_c('u-button',{attrs:{"text":"Limpar","color":"#FFFFFF","text-color":"#76828B"}})],1),_c('div',{on:{"click":_vm.applyFilter}},[_c('u-button',{attrs:{"text":"Filtrar"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }