<template>
    <div class="u-gif-picker" v-on-clickaway="closeGifModal">
        <template>
            <span @click.prevent="showGifPicker = !showGifPicker" class="u-gif-picker-button">
                <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.61016 5.75788H4.66353V6.4783H5.82754C5.80245 6.56481 5.7702 6.64463 5.73094 6.71791L5.73058 6.71861C5.68439 6.80769 5.62899 6.88061 5.56484 6.93835L5.56417 6.93897C5.47183 7.02471 5.35887 7.09644 5.22438 7.15364C5.09697 7.20584 4.93711 7.23313 4.74287 7.23313C4.57482 7.23313 4.41767 7.20462 4.27092 7.148C4.12377 7.08774 3.99365 7.00427 3.88016 6.89753C3.77071 6.79131 3.68232 6.6632 3.61524 6.51248C3.55219 6.35955 3.5202 6.18898 3.5202 5.99996C3.5202 5.81074 3.55226 5.64196 3.61523 5.49269C3.68246 5.33816 3.77099 5.20825 3.88048 5.10209C3.99021 4.99569 4.11825 4.91414 4.26514 4.85739L4.2662 4.85695C4.41298 4.79691 4.5682 4.7668 4.73237 4.7668C4.93605 4.7668 5.10748 4.80077 5.24851 4.86636L5.24954 4.86682C5.39238 4.92994 5.52262 5.0232 5.64024 5.14755L5.68101 5.19065L6.25219 4.63175L6.2137 4.59011C6.03651 4.39845 5.82329 4.2486 5.57475 4.14052C5.32822 4.03179 5.04879 3.97813 4.73762 3.97813C4.45746 3.97813 4.19273 4.02845 3.94393 4.12941C3.69569 4.23015 3.47919 4.37076 3.29503 4.55131C3.11092 4.73181 2.96501 4.94635 2.85719 5.1943C2.74867 5.44033 2.69478 5.70914 2.69478 5.99996C2.69478 6.29084 2.74882 6.56162 2.85725 6.81101C2.96511 7.05551 3.11103 7.26822 3.29503 7.44862C3.47919 7.62917 3.69569 7.76978 3.94393 7.87051C4.19273 7.97148 4.45746 8.0218 4.73762 8.0218C5.04494 8.0218 5.32055 7.97176 5.56336 7.87029C5.80514 7.76924 6.01335 7.6263 6.18717 7.44161C6.35019 7.26773 6.47004 7.06989 6.54627 6.84845C6.62202 6.62843 6.6597 6.39447 6.6597 6.14696C6.6597 6.08517 6.65607 6.02339 6.6488 5.96165C6.64161 5.90054 6.63249 5.84719 6.62121 5.80206L6.61016 5.75788Z" fill="#2A3B49"/>
                    <path d="M8.05919 4.06213H7.23378V7.9378H8.05919V4.06213Z" fill="#2A3B49"/>
                    <path d="M11.3053 4.06213H8.80509V7.9378H9.6305V6.4363H11.1373V5.64763H9.6305V4.8508H11.3053V4.06213Z" fill="#2A3B49"/>
                    <path d="M0.116638 1.96042C0.116638 0.929485 0.952373 0.09375 1.9833 0.09375H12.2368C13.2678 0.09375 14.1035 0.929485 14.1035 1.96042V9.89375C14.1035 10.9247 13.2678 11.7604 12.2368 11.7604H1.98331C0.952375 11.7604 0.116638 10.9247 0.116638 9.89375V1.96042ZM11.9802 1.26042H2.23997C1.71162 1.26042 1.2833 1.68873 1.2833 2.21708V9.63708C1.2833 10.1654 1.71162 10.5938 2.23997 10.5938H11.9802C12.5085 10.5938 12.9368 10.1654 12.9368 9.63708V2.21708C12.9368 1.68873 12.5085 1.26042 11.9802 1.26042Z" fill="#2A3B49"/>
                </svg>
            </span>
        </template>

        <div v-if="showGifPicker" class="u-gif-picker-modal">
            <div class="u-gif-picker-modal-search">
                <input
                    type="text"
                    v-model="search"
                    ref="refGifInput"
                    placeholder="Pesquisar GIFs"
                    autofocus
                />
            </div>
            <div
                class="u-gif-picker-modal-container"
                ref="refGifPickerContainer"
            >
                <div class="u-gif-picker-modal-container-grid">
                    <img
                        v-for="(gif, index) in firstColumnGifs"
                        :key="index"
                        :src="gif.media[0].tinygif.url"
                        @click="selectGif(gif)"
                        class="u-gif-picker-modal-container-grid-item"
                    />
                </div>
                <div class="u-gif-picker-modal-container-grid">
                    <img
                        v-for="(gif, index) in secondColumnGifs"
                        :key="index"
                        :src="gif.media[0].tinygif.url"
                        @click="selectGif(gif)"
                        class="u-gif-picker-modal-container-grid-item"
                    />
                </div>
            </div>
            <div class="copy-tenor">
                <p>Powered By <a href="https://tenor.com/" target="_blank">Tenor</a></p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mixin as clickaway } from "vue-clickaway";

export default {
    mixins: [clickaway],

    data() {
        return {
            showGifPicker: false,
            search: null,
            apikey: "4VLGJ6IEMH9O",
            gifList: [],
            firstColumnGifs: [],
            secondColumnGifs: [],
            apiPrefix: "https://g.tenor.com/v1/",
            apiSuffix:
                "key=0J8Q3EF8I46T&locale=pt_BR&media_filter=minimal&contentfilter=medium&ar_range=standard",
            apiPagination: null,
            loading: false,
        };
    },

    computed: {
        hasMoreItems() {
            return (
                this.firstColumnGifs.length &&
                this.firstColumnGifs.length + this.firstColumnGifs.length < 100
            );
        },
    },

    methods: {
        getTrendingGifs(isPaginate = false) {
            if (this.loading) {
                return;
            }

            this.loading = true;

            if (!isPaginate) {
                this.firstColumnGifs = [];
                this.secondColumnGifs = [];
            }

            let api = this.apiPrefix + "trending?" + this.apiSuffix;

            if (this.apiPagination) {
                api += "&pos=" + this.apiPagination;
            }

            axios.get(api).then(({ data }) => {
                this.apiPagination = data.next;
                this.setColumns(data.results);
            });
        },

        searchGifs(isPaginate = false) {
            if (this.loading) {
                return;
            }

            this.loading = true;

            if (!isPaginate) {
                this.firstColumnGifs = [];
                this.secondColumnGifs = [];
            }

            let api =
                this.apiPrefix +
                "search?q=" +
                this.search +
                "&" +
                this.apiSuffix;

            if (this.apiPagination) {
                api += "&pos=" + this.apiPagination;
            }

            axios.get(api).then(({ data }) => {
                this.apiPagination = data.next;
                this.setColumns(data.results);
            });
        },

        setColumns(list) {
            const half = Math.ceil(list.length / 2);

            this.firstColumnGifs.push(...list.splice(0, half));
            this.secondColumnGifs.push(...list.splice(-half));
            this.loading = false;
        },

        handleScroll() {
            const element = this.$refs.refGifPickerContainer;

            if (!element) return

            if (
                element &&
                element.scrollTop ==
                    element.scrollHeight - element.offsetHeight &&
                this.gifList.length < 100
            ) {
                if (!this.search) {
                    return this.getTrendingGifs(true);
                }

                this.searchGifs(true);
            }
        },

        closeGifModal() {
            if (!this.showGifPicker) {
                return;
            }

            this.showGifPicker = false;
        },

        selectGif(gif) {
            this.$emit("select-gif", gif.media[0].tinygif.url);
            this.closeGifModal();
        },
    },

    watch: {
        search: {
            handler() {
                if (!this.search) {
                    this.apiPagination = null;
                    return this.getTrendingGifs();
                }

                this.apiPagination = null;
                this.searchGifs();
            },
            immediate: true,
        },

        showGifPicker() {
            if (this.showGifPicker) {
                this.$nextTick(() => {
                    this.$refs.refGifInput.focus();
                    this.$refs.refGifPickerContainer.addEventListener(
                        "scroll",
                        this.handleScroll
                    );
                });
            } else {
                this.search = null;
                this.$refs.refGifPickerContainer.removeEventListener(
                    "scroll",
                    this.handleScroll
                );
            }
        },
    },
};
</script>

<style lang="scss" scoped>

.u-gif-picker {
    display: inline;
    position: relative;

    .u-gif-picker-modal {
        position: absolute;
        top: 20px;
        right: -10px;
    }

    .u-gif-picker-button:hover {
        color: $blue5;
    }
}

.u-gif-picker-modal {
    width: 250px;
    background-color: $white-color;
    border-radius: 8px;
    border: 1px solid $grey1;
}

.u-gif-picker-modal-search {
    display: flex;
    align-items: center;
    margin: 8px;
    border-bottom: 1px solid $grey2;

    input {
        width: 100%;
        padding: 10px 4px;
        border: none;
        outline:none;

        &::placeholder {
            color: $grey3;
        }
    }
}

.u-gif-picker-modal-container {
    height: 300px;
    display: flex;
    overflow: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        margin-top: 3px;
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #e4e6e7;
    }

    &::-webkit-scrollbar-thumb {
        background: #aaadaf;
        border-radius: 50px;
        background-clip: padding-box;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #a5aab0;
    }
}

.u-gif-picker-modal-container-grid {
    width: 50%;

    .u-gif-picker-modal-container-grid-item {
        width: 100%;
        padding: 4px;
        border-radius: 8px;
        cursor: pointer;
        box-sizing: border-box;
    }
}

.copy-tenor {
    text-align: right;
    padding: 4px 8px;
    
    p {
        font-size: 11px;
        color: $grey4;
    }

    a {
        color: $blue5;
        text-decoration: none;
    }
}
</style>