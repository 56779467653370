import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'vue-toast-notification/dist/theme-default.css';
import './assets/css/animation.css'
import './assets/css/fontello.css'
import './assets/css/fontello-codes.css'
import './assets/css/fontello-embedded.css'
import './assets/css/forms-flows-icons.css'
import './assets/css/forms-flows-icons-codes.css'
import './assets/css/forms-flows-icons-embedded.css'
import VueMobileDetection from 'vue-mobile-detection'
import VueToast from 'vue-toast-notification';
import VPopover from 'vue-js-popover'
import VTooltip from 'v-tooltip'
import TextareaAutosize from '@/assets/js/components/UI/textarea/TextareaAutosize'
import UIcon from '@/assets/js/components/UI/icons/UIcon'
import UTitle from '@/assets/js/components/UI/typography/UTitle'
import UText from '@/assets/js/components/UI/typography/UText'
import UGhostLoader from '@/assets/js/components/UI/loading/UGhostLoader'
import VueClipboard from 'vue-clipboard2'
import { VueMaskDirective } from 'v-mask'

Vue.config.productionTip = false

Vue.component('textarea-autosize', TextareaAutosize)
Vue.component('u-icon', UIcon)
Vue.component('u-title', UTitle)
Vue.component('u-text', UText)
Vue.component('u-ghost-loader', UGhostLoader)

Vue.use(VueMobileDetection)
Vue.directive('mask',VueMaskDirective)
Vue.use(require('vue-shortkey'))
Vue.use(VueToast, {
    position: 'top'
})
Vue.use(VTooltip)
Vue.use(VueClipboard)
Vue.use(VPopover, { tooltip: true })

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
