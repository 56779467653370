<template>
    <div class="modal-decline-term">
        <div class="modal-decline-term-content">
            <p class="modal-decline-title">
                Tem certeza que deseja recusar os Termos e Políticas?
            </p>

            <p class="modal-decline-description">
                Ao declinar os <strong>Termos de Uso e Políticas de Privacidade e Proteção de Dados 
                Pessoais</strong> você não poderá mais utilizar o aplicativo e seremos informados
                que você não autoriza o uso dos seus dados para os fins coletados anteriormente. Caso
                estejamos utilizando os dados para cumprimento de alguma obrigação contratual
                previamente solicitada por você, poderemos continuar utilizando esses dados
                até a finalização do período vigente da solicitação.
            </p>

            <div class="modal-decline-actions">
                <u-button
                    class="decline-button"
                    text="Recusar mesmo assim"
                    color="#FCE1DE"
                    @click="declineCurrentTerm"
                ></u-button>

                <u-button
                    v-if="!isOnlyDecline"
                    class="accept-button"
                    text="Aceitar Termos e Políticas"
                    color="#038DD2"
                    @click="acceptTerms"
                ></u-button>
            </div>
        </div>

        <modal-anonymization-contact
            v-if="dialogAnonymization"
            :dialog="dialogAnonymization"
            @decline="decline"
            @onlyDecline="onlyDecline"
        ></modal-anonymization-contact>
    </div>
</template>

<script>
    import ModalAnonymizationContact from './ModalAnonymizationContact'
    import UButton from '@/assets/js/components/UI/button/UButton'

    export default { 
        props: { 
            dialog: { 
                required: true,
                type: Boolean
            },

            isOnlyDecline: { 
                type: Boolean,
                default: false,
            }
        },

        components: {
            UButton,
            ModalAnonymizationContact
        },

        data() { 
            return { 
                dialogAnonymization: false,
            }
        },

        methods: { 
            acceptTerms() { 
                this.$emit('acceptTerms')
                this.$emit('closeModal')
            },

            declineCurrentTerm() { 
                this.dialogAnonymization = true
            },

            decline() { 
                this.dialogAnonymization = false
                this.$emit('declineCurrentTerm')
                this.$emit('closeModal')
            },

            onlyDecline() { 
                this.dialogAnonymization = false
                this.$emit('onlyDecline')
                this.$emit('closeModal')
            }
        },
    }
</script>

<style lang="scss" scoped>
    .modal-decline-term { 
        z-index: 200;
        position: fixed;
        width: 100%;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.7);

        .modal-decline-term-content {
            background-color: $white-color;
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 30px;
            border-radius: 8px;
            max-width: 520px;
            width: 60%;
            height: fit-content;

            .modal-decline-title { 
                font-size: 19px;
                font-weight: 600;
                color: $grey8;
                margin-bottom: 20px;
            }

            .modal-decline-description { 
                font-size: 16px;
                color: $grey8;
                margin-bottom: 30px;
            }
        }

        .modal-decline-actions { 
            width: 100%;
            display: flex;
            justify-content: flex-end;
            
            .decline-button { 
                max-width: 14rem;
                margin-right: 12px;
                color: $red5;
                padding: 0.7rem 2rem;
            }

            .accept-button { 
                max-width: 17rem;
                padding: 0.7rem 1rem;
            }
        }
    }
</style>