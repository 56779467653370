export default { 
    setSlug: (state, currentSlug) => {
        state.slug = currentSlug
    },

    setAppSettings: (state, currentSettings) => {
        state.uAppSettings = currentSettings 
    },

    setStoreLinks: (state, links) => { 
        state.googlePlayLink = links['googlePlayLink']
        state.appStoreLink = links['appStoreLink']
    },

    setToken: (state, currentToken) => {
        state.token = currentToken
    },

    setContact: (state, currentContact) => {
        state.contact = currentContact
    },

    setOrgId: (state, currentId) => {
        state.orgId = currentId
    },

    setTerm: (state, term) => {
        state.terms = term
    },

    setBlockedUser: (state, blocked) => {
        state.blockedUser = blocked
    },
    
    setPreferences: (state, currentPreferences) => {
        state.preferences = currentPreferences
    },

    setOrganizationUpdatePassword: (state, value) => {
        state.organizationUpdatePassword = value
    },    

    setContactCustomFields: (state, value) => { 
        state.contactCustomFields = value
    },

    setContactMeta: (state, value) => { 
        state.contactMeta = value
    },
 
    setAppLogoPreference: (state, value) => {
        state.preferences.app.logo = value.uri
    }
}