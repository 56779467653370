<script>
    export default {
        props: { 
            theme: {
                type: String,
                default: 'basic'
            },

            value: { 
                required: false,
                default: false,
            }
        },

        data() { 
            return { 
                checked: false,
            }
        },

        render(createElement) {
            return createElement(
                'div', { 
                    'class': ['u-checkbox', {'checked' : this.checked}, {'mini' : this.theme == 'mini'}],
                    on: {
                        click: this.clickOnItem
                    },
                }, [
                    createElement(
                        'i', { 
                            'class': ['fas fa-check']
                        }
                    )
                ]
            )
        },

        created() { 
            if (this.value != null && this.value != false) { 
                this.checked = true
            }
        },

        methods: { 
            clickOnItem() { 
                this.checked = !this.checked
                this.$emit('input', this.checked)
            }
        }
    }
</script>

<style lang="scss" scoped> 
    .u-checkbox { 
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border: 1px solid $grey2;

        svg { 
            font-size: 12px;
            color: $white-color;
            display: none;
        }

        &.checked { 
            background-color: $blue5;
            border-color: $blue5;

            svg { 
                display: block;
            }
        }

        &.mini { 
            width: 16px;
            height: 16px;
            border-radius: 4px;
        }

        &:hover { 
            border-color: $blue5;
        }
    }
</style>