<template>
    <div class="modal-anonymization-contact">
        <div class="modal-content">
            <p class="modal-content-title">
                Deseja solicitar a exclusão dos seus dados?
            </p>

            <p class="modal-content-description">
                Você pode solicitar que os seus dados pessoais sejam excluídos ou anonimizados do nosso 
                banco de dados, e isso será feito dentro de 30 dias, caso não hajam outras obrigações 
                contratuais ou fiscais que precisemos deles para serem cumpridas. Se isso for feito perderemos 
                dados que poderiam facilitar e melhorar o seu atendimento em outras oportunidades.
            </p>

            <div class="modal-content-actions">
                <u-button
                    class="button-keep-info"
                    text="Não solicitar exclusão"
                    color="#EEF5E5"
                    @click="decline"
                ></u-button>

                <u-button
                    class="button-anonymization"
                    text="Solicitar exclusão de dados"
                    color="#FCE1DE"
                    @click="anonymizationContact"
                ></u-button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import UButton from '@/assets/js/components/UI/button/UButton'

    export default {
        props: { 
            dialog: {
                required: true,
                type: Boolean
            }
        },

        components: {
            UButton
        },

        methods: {
            ...mapActions("Auth", [
                "deleteAccount",
                "revokeTerm"
            ]),

            anonymizationContact() { 
                this.deleteAccount().then(() => {
                    this.$emit('decline')
                })
            },

            decline() { 
                this.$emit('onlyDecline')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .modal-anonymization-contact { 
        z-index: 300;
        position: fixed;
        width: 100%;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.3);

        .modal-content { 
            background-color: $white-color;
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 30px;
            border-radius: 8px;
            max-width: 500px;
            width: 60%;
            height: fit-content;

            .modal-content-title { 
                font-size: 19px;
                font-weight: 600;
                color: $grey8;
                margin-bottom: 20px;
            }

            .modal-content-description { 
                font-size: 16px;
                color: $grey8;
                margin-bottom: 30px;
            }
        }

        .modal-content-actions { 
            width: 100%;
            display: flex;
            justify-content: flex-end;
            
            .button-keep-info { 
                max-width: 14rem;
                margin-right: 12px;
                color: $green5;
                font-weight: 600;
                padding: 0.7rem 2rem;
            }

            .button-anonymization { 
                max-width: 15rem;
                color: $red5;
                font-weight: 600;
                padding: 0.7rem 1rem;
            }
        }
    }
</style>