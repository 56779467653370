import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
import Cookies from 'js-cookie'

import UnavailableApp from '@/assets/js/modules/auth/UnavailableApp'
import WarnContactDeleted from '@/assets/js/modules/auth/WarnContactDeleted'
import Loading from '@/assets/js/modules/auth/Loading'
import Login from '@/assets/js/modules/auth/Login'
import Register from  '@/assets/js/modules/auth/Register'
import Feed from '@/assets/js/modules/feed/Feed'
import PostPage from '@/assets/js/modules/feed/PostPage'
import OrgSettings from '@/assets/js/modules/organization/OrgSettings'
import MyRequests from '@/assets/js/modules/requests/MyRequests'
import MakeRequests from '@/assets/js/modules/requests/MakeRequests'
import ContactData from '@/assets/js/modules/contact/ContactData'
import ContactDataManagement from '@/assets/js/modules/contact/management/ContactDataManagement'
import OrganizationDrive from '@/assets/js/modules/drive/OrganizationDrive'
import DriveAlbum from '@/assets/js/modules/drive/DriveAlbum'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'search',
        beforeEnter(to, from, next) {
            window.location.href="https://www.ummense.com/u-app/"
        }
    },

    {
        path: '/:slug/unavailable',
        name: 'unavailable',
        component: UnavailableApp
    },

    {
        path: '/:slug',
        name: 'loading',
        component: Loading
    },

    {
        path: '/:slug/login',
        name: 'login',
        component: Login
    },

    {
        path: '/:slug/register',
        name: 'register',
        component: Register
    },

    {
        path: '/:slug/feed',
        name: 'feed',
        component: Feed
    },

    {
        path: '/:slug/post/:id',
        name: 'post',
        component: PostPage
    },

    {
        path: '/:slug/settings',
        name: 'settings',
        component: OrgSettings
    },

    {
        path: '/:slug/my-requests',
        name: 'my-requests',
        component: MyRequests
    },

    {
        path: '/:slug/make-requests',
        name: 'make-requests',
        component: MakeRequests
    },

    {
        path: '/:slug/contact-deleted',
        name: 'contact-deleted',
        component: WarnContactDeleted
    },

    {
        path: '/:slug/contact-data',
        name: 'contact-data',
        component: ContactData
    },

    {
        path: '/:slug/contact-data-management',
        name: 'contact-data-management',
        component: ContactDataManagement
    },
    
    {
        path: '/:slug/drive',
        name: 'organization-drive',
        component: OrganizationDrive
    },

    {
        path: '/:slug/album/:selectedAlbum?',
        name: 'drive-album',
        component: DriveAlbum,
    }
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const currentSlug = '/' + window.location.pathname.split('/')[1];

    if (store.state.Auth.slug == null
        && to.path != currentSlug
        && currentSlug != '')
    {
        next({
            path: currentSlug
        })
    }

    if (to.path == '/') {
        Cookies.remove('user-token')
    }

    next()
})

export default router