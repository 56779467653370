<template>
    <div v-if="hasSidebar" class="side-menu-mobile">
        <a href="" @click.prevent="openNav" class="cursor-pointer pr-6">
            <i class="icon icon-sandwich-menu icon20 grey6"></i>
        </a>

        <div :class="['side-menu-mobile-drawer', { 'side-menu-mobile-drawer-open': showNav }]">
            <div v-if="showNav" class="side-menu-mobile-drawer-header">
                <u-avatar :organization="appSettings"></u-avatar>
                <u-title size="2" :margin="false" class="ml-4">{{ preferences && preferences.organization ? preferences.organization.name : null }}</u-title>
            </div>

            <side-menu v-if="showNav" mobile></side-menu>
        </div>

        <div v-if="showNav" @click="closeNav" class="side-menu-mobile-drawer-overlay"></div>
    </div>
</template>

<script>
import SideMenu from './SideMenu'
import UAvatar from '@/assets/js/components/UI/avatar/UAvatar'
import { mapGetters } from 'vuex'

export default {
    components: {
        SideMenu,
        UAvatar,
    },

    computed: {
        ...mapGetters('Auth', {
            appSettings: "getAppSettings",
            preferences: "getPreferences"
        }),

        hasSidebar() {
            let pages = ['feed', 'my-requests', 'make-requests', 'organization-drive', 'drive-album']

            return pages.includes(this.$route.name)
        }
    },

    data() {
        return {
            showNav: false,
        }
    },

    methods: {
        openNav() {
            this.showNav = true
        },

        closeNav() {
            this.showNav = false
        }
    }
}
</script>

<style lang="scss" scoped>

.side-menu-mobile {
    display: none;

    @media (max-width: 1024px) {
        display: block;
    }
}

.side-menu-mobile-drawer {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 0;
    background-color: $white-color;
    opacity: 0;
    z-index: 99;
    transition: all .2s;

    &.side-menu-mobile-drawer-open {
        width: 80%;
        opacity: 1;
    }
}

.side-menu-mobile-drawer-header {
    display: flex;
    align-items: center;
    padding: $spacing-5;
}

.side-menu-mobile-drawer-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $grey9;
    opacity: 0.5;
    z-index: 98;
    transition: all .2s;
}

</style>