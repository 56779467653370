<template>
    <div :class="['side-menu', { 'side-menu-mobile': mobile }, { 'side-menu-padding': !loading }, { 'bg-white': !loading }]">
        <u-ghost-loader v-if="loading" height="380px"></u-ghost-loader>

        <side-menu-cards v-if="$route.name == 'feed' || $route.name == 'post'" @set-loading="setLoading"></side-menu-cards>

        <side-menu-drive v-if="$route.name == 'drive-album'" @set-loading="setLoading"></side-menu-drive>       

        <div v-if="$route.name == 'make-requests' || $route.name == 'my-requests'">
            <router-link :to="{name: 'make-requests'}" :class="['side-menu-item', { 'side-menu-item-active': $route.name == 'make-requests' }]">
                <u-text :semibold="$route.name == 'make-requests'">Efetuar solicitações</u-text>
            </router-link>
            <router-link :to="{name: 'my-requests'}" :class="['side-menu-item', { 'side-menu-item-active': $route.name == 'my-requests' }]">
                <u-text :semibold="$route.name == 'my-requests'">Minhas solicitações</u-text>
            </router-link>
        </div>
    </div>
</template>

<script>
import SideMenuCards from './SideMenuCards'
import SideMenuDrive from './SideMenuDrive'

export default {
    props: {
        mobile: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            loading: true,
        }
    },

    components: {
        SideMenuCards,
        SideMenuDrive,
    },

    created() { 
        if (this.checkIfInitialPageIsRequests) { 
            this.setLoading(false)
        }
    },

    computed: { 
        checkIfInitialPageIsRequests() { 
            return (this.$route.name == 'make-requests' || this.$route.name == 'my-requests')
        }
    },

    methods: {
        setLoading(value) {
            this.loading = value
        }
    },

    watch: {
        '$route'() {
            if (this.loading && (this.$route.name == 'make-requests' || this.$route.name == 'my-requests')) {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.side-menu {
    position: fixed;
    width: 380px;
    min-width: 380px;
    margin-top: $spacing-7;
    border-radius: 16px;

    &.side-menu-padding {
        padding: $spacing-5;
    }

    @media (max-width: 1024px) {
        display: none;

        &.side-menu-mobile {
            position: initial;
            width: 100%;
            min-width: auto;
            margin-top: 0 !important;
        }
    }     
}

.side-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $grey7;
    padding: $spacing-4;
    margin-bottom: $spacing-1;
    border-radius: 4px;

    &.side-menu-item-active {
        color: $blue5;
        background-color: $blue0;
    }

    .side-menu-item-ticket {
        display: flex;
        align-items: center;
    }
}

</style>