import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// Modules
import Auth from './modules/auth/index'
import Feed from './modules/feed/index'
import Forms from './modules/forms/index'
import Files from './modules/files/index'
import Contact from './modules/contact/index'
import Drive from './modules/drive/index'

export const store = new Vuex.Store({
    state: {
    },

    modules: {
        Auth,
        Feed,
        Forms,
        Files,
        Contact,
        Drive,
    },

    mutations: {
    },
})

export default store
