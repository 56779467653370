<template>
    <div class="contact-address-data">
        <p class="address-data-title"> Endereço </p>

        <div class="address-fields">
            <div class="field spacing">
                <p class="label"> Logradouro </p>
                <input/>
            </div>

            <div class="field small">
                <p class="label"> Número </p>
                <input/>
            </div>
        </div>

        <div class="address-fields">
            <div class="field spacing">
                <p class="label"> Complemento </p>
                <input/>
            </div>

            <div class="field">
                <p class="label"> Bairro </p>
                <input/>
            </div>
        </div>

        <div class="field">
            <p class="label"> Cidade </p>
            <input/>
        </div>

        <u-button
            text="Solicitar alterações"
            color="#59abda"
            :hasBorder="true"
        ></u-button>
    </div>
</template>

<script>
    import UButton from '@/assets/js/components/UI/button/UButton'

    export default { 
        components: { 
            UButton
        }
    }
</script>

<style lang="scss" scoped>
    .contact-address-data { 
        margin-top: 24px;
        height: fit-content;
        width: 800px;
        padding: 24px;
        background-color: $white-color;
        border-radius: 8px;
        display: flex;
        flex-direction: column;

        .address-data-title { 
            font-size: 19px;
            font-weight: 600;
            color: $grey8;
        }

        .address-fields { 
            display: flex;
            align-items: center;
            width: 100%;

            .field { 
                width: 100%;

                &.spacing { 
                    margin-right: 16px;
                }

                &.small { 
                    width: 20%;
                }
            }
        }

        .label { 
            position: relative;
            top: 10px;
            left: 8px;
            background-color: $white-color;
            width: fit-content;
            padding: 0 5px;
            border-radius: 8px;
        }

        input { 
            width: calc(100% - 24px);
            border: 1px solid $grey2;
            background-color: $white-color;
            padding: 14px 12px;
            font-size: 15px;
            color: $grey8;
            min-height: 20px;
            outline: none !important;

            &:focus { 
                border-color: $blue5;
            }
        }

        button { 
            height: fit-content;
            padding: 9px 12px !important;
            border-radius: 8px;
            margin-top: 24px;
            width: fit-content;
        }
    }
</style>