<template>
    <div v-if="loading">
        <u-ghost-loader height="22px" width="98px" :has-opacity="false" class="mb-4"></u-ghost-loader>
        <u-ghost-loader height="555px" radius="16px"></u-ghost-loader>
    </div>

    <div v-else class="make-requests">
        <div v-if="!showSuccessMessage" class="mb-4">
            <div class="d-flex">
                <div v-if="selectedForm || currentCategory" @click="goToHome" class="make-requests-back-button mr-3 mb-2">
                    <i class="icon icon-left-arrow-2 grey6"></i>
                </div>
                <u-title size="2" color="grey8">{{ title }}</u-title>
            </div>
            
            <div v-if="selectedForm || currentCategory" class="forms-steps">
                <p class="not-selected" @click="goToHome"> Início / </p>

                <p v-for="(breadcump, index) in breadcumps" :key="index" :class="checkBreadcump(breadcump)">
                    <span @click="clickOnItem(breadcump, true)">
                        &ensp; {{ breadcump.name}} /
                    </span>
                </p>
            </div>
        </div>

        <div v-if="!showSuccessMessage" :class="['make-requests-container', {'has-breadcump' : selectedForm || currentCategory}]">
            <div v-if="!selectedForm && !currentCategory" class="select-form">

                <div v-if="loading" class="loading">
                    <img src="/images/loading-ummense.gif"/>
                </div>

                <div class="box-forms">
                    <div v-for="(item, index) in items"
                        :key="index"
                    >
                        <div v-if="!item.formCategoryId && !item.parentId" class="form" @click="clickOnItem(item)">
                            <div class="d-flex">
                                <i :class="['icon-forms-flows', getFormIcon(item.icon), 'grey6 icon16 mr-5']" :style="getFormStyle(item.color)"></i>
                                <u-text color="grey6">{{ item.name }}</u-text>
                            </div>
                            
                            <i class="icon icon-right-arrow grey4 icon12"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!selectedForm && currentCategory" class="select-form">
                 <div v-if="loading" class="loading">
                    <img src="/images/loading-ummense.gif"/>
                </div>

                <div class="box-forms">
                    <div v-for="(item, index) in items"
                        :key="index"
                    >
                        <div v-if="insideThisCategory(item)" class="form" @click="clickOnItem(item)">
                            <div class="d-flex">
                                <i :class="['icon-forms-flows', getFormIcon(item.icon), 'grey6 icon16 mr-5']" :style="getFormStyle(item.color)"></i>
                                <u-text color="grey6">{{ item.name }}</u-text>
                            </div>

                            <i class="icon icon-right-arrow grey4 icon12"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="selectedForm" class="selected-form">
                <p v-if="selectedForm.description"
                    class="requests-description"
                    v-html="selectedForm.description"
                ></p>

                <div class="form-fields">
                    <div v-for="(field, index) in selectedForm.fields" :key="index" class="field">
                        <span v-if="field.label"
                            class="field-label"
                        > {{ field.label }} </span>

                        <input :class="[{'auto-field' : !selectedForm.allowEditNameEmail}]"
                            v-if="field.type == 'input'
                            && field.slug_model == 'nome-pessoa-fisica'"
                            :placeholder="selectedForm.allowEditNameEmail ? field.placeholder : null"
                            :disabled="!selectedForm.allowEditNameEmail" 
                            :value="getValueFromField(index,field)"
                            @keyup="updateModel(field, $event.target.value, index)"
                            @keydown="keydownInputField(field, $event)"
                            ref="refContactName"
                        />

                        <input :class="[{'auto-field' : !selectedForm.allowEditNameEmail}]"
                            v-if="field.type == 'input'
                            && field.slug_model == 'sobrenome-pessoa-fisica'"
                            :placeholder="selectedForm.allowEditNameEmail ? field.placeholder : null"
                            :disabled="!selectedForm.allowEditNameEmail"
                            :value="getValueFromField(index,field)"
                            @keyup="updateModel(field, $event.target.value, index)"
                            @keydown="keydownInputField(field, $event)"
                            ref="refContactLastname"
                        />

                        <input :class="[{'auto-field' : !selectedForm.allowEditNameEmail}]"
                            v-if="field.type == 'input'
                            && field.slug_model == 'e-mail-pessoa-fisica'"
                            :placeholder="selectedForm.allowEditNameEmail ? field.placeholder : null"
                            :disabled="!selectedForm.allowEditNameEmail"
                            :value="getValueFromField(index,field)"
                            @keyup="updateModel(field, $event.target.value, index)"
                            @keydown="keydownInputField(field, $event)"
                            ref="refContactEmail"
                        />

                        <input v-if="field.type == 'input'
                            && field.slug_model != 'e-mail-pessoa-fisica'
                            && field.slug_model != 'sobrenome-pessoa-fisica'
                            && field.slug_model != 'nome-pessoa-fisica'"
                            :placeholder="field.placeholder"
                            v-mask="getFieldMask(field.mask)"
                            v-money="getMoneyMask(field.mask)"
                            :value="getValueFromField(index,field)"
                            :type="getFieldType(field)"
                            @keyup="updateModel(field, $event.target.value, index)"
                            @keydown="keydownInputField(field, $event)"
                            :ref="field.slug_model"
                        />
                        <textarea v-if="field.type == 'textArea'"
                            :placeholder="field.placeholder"

                            @keyup="updateModel(field, $event.target.value, index)"
                        ></textarea>

                        <div v-if="field.type == 'select'">
                            <multiselect
                                @input="updateModel(field, $event, index)"
                                v-model="model[index].field_value"
                                :options="convertStringToArray(field.values)"
                                :placeholder="field.label"
                                :searchable="false"
                                selectLabel="Pressione enter para selecionar"
                                deselectLabel="Pressione enter para remover"
                            ></multiselect>
                        </div>

                        <div v-if="field.type == 'itemList'">
                            <multiselect
                                @input="updateModel(field, $event, index, true)"
                                v-model="selectedNames[index]"
                                :options="field.values"
                                :multiple="true"
                                :close-on-select="false"
                                :placeholder="field.label"
                                :hideSelected="true"
                                label="name"
                                track-by="name"
                                selectLabel="Pressione enter para selecionar"
                                deselectLabel="Pressione enter para remover"
                            ></multiselect>
                        </div>

                        <div class="field-radios" v-if="field.type == 'radios'">
                            <div v-for="(option, position) in convertStringToArray(field.values)"
                                :key="position"
                                class="option"
                                @click="selectOption(field, option, index)"
                            >
                                <u-radio-button
                                    :label="option"
                                    :selected="model[index].field_value === option"
                                ></u-radio-button>
                            </div>
                        </div>

                        <div v-if="field.type == 'checkbox' && !field.values">
                            <u-checkbox
                                @input="updateModel(field, $event, index)"
                            ></u-checkbox>
                        </div>

                        <!-- Lista de Checkbox -->
                        <div v-if="field.type == 'checkbox' && field.values">
                            <div
                                class="list-checkbox-web"
                                v-for="(value, indexCheck) in convertStringToArray(field.values)" :key="indexCheck"
                            >
                                <u-checkbox
                                    @input="updateModel(field, value, index, $event)"
                                ></u-checkbox>
                                <p>{{value}}</p>
                            </div>
                        </div>

                        <div v-if="field.type == 'dateRange'">
                            <u-date-range-picker
                                @change="updateModel(field, ...arguments, index)"
                            ></u-date-range-picker>
                        </div>

                        <div v-if="field.type == 'timeRange'">
                            <u-time-range-picker
                                @change="updateModel(field, ...arguments, index)"
                            ></u-time-range-picker>
                        </div>


                        <div v-if="field.type === 'upload'">
                            <u-upload-file
                                is-form-field
                                :form-field="field"
                                :model="'form'"
                                :multiple="field.accept_multiple_values"
                                @uploadSuccess="uploadSuccess(field, index, ...arguments)"
                                @removeFile="removeFile(field, ...arguments, index)"
                                @changeLoadingStatus="(value) => loadingSendForm = value"
                            ></u-upload-file>
                        </div>

                        <span v-if="field.help"
                            class="field-tips"
                        > {{ field.help }} </span>

                        <span v-if="hasError[index] == true && field.is_required"
                            class="field-error"
                        > Campo obrigatório </span>
                    </div>

                    <div class="button-container">
                        <u-button
                            @click="sendForm()"
                            class="form-button"
                            :text="selectedForm.textButton"
                        ></u-button>
                    </div>
                </div>
            </div>

            <div class="no-response" v-if="items.length == 0 && !loading">
                <p> Nenhum item encontrado. </p>
            </div>
        </div>

        <request-success-message
            v-if="showSuccessMessage"
            :selectedForm="selectedForm"
            @closeForm="closeForm"
        ></request-success-message>

        <loading
            :active.sync="loadingSendForm"
            color="#208fcf"
            :width="100"
            :height="100"
        ></loading>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex'
    import { getFieldMask, getMoneyMask, hasCategoryForms, hasChildrenCategories } from "@/assets/js/helpers";
    import UButton from '@/assets/js/components/UI/button/UButton'
    import UDateRangePicker from "@/assets/js/components/UI/pickers/UDateRangePicker"
    import UTimeRangePicker from "@/assets/js/components/UI/pickers/UTimeRangePicker"
    import URadioButton from '@/assets/js/components/UI/button/URadioButton'
    import UCheckbox from '@/assets/js/components/UI/button/UCheckbox'
    import UUploadFile from '@/assets/js/components/UI/upload/UUploadFile'
    import RequestSuccessMessage from './RequestSuccessMessage'
    import 'vue-loading-overlay/dist/vue-loading.css';
    import Loading from 'vue-loading-overlay';
    import Multiselect from 'vue-multiselect'
    import 'moment/locale/pt-br';
    import { VMoney } from 'v-money'

    export default {
        directives: {money: VMoney},

        components: {
            UButton,
            Loading,
            UUploadFile,
            Multiselect,
            UCheckbox,
            URadioButton,
            UDateRangePicker,
            UTimeRangePicker,
            RequestSuccessMessage,
        },

        data() {
            return {
                items: [],
                forms: [],
                categories: [],
                currentCategory: null,
                selectedForm: null,
                loadingSendForm: false,
                loading: true,
                model: [],
                selectedNames: [],
                breadcumps: [],
                hasError: [],
                uploadedFiles: [],
                fieldChecklist: [],
                showSuccessMessage: false,
            }
        },

        computed: {
            ...mapState('Auth', ['contactMeta']),

            ...mapGetters('Auth', {
                appContact: 'getContact',
            }),

            title() {
                if (this.selectedForm) {
                    return this.selectedForm.name
                }

                if (!this.selectedForm && this.currentCategory && this.currentCategory.title) {
                    return this.currentCategory.title
                }

                return 'Efetuar solicitações'
            },
        },

        created() {
            this.updateForms()
            this.getUserFields()
        },

        methods: {
            getMoneyMask,

            getFieldMask,

            ...mapActions('Auth', ['getUserFields']),

            ...mapActions("Forms", [
                "getForms",
                "updateResponse"
            ]),

            ...mapActions("Files", [
                "deleteUploadedFile"
            ]),

            getValueFromField(index) {
                return this.model[index].field_value
            },

            selectOption(field, option, index) {
                this.updateModel(field, option, index)
            },

            goToHome() {
                this.breadcumps = []
                this.selectedForm = null
                this.currentCategory = null
            },

            uploadSuccess(field, fieldIndex, file) {
                this.uploadedFiles.push(file.id)
                this.groupUploadedFiles(field, fieldIndex)
                this.loadingSendForm = false
            },

            groupUploadedFiles(field, fieldIndex){
                let file = this.uploadedFiles[this.uploadedFiles.length - 1]

                this.uploadedFiles.splice(this.uploadedFiles.length - 1, -1)

                if (! field.values) {
                    field.values = []
                }

                field.values.push(file)
                this.updateModel(field, field.values, fieldIndex)
            },

            checkBreadcump(item) {
                if (this.currentCategory && this.currentCategory.id == item.id) {
                    return 'selected'
                }

                if (this.selectedForm && this.selectedForm.id == item.id) {
                    return 'selected'
                }

                return 'not-selected'
            },

            removeFile(field, file, index){
                if (!this.model || !field || !file) {
                    return
                }

                this.model[index].field_value = []
                this.selectedForm.fields[index].values = []

                let fileIndex = this.uploadedFiles.findIndex(fileId => fileId == file.id)

                if (fileIndex > -1) {
                    this.uploadedFiles.splice(fileIndex, 1)
                }

                this.deleteUploadedFile(file.id)
            },

            insideThisCategory(item) {
                if (item.parentId == this.currentCategory.id
                    || item.formCategoryId == this.currentCategory.id)
                {
                    return true
                }

                return false
            },

            updateForms() {
                this.getForms().then((data) => {
                    this.forms = data.forms
                    this.categories = data.categories

                    this.sortItems()
                    this.setItems()
                })
            },

            setItems() {
                this.categories.map((category) => {
                    if (this.hasChildForm(category)) {
                        this.items.push(category)
                    }
                })

                this.forms.map((form) => {
                    if (form.fields.length > 0) {
                        this.items.push(form)
                    }
                })

                this.items.sort((itemA, itemB) => (itemA.position > itemB.position) ? 1 : ((itemB.position > itemA.position) ? -1 : 0))

                this.loading = false
            },

            sortItems() {
                this.categories.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))
                this.forms.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))
            },

            hasChildForm(category) {
                let hasChildForm = this.forms.find(form => form.formCategoryId == category.id)

                if (hasChildForm) return true

                let subcategories = this.categories.filter(currentCategory => currentCategory.parentId == category.id)

                for (let index = 0; index < subcategories.length; index++) {
                    if (this.hasChildForm(subcategories[index])) {
                        return true
                    }                    
                }

                return false
            },

            openCategorie(categorie) {
                this.forms.map((form) => {
                    if (form.formCategoryId == categorie.id) {
                        this.categorieForms.push(form)
                    }
                })
            },

            clickOnItem(item, isBreadcump = false) {
                if (isBreadcump) {
                    this.breadcumps.splice(this.breadcumps.indexOf(item), this.breadcumps.length);
                }

                this.clearVariables()

                this.breadcumps.push(item)

                if (!this.isCategory(item)) {
                    this.currentCategory = null
                    this.selectedForm = item
                    this.setModelKeys()
                    this.fillContactNameFields()
                    return
                }

                if (this.isCategory(item)) {
                    this.selectedForm = null
                    this.currentCategory = item
                }
            },

            clearVariables() {
                this.hasError = []
                this.selectedNames = []
                this.model = []
                this.uploadedFiles = []
            },

            isCategory(item) {
                if (!(item.hasOwnProperty("parentId"))) {
                    return false
                }

                return true
            },

            fillContactNameFields() {
                this.$nextTick(() => {
                    if (this.$refs.refContactName) {
                        this.$refs.refContactName[0].value = this.appContact.name
                        const nameIndex = this.model.findIndex(field => field.field_key == 'nome-pessoa-fisica')
                        this.model[nameIndex].field_value = this.appContact.name
                    }

                    if (this.$refs.refContactLastname) {
                        this.$refs.refContactLastname[0].value = this.appContact.lastname
                        const lastnameIndex = this.model.findIndex(field => field.field_key == 'sobrenome-pessoa-fisica')
                        this.model[lastnameIndex].field_value = this.appContact.lastname
                    }

                    if (this.$refs.refContactEmail) {
                        this.$refs.refContactEmail[0].value = this.appContact.email
                        const emailIndex = this.model.findIndex(field => field.field_key == 'e-mail-pessoa-fisica')
                        this.model[emailIndex].field_value = this.appContact.email
                    }

                    if (this.$refs['telefone-pessoa-fisica'] != null && this.appContact.phone) {
                        this.$refs['telefone-pessoa-fisica'][0].value = this.appContact.phone
                        let index = this.model.findIndex(field => field.field_key == 'telefone-pessoa-fisica')
                        this.model[index].field_value = this.appContact.phone
                    }

                    if (this.$refs['celular-pessoa-fisica'] != null && this.appContact.cellphone) {
                        this.$refs['celular-pessoa-fisica'][0].value = this.appContact.cellphone
                        let index = this.model.findIndex(field => field.field_key == 'celular-pessoa-fisica')
                        this.model[index].field_value = this.appContact.cellphone
                    }

                    for (const key in this.contactMeta) {
                        const element = this.contactMeta[key];

                        if (this.$refs[element.key] != null && element.value != '%%empty_data%%') {
                            let index = this.model.findIndex(field => field.field_key == element.key)
                            this.model[index].field_value = element.value
                        }
                    }
                })
            },

            clearOptions(index) {
                this.model[index].field_value = []
            },

            setModelKeys() {
                this.model = []

                this.selectedForm.fields.map((element) => {
                    this.model.push({ field_label: element.label, field_key: element.slug_model, field_value: '' })
                })
            },

            keydownInputField(field, event) {
                if (event.key == 'ArrowLeft' || event.key == 'ArrowRight' || event.key == 'Delete' || event.key == 'Backspace') {
                    return
                }

                let value = event.target.value + event.key

                if (field.mask == 'number' && (!/[0-9]/.test(event.key) || !/-/.test(event.key)) && !/^-?\d*$/.test(value)) {
                    event.preventDefault()
                }
            },

            updateModel(field, value, index, $event, isMultiselect = false) {
                if (field.is_required) {
                    this.clearErrors(index)
                }

                if (isMultiselect && field.type !== 'checkbox') {
                    this.selectedNames.index = value
                    let idObjects = []

                    this.model[index].field_value.map((object) => {
                        if (object.id) {
                            idObjects.push(object.id)
                        } else {
                            idObjects.push(object)
                        }

                        this.model[index].field_value = idObjects
                    })
                }

                if ( field.type === 'checkbox' && field.values ) {
                    if ($event == true) {
                        this.fieldChecklist.push(value)
                        value = this.fieldChecklist
                    } else {
                        this.fieldChecklist.splice(
                            this.fieldChecklist.findIndex(
                                (field) => field == value
                            ),
                            1
                        )
                        value = this.fieldChecklist
                    }
                }

                if (field.type === 'itemList' && field.values) { 
                    let itemListValues = []

                    value.map((item) => { 
                        itemListValues.push(item.id)
                    })

                    this.model[index].field = field
                    this.model[index].field_value = itemListValues
                    return
                }

                this.model[index].field = field
                this.model[index].field_value = value
            },

            clearErrors(index) {
                this.$set(this.hasError, index, false)
            },

            async sendForm() {
                this.checkAutoFields()
                this.checkRequiredFields()

                if (this.hasError.length > 0) {
                    this.$toast.open({
                        message: 'Preencha os campos obrigatórios',
                        type: 'error',
                    });

                    return
                }

                this.loadingSendForm = true

                let data = {
                    forms_id: this.selectedForm.id,
                    response: this.model
                }

                this.selectedForm.fields.map((field) => {
                    if (field.type == 'upload') {
                        field.values = null
                    }
                })

                this.updateResponse(data).then(() => {
                    this.loadingSendForm = false
                    this.showSuccessMessage = true
                    this.breadcumps = []
                    this.uploadedFiles = []
                    this.fieldChecklist = []

                    this.$toast.open({
                        message: 'Solicitação enviada',
                    });
                }).catch(() => {
                    this.$toast.open({
                        message: 'Algo deu errado, por favor recarregue a página e preencha o formulário novamente!',
                        type: 'error',
                    });
                    
                })
            },

            checkAutoFields() {
                if (this.selectedForm.allowEditNameEmail) return

                this.model.map((field) => {
                    if (field.field_key == 'e-mail-pessoa-fisica') {
                        field.field_value = this.appContact.email
                    }

                    if (field.field_key == 'nome-pessoa-fisica') {
                        field.field_value = this.appContact.name
                    }
                })
            },

            checkRequiredFields() {
                this.hasError = []

                for (var i = 0; i < this.selectedForm.fields.length; i++) {
                    if (this.selectedForm.fields
                        && this.selectedForm.fields[i].is_required == true
                        && this.model[i].field_value == '')
                    {
                        this.hasError[i] = true
                    }
                }
            },

            convertStringToArray(string) {
                if (string == null || string.length <= 0) return

                return string.split(",")
            },

            getFieldType(field) {
                return field.mask == 'number' ? 'number' : 'text'
            },

            getFormIcon(icon) {
                if (!icon) return 'icon-form-fill'

                return icon
            },

            getFormStyle(color) {
                if (!color) return ''

                return `color: ${color} !important`
            },

            closeForm() {
                this.selectedForm = null
                this.showSuccessMessage = false
            }
        },

        watch: {
            currentCategory: function(category) {
                if(category && !hasCategoryForms(this.forms,category.id)
                && !hasChildrenCategories(this.categories,category.id)) {
                    this.$toast.open({
                        message: 'Não ha formularios nesta categoria',
                        type: 'error'
                    })

                    this.goToHome()
                }
            }
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>

    .list-checkbox-web {
        display: flex;
        align-items: center;
        margin-bottom: 0.2rem;
        margin-top: 0.2rem;

        p {
            margin-left: 0.2rem;
        }
    }

    .make-requests {
        height: 100%;

        

        .make-requests-back-button {
            display: none;

            @media( max-width: 768px ) {
                display: block;
            }
        }

        .forms-steps {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            max-width: 100%;

            @media (max-width: 900px) {
                width: calc(100% - 64px);
                justify-content: flex-start;
            }

            p {
                cursor: pointer;
                font-size: 13px;
                margin-right: 5px;

                &.selected {
                    color: $grey7;

                    &:hover {
                        color: $blue5;
                    }
                }

                &.not-selected {
                    color: $blue5;

                    &:hover {
                        color: $blue3;
                    }
                }
            }
        }
    }

    .make-requests-container {
        width: 100%;
        height: fit-content;
        background-color: $white-color;
        padding: 24px 0 0 0;
        border-radius: 16px;
        overflow-y: scroll;
        max-height: calc(100% - 10rem);
        min-height: calc(550px - 48px);

        @media (max-width: 900px) {
            width: 100% !important;
            max-width: 100% !important;
            border-radius: 0px;
            height: calc(100% - 100px);
            position: absolute;
            max-height: unset;

            &.has-breadcump {
                height: calc(100% - 128px);
            }
        }

        .loading {
            width: 100%;
            height: 23rem;
            max-height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 40px;
            }
        }

        .box-forms {
            width: 100%;

            .form {
                width: 100%;
                height: 72px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 24px;
                border-bottom: 1px solid $grey1;

                p, svg {
                    color: $grey8;
                    font-size: 16px;
                }

                &:hover {
                    cursor: pointer;
                    background-color: $blue1;

                    p, svg {
                        color: $blue5;
                    }
                }
            }
        }

        .requests-description {
            padding: 0 24px;
            margin-bottom: 24px;
            font-size: 15px;
            color: $grey8;
            
            img {
                max-width: 100%;
            }
        }

        .form-fields {
            display: flex;
            flex-direction: column;

            input, textarea {
                background: transparent;
                outline: none;
                border: 1px solid $grey1;
                padding: 14px 12px;
                width: calc(100% - 48px);
                resize: none;
            }

            .field-error {
                font-size: 13px;
                color: $red5;
                padding-top: 12px;
            }

            .field {
                margin-bottom: 24px;
                padding-left: 24px;
                display: flex;
                flex-direction: column;

                .auto-field {
                    background-color: $grey1;
                }

                .field-label {
                    margin-bottom: 12px;
                    color: $grey8;
                    font-size: 15px;
                }

                .field-tips {
                    padding-top: 12px;
                    color: $grey6;
                    font-size: 13px;
                }

                select {
                    width: calc(100% - 24px);
                    background: transparent;
                    border: 1px solid $grey1;
                    outline: none;
                    padding: 14px;
                    color: $grey5;

                    option {
                        height: 40px;
                        padding: 14px;
                    }
                }

                .multiselect {
                    cursor: pointer;
                    width: calc(100% - 24px);

                    .multiselect__clear {
                        svg {
                            display: block;
                            position: absolute;
                            top: 12px;
                            right: 40px;
                            z-index: 500;
                            color: $grey5;

                            &:hover {
                                color: $red5;
                            }
                        }
                    }
                }

                svg {
                    display: none;
                }
            }

            .field-radios {
                display: flex;
                flex-direction: column;

                .option {
                    margin-bottom: 12px;

                    &:last-child {
                        margin: 0;
                    }
                }
            }

            .button-container {
                width: 100%;
                display: flex;
                justify-content: center;

                .form-button {
                    min-width: 379px;
                    width: fit-content;
                    margin-bottom: 24px;

                    @media (max-width: 900px) {
                        min-width: 280px;
                    }
                }
            }
        }

        .no-response {
            padding: 0 24px;
            display: flex;
            align-items: center;

            p {
                font-size: 16px;
                color: $grey5;
            }
        }

        &::-webkit-scrollbar {
            width: 0;
        }

        &::-webkit-scrollbar-thumb {
            background: $grey1;
        }
    }
</style>