import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { filesFilterDefaultValues } from '../../../assets/js/helpers/index.js'

const files = {
    namespaced: true,

    state: {
        files: [],
        fileUpload: null,
        deleted_files: [],
        filesFilter: filesFilterDefaultValues(),
        totalOrganizationFiles: null,
        storageUsage: 0,
        modalLimitReached: false,
        uploadLoading: false,
        fileModal: null,
        filesSent: []
    },

    mutations,
    actions,
    getters
};
export default files;