var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feed"},[(_vm.loadingFeed)?_c('div',[_c('u-ghost-loader',{staticClass:"mb-4",attrs:{"height":"22px","width":"98px","has-opacity":false}}),_c('u-ghost-loader',{attrs:{"height":"555px","radius":"16px"}})],1):_c('div',[_c('div',{staticClass:"mb-4"},[_c('u-title',{attrs:{"size":"2","color":"grey8","margin":false}},[_vm._v(_vm._s(_vm.feedTitle))]),(_vm.selectedProject)?_c('div',{staticClass:"feed-card-data"},[(_vm.showTicketOnCard)?_c('v-popover',{attrs:{"trigger":"hover","placement":"bottom-end"}},[_c('div',{staticClass:"feed-card-data-ticket",on:{"click":_vm.copyCardTicket}},[_c('i',{staticClass:"icon icon-ticket icon10 grey4 mr-2"}),_c('u-text',{attrs:{"size":"3","color":"grey4"}},[_vm._v(_vm._s(_vm.selectedProject.ticket))])],1),_c('div',{directives:[{name:"close-popover",rawName:"v-close-popover"}],staticClass:"feed-card-ticket-popover",attrs:{"slot":"popover"},slot:"popover"},[_c('u-text',{attrs:{"size":"3"}},[_vm._v("Copiar número identificador.")])],1)]):_vm._e(),(_vm.selectedProject.status == 'completed' || _vm.selectedProject.status == 'canceled')?_c('div',{class:[
                        'feed-card-data-status',
                        { 'bg-green1': _vm.selectedProject.status == 'completed' },
                        { 'bg-grey2': _vm.selectedProject.status == 'canceled' },
                    ]},[_c('i',{class:[
                            'icon icon12 mr-1',
                            { 'icon-check-circle-fill-1 green5': _vm.selectedProject.status == 'completed' },
                            { 'icon-cancel-circle-fill grey4': _vm.selectedProject.status == 'canceled' },
                        ]}),_c('u-text',{staticClass:"ml-2",attrs:{"size":"3","color":_vm.selectedProject.status == 'completed' ? 'green5' : 'grey4'}},[_vm._v(_vm._s(_vm.selectedProject.status == 'completed' ? 'Concluído' : 'Cancelado'))])],1):_vm._e()],1):_vm._e()],1),_c('feed-editor',{staticClass:"feed-create-post mb-4",attrs:{"filteredProject":_vm.selectedProject,"projectsFilter":_vm.projectsFilter},on:{"updatePosts":_vm.addNewPost}}),_vm._l((_vm.timelinePosts),function(post){return _c('post',{key:post.id,attrs:{"post":post}})}),(!_vm.timelinePosts.length)?_c('div',{staticClass:"empty-posts"},[_c('u-text',[_vm._v("Nenhum post foi encontrado")])],1):_vm._e(),(_vm.pagination)?_c('div',{staticClass:"feed-loading-pagination",on:{"click":function($event){return _vm.getCurrentPosts(true)}}},[(!_vm.loading)?_c('u-text',{attrs:{"size":"3","color":"blue5"}},[_vm._v("Carregar mais posts")]):_c('img',{attrs:{"src":"/images/loading-ummense.gif"}})],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }