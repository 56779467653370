<template>
    <div class="side-menu-drive">
        <side-menu-header 
            v-if="search || albums.length > 1"
            placeholder="Encontrar álbum" 
            :show-filter-button="false"
            :sort-active="listOrder" 
            @search="searchAlbum"
            @sort-list="sortList"
        ></side-menu-header>

        <div class="side-menu-drive-scroll">
            <side-menu-item 
                v-if="!search && !loading"
                semibold
                text="Todos os arquivos" 
                :active="!selectedAlbum"
                @click="setSelectedAlbum(null)"
            ></side-menu-item>

             <div v-if="!initialLoading && loading" class="side-menu-drive-loading">
                <img src="/images/loading-ummense.gif"/>
            </div>

            <side-menu-filter-empty v-else-if="search && !albums.length" @clear-search="clearSearch"></side-menu-filter-empty>

            <div v-else>
                <side-menu-item 
                    v-for="album in albums" 
                    :key="album.id" 
                    :text="album.name" 
                    :status="album.status"
                    :active="selectedAlbum && selectedAlbum.id == album.id"
                    @click="setSelectedAlbum(album)"
                ></side-menu-item>
            </div>
        </div>
        
        <div v-if="page > 1" @click="fetchAlbuns(true)" class="side-menu-drive-pagination">
            <u-text v-if="!loadingPagination" size="3" color="blue5">Ver mais</u-text>
            <img v-if="loadingPagination" class="side-menu-drive-pagination-loading" src="/images/loading-ummense.gif"/>
        </div>
    </div>
</template>

<script>
import SideMenuHeader from './SideMenuHeader'
import SideMenuItem from './SideMenuItem'
import SideMenuFilterEmpty from './SideMenuFilterEmpty'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
    components: {
        SideMenuHeader,
        SideMenuItem,
        SideMenuFilterEmpty,
    },

    computed: {
        ...mapState('Drive', ['selectedAlbum']),
    },

    data() {
        return {
            page: 1,
            listOrder: 'created',
            albums: [],
            initialLoading: true,
            loading: false,
            loadingPagination: false,
            search: null,
        }
    },

    mounted() {
        this.fetchAlbuns()
        this.$emit('set-loading', true)
    },

    methods: {
        ...mapMutations('Drive', ['setSelectedAlbum']),

        ...mapActions('Drive', ['getDriveAlbums']),

        fetchAlbuns(isPagination = false) {
            if (this.loading || this.loadingPagination) return

            if (isPagination) {
                this.loadingPagination = true
            } else {
                this.albums = []
                this.loading = true
            }

            this.getDriveAlbums({ 
                page: this.page,
                filter: this.listOrder,
                search: this.search,
            }).then((data) => {
                this.albums.push(...data.albums.items)
                this.page = data.albums.pagination.nextPage
            }).finally(() => {
                this.loading = false
                this.loadingPagination = false
                this.initialLoading = false
                this.$emit('set-loading', false)
            })
        },

        searchAlbum(search) {
            this.search = search
            this.page = 1
            this.fetchAlbuns()
        },

        sortList(order) {
            this.listOrder = order
            this.page = 1
            this.fetchAlbuns()
        },

         clearSearch() {
            this.search = null
            this.page = 1
            this.fetchAlbuns()
        }
    }
}
</script>

<style lang="scss" scoped>

.side-menu-drive-scroll {
    max-height: 75vh;
    overflow: auto;
    scrollbar-width: thin;
    
    &::-webkit-scrollbar {
        margin-top: 3px;
        width: 8px;
        height: 1.2rem !important;
    }

    &::-webkit-scrollbar-track {
        background: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background: $grey4;
        border-radius: 50px;
        background-clip: padding-box;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $grey5;
    }
}

.side-menu-drive-loading {
    text-align: center;
    padding-top: $spacing-4;

    img {
        height: 24px;
        width: 24px;
    }
}

.side-menu-drive-pagination {
    text-align: center;
    padding: $spacing-2;
    cursor: pointer;

    .side-menu-drive-pagination-loading {
        height: 18px;
        width: 18px;
    }
}

</style>