<template>
    <div class="contact-personal-data">
        <p class="personal-data-title"> Dados pessoais </p>

        <p class="personal-data-description"> 
            Seus dados podem estar sendo utilizados para documentos e interações 
            legais e fiscais. Você pode solicitar alteração ou anonimização dos dados a 
            qualquer momento.
        </p>

        <div class="personal-fields">
            <div class="field spacing">
                <p class="label"> Nome </p>
                <input v-model="currentUser.name" placeholder="Seu nome"/>
            </div>

            <div class="field">
                <p class="label"> Sobrenome </p>
                <input v-model="currentUser.lastname" placeholder="Seu sobrenome"/>
            </div>
        </div>

        <div class="field">
            <p class="label"> E-mail </p>
            <input v-model="currentUser.email" placeholder="Seu e-mail" />
        </div>

        <div class="personal-fields">
            <div class="field spacing">
                <p class="label"> Telefone </p>
                <input v-model="currentUser.phone" placeholder="(xx) xxxx-xxxx"/>
            </div>

            <div class="field">
                <p class="label"> Celular </p>
                <input v-model="currentUser.cellphone" placeholder="(xx) xxxxx-xxxx"/>
            </div>
        </div>

        <div v-for="(field, index) in listCustomFieldsUpdated" :key="index" class="field"> 
            <p :class="['label', {'without-position' : field.type == 'checkbox' || field.type == 'radios'}]"> {{ field.label }} </p>

            <select v-if="field.type == 'select'" v-model="field.value">
                <option v-for="(value, index) in field.values" :key="index" :value="value">
                    {{ value }}
                </option>
            </select>

            <div v-if="field.type == 'dateRange'">
                <u-date-range-picker
                    :value="field.value"
                    @change="updateModel(field, ...arguments, index)"
                ></u-date-range-picker>
            </div>

            <div v-if="field.type == 'timeRange'">
                <u-time-range-picker
                    list-width="200px"
                    :value="field.value"
                    @change="updateModel(field, ...arguments, index)"
                ></u-time-range-picker>
            </div>

            <div v-if="field.type == 'checkbox' && !field.values" class="checkbox-item">
                <u-checkbox
                    :value="field.value"
                    @input="updateModel(field, $event, index)"
                ></u-checkbox>
            </div>

            <div v-if="field.type == 'checkbox' && field.values">
                <div v-for="(value, subIndex) in field.values" :key="subIndex" class="checkbox-item">
                    <u-checkbox
                        :value="field.value ? field.value.find((field) => field == value) : false"
                        @input="updateModel(field, value, index, $event)"
                    ></u-checkbox>

                    <p> {{ value }} </p>
                </div> 
            </div>

            <div v-if="field.type == 'radios'" class="radio-list">
                <div v-for="(option, subIndex) in field.values" :key="subIndex" @click="updateModel(field, option, index)" class="radio">
                    <u-radio-button
                        :label="option"
                        :selected="field.value == option ? true : false"
                    ></u-radio-button>
                </div>
            </div>

            <input v-if="field.type == 'input'" 
                v-model="field.value" 
                :type="field.mask == 'number' ? 'number' : field.type" 
                :placeholder="field.placeholder"
                v-mask="getFieldMask(field.mask)"
                v-money="getMoneyMask(field.mask)"
            />

            <textarea v-if="field.type == 'textArea'"
                :placeholder="field.placeholder"
                v-model="field.value"
            ></textarea>

            <u-text v-if="field.help" :size="3" color="grey5" class="ml-4 mt-2"> 
                {{ field.help }}
            </u-text>
        </div>

        <u-button
            text="Solicitar alterações"
            color="#59abda"
            :hasBorder="true"
            @click="dialogReason = true"
        ></u-button>

        <modal-contact-reason
            v-if="dialogReason"
            :dialog="dialogReason"
            :loading="loadingDialogReason"
            @sendChanges="setContactData"
            @closeModal="dialogReason = false"
        ></modal-contact-reason>
    </div>
</template>

<script>
    import { getFieldMask, getMoneyMask } from "@/assets/js/helpers";
    import { mapGetters, mapActions } from 'vuex'
    import { VMoney } from 'v-money'
    import UButton from '@/assets/js/components/UI/button/UButton'
    import ModalContactReason from './ModalContactReason'
    import UDateRangePicker from "@/assets/js/components/UI/pickers/UDateRangePicker"
    import UTimeRangePicker from "@/assets/js/components/UI/pickers/UTimeRangePicker"
    import URadioButton from '@/assets/js/components/UI/button/URadioButton'
    import UCheckbox from '@/assets/js/components/UI/button/UCheckbox'

    export default { 
        directives: {money: VMoney},

        components: { 
            UButton,
            ModalContactReason,
            UDateRangePicker,
            UTimeRangePicker,
            URadioButton,
            UCheckbox
        },

        computed: { 
            ...mapGetters('Auth', {
                user: 'getContact',
                userCustomFields: 'getContactCustomFields'
            }),
        },

        data() { 
            return { 
                currentUser: {},
                dialogReason: false,
                loadingDialogReason: false,
                listCustomFieldsUpdated: [],
                selectedCheckboxList: [],
            }
        },

        created() { 
            this.currentUser = {
                name: this.user.name,
                lastname: this.user.lastname,
                email: this.user.email,
                phone: this.user.phone,
                cellphone: this.user.cellphone
            }

            this.setCustomFields() 
        },

        methods: {
            getMoneyMask,

            getFieldMask,

            ...mapActions('Contact', [
                "updateContact"
            ]),

            setContactData(reason) { 
                this.loadingDialogReason = true

                let userFields = [
                    {key: 'name', label: 'Nome', value: this.currentUser.name ? this.currentUser.name : null},
                    {key: 'lastname', label: 'Sobrenome', value: this.currentUser.lastname ? this.currentUser.lastname : null},
                    {key: 'email', label: 'E-mail', value: this.currentUser.email ? this.currentUser.email : null},
                    {key: 'cellphone', label: 'Celular', value: this.currentUser.cellphone ? this.currentUser.cellphone : null},
                    {key: 'phone', label: 'Telefone', value: this.currentUser.phone ? this.currentUser.phone : null},
                ]

                let payload = {
                    fieldsUpdated: userFields,
                    customFieldsUpdated: this.listCustomFieldsUpdated,
                    reason: reason
                }

                this.updateContact(payload).then((data) => { 
                    this.loadingDialogReason = false
                    this.dialogReason = false
                    this.$toast.open({
                        message: 'Alterações foram solicitadas com sucesso'
                    })
                }).catch((response) => {
                    this.loadingDialogReason = false
                    this.dialogReason = false
                    if (response.code == 422) {
                        this.$toast.open({
                            message: response.message,
                            type: 'error',
                        });
                    }
                })
            },

            setCustomFields() { 
                if (this.userCustomFields && this.userCustomFields.length <= 0) return

                this.userCustomFields.map((field) => { 
                    if (field.field.type == 'checkbox' && field.field.values && Array.isArray(field.fieldValue)) { 
                        this.selectedCheckboxList = field.fieldValue
                    }

                    this.listCustomFieldsUpdated.push(
                        {
                            'type': field.field.type,
                            'values' : this.convertStringToArray(field.field.values),
                            'slug_model': field.field.slug_model,
                            'label': field.field.label,
                            'value': field.fieldValue ? field.fieldValue : null,
                            'placeholder' : field.field.placeholder,
                            'help' : field.field.help,
                            'mask' : field.field.mask
                        }
                    )
                })
            },

            convertStringToArray(string) {
                if (string == null || string.length <= 0) return

                return string.split(",")
            },

            updateModel(field, value, index, event) { 
                if (field.type == 'checkbox' && field.values) { 
                    this.updateCheckboxListModel(field, value, index, event)
                    return
                }

                this.listCustomFieldsUpdated[index].value = value
            },

            updateCheckboxListModel(field, value, index, event) { 
                if (event != true) { 
                    this.selectedCheckboxList.splice(this.selectedCheckboxList.findIndex(
                        (field) => field == value
                    ), 1)
                    this.listCustomFieldsUpdated[index].value = this.selectedCheckboxList
                    return
                }

                this.selectedCheckboxList.push(value)
                this.listCustomFieldsUpdated[index].value = this.selectedCheckboxList
            }
        },
    }
</script>

<style lang="scss" scoped>
    .contact-personal-data { 
        height: fit-content;
        padding: 24px;
        background-color: $white-color;
        border-radius: 8px;
        display: flex;
        flex-direction: column;

        @media (max-width: 900px) { 
            max-width: calc(100% - 48px);
        }

        .personal-data-title { 
            font-size: 19px;
            font-weight: 600;
            color: $grey8;
        }

        .personal-data-description { 
            margin-top: 16px;
            font-size: 14px;
            color: $grey8;
            margin-bottom: 24px;
        }

        .personal-fields { 
            display: flex;
            align-items: center;
            width: 100%;

            .field { 
                width: 100%;

                &.spacing { 
                    margin-right: 16px;
                }
            }
        }

        .label { 
            z-index: 100;
            position: relative;
            top: 8px;
            left: 8px;
            background-color: $white-color;
            width: fit-content;
            padding: 0 5px;
            border-radius: 8px;

            &.without-position { 
                top: 0;
                padding: 0 !important;
                margin: 8px 0;
            }
        }

        input, select, textarea { 
            width: calc(100% - 24px);
            border: 1px solid $grey2;
            background-color: $white-color;
            padding: 14px 12px;
            font-size: 15px;
            color: $grey8;
            min-height: 48px;
            max-height: 48px;
            outline: none !important;

            &:focus { 
                border-color: $blue5;
            }
        }

        textarea { 
            max-height: unset;
        }

        select { 
            -moz-appearance:none;
            -webkit-appearance:none;
            appearance:none;    

            &:hover { 
                -moz-appearance:auto !important;
                -webkit-appearance:auto !important;
                appearance:auto !important;    
            }
        }

        .checkbox-item { 
            display: flex;
            align-items: center;
            margin-top: $spacing-3;
            padding-left: $spacing-3;

            p { 
                margin-left: $spacing-3;
            }
        }

        .radio-list { 
            padding-left: $spacing-3;

            .radio { 
                margin-top: $spacing-3;
            }
        }

        button { 
            height: fit-content;
            padding: 9px 12px !important;
            border-radius: 8px;
            margin-top: 24px;
            width: fit-content;
        }
    }
</style>