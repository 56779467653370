<template>
    <div class="contact-info">
        <div class="avatar-field">
            <u-avatar
                :user="user"
                :size="2"
            ></u-avatar>

            <input v-show="false" type="file" ref="inputfile" @change="updateContactAvatar">

            <div @click="$refs.inputfile.click()" class="button-change-avatar">
                <i class="fas fa-camera"></i>
            </div>
        </div>

        <div class="input-field">
            <p class="label"> Nome </p>
            <input v-model="user.name" disabled/>
        </div>

        <div class="input-field">
            <p class="label"> Sobrenome </p>
            <input v-model="user.lastname" disabled/>
        </div>

        <div class="input-field">
            <p class="label"> E-mail </p>
            <input v-model="user.email" disabled/>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    import UAvatar from '@/assets/js/components/UI/avatar/UAvatar'

    export default {
        components: { 
            UAvatar
        },

        computed: {
            ...mapGetters('Auth', {
                user: 'getContact',
            }),
        },
        
        data() { 
            return { 
                
            }
        },

        methods: {
            ...mapActions('Contact', [
                "updateAvatar"
            ]),

            updateContactAvatar() { 
                let settings = {
                    accepted: ["png", "jpg", "jpeg"],
                    maxFileSize: "2000000"
                };

                let fileType = this.$refs.inputfile.files[0].type.split("/")[1],
                fileSize = this.$refs.inputfile.files[0].size;

                let avatar = new FileReader(),
                data = [];

                if (!settings.accepted.includes(fileType)) {
                    this.$toast.open({
                        type: 'error',
                        message: 'Por favor insira uma imagem png, jpg ou jpeg'
                    })

                    return
                }

                if (fileSize >= settings.maxFileSize) {
                    this.$toast.open({
                        type: 'error',
                        message: 'Peso do avatar foi excedido'
                    })

                    return
                }

                avatar.readAsDataURL(this.$refs.inputfile.files[0])

                avatar.onload = e => {
                    data = {
                        avatar: e.target.result,
                        isUWeb: true
                    }
                    
                    this.updateAvatar(data).then((data) => {
                        this.$toast.open({
                            message: 'Avatar atualizado com sucesso'
                        })
                    })
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    .contact-info { 
        background-color: $white-color;
        width: 560px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        padding: 24px;
        border-radius: 8px;

        @media (max-width: 900px) { 
            max-width: calc(100% - 48px);
            margin-bottom: 24px;
        }

        .avatar-field { 
            display: flex;
            align-items: center;

            .button-change-avatar { 
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                top: 20px;
                right: 25px;
                border-radius: 50%;
                background-color: $white-color;
                width: 28px;
                height: 28px;

                svg { 
                    font-size: 14px;
                }

                &:hover { 
                    svg { 
                        color: $blue5;
                    }
                }

                &:active { 
                    background-color: $blue8;

                    svg { 
                        color: $white-color;
                    }
                }
            }
        }
        
        .input-field { 
            margin-top: 24px;
            width: calc(100% - 24px);
        }

        .label { 
            position: relative;
            top: 10px;
            left: 8px;
            background-color: $white-color;
            width: fit-content;
            padding: 0 5px;
            border-radius: 8px;
        }

        input { 
            width: 100%;
            border: 0;
            background-color: $grey1;
            padding: 14px 12px;
            font-size: 15px;
            color: $grey8;
            min-height: 20px;
        }
    }
</style>