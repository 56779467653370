<template>
    <div class="contact-data-management">
        <contact-personal-data
        ></contact-personal-data>

        <!-- <contact-address-data
            v-for="address in addressNumber"
            :key="address"
        ></contact-address-data> -->

        <div class="data-manegement-actions">
            <!-- <u-button
                @click="addAdress"
                color="#b3dbf1"
                text="Incluir endereço"
            ></u-button> -->

            <u-button
                @click="dialogDeleteContact = true"
                :hasIcon="true"
                icon="far fa-trash-alt"
                class="anonim"
                color="#fff"
                text="Anonimizar todos meus dados"
            ></u-button>
        </div>

        <modal-delete-contact
            v-if="dialogDeleteContact"
            :dialog="dialogDeleteContact"
            @deleteContact="anonimizateContact"
            @closeModal="dialogDeleteContact = false"
        ></modal-delete-contact>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import UButton from '@/assets/js/components/UI/button/UButton'
    import ContactAddressData from './ContactAddressData'
    import ContactPersonalData from './ContactPersonalData'
    import ModalDeleteContact from './ModalDeleteContact'

    export default { 
        components: {
            UButton,
            ContactAddressData,
            ContactPersonalData,
            ModalDeleteContact
        },

        data() { 
            return {
                dialogDeleteContact: false,
                addressNumber: 1,
            }
        },

        methods: { 
            ...mapActions("Contact", [
                "deleteAccount"
            ]),

            addAdress() {
                this.addressNumber++
            },

            anonimizateContact(reason) { 
                this.deleteAccount(reason).then(() => {
                    this.$toast.open({
                        message: 'Solicitação enviada'
                    })

                    this.$router.push({ name: 'login' })
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .contact-data-management { 
        width: 100%;
        max-width: 800px;
        padding-bottom: 24px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: auto;

        @media (max-width: 900px) { 
            margin-top: 0;
        }

        .data-manegement-actions { 
            width: 100%;

            @media (max-width: 900px) { 
                max-width: 100%;
                display: flex;
                justify-content: center;
            }

            button { 
                padding: 12px 16px;
                height: fit-content;
                color: $blue5;
                border-radius: 8px;
                margin-top: 24px;
                width: fit-content;

                &.anonim { 
                    color: $red5;
                }
            }
        }

        &::-webkit-scrollbar {
            width: 0;
        }

        &::-webkit-scrollbar-thumb {
            background: #dad7d7;
        }
    }
</style>