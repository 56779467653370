<template>
    <div class="u-code">
        <input
            @paste="onPaste"
            @keydown="onKeydown"
            @focus="onFocus(0)"
            :disabled="isDisable"
            v-model="codeValidation[0]"
            maxlength="1"
            class="fieldCode"
            ref="code0"
            type="tel"
        />
        <input
            @focus="onFocus(1)"
            @keydown="onKeydown"
            :disabled="isDisable"
            v-model="codeValidation[1]"
            maxlength="1"
            class="fieldCode"
            ref="code1"
            type="tel"
        />
        <input
            @focus="onFocus(2)"
            @keydown="onKeydown"
            :disabled="isDisable"
            v-model="codeValidation[2]"
            maxlength="1"
            class="fieldCode"
            ref="code2"
            type="tel"
        />
        <input
            @focus="onFocus(3)"
            @keydown="onKeydown"
            :disabled="isDisable"
            v-model="codeValidation[3]"
            maxlength="1"
            class="fieldCode"
            ref="code3"
            type="tel"
        />
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeInput: 0,
            codeValidation: [],
            mountedCode: "",
            isDisable: null,
        };
    },

    methods: {
        onPaste(event) {
            var pasteValue = event.clipboardData.getData("text");

            this.pasteValues(pasteValue);
        },

        onFocus(index) {
            this.activeInput = index;
        },

        onKeydown(event) {
            if (event.keyCode == 8) {
                this.codeValidation[this.activeInput] = "";

                setTimeout(() => this.focusPrevInput(), 100);
            }
        },

        focusPrevInput() {
            this.focusInput(this.activeInput - 1);
        },

        focusInput(input) {
            this.activeInput = Math.max(Math.min(3, input), 0);

            if (this.activeInput == 0) {
                this.$refs.code0.focus();
            }

            if (this.activeInput == 1) {
                this.$refs.code1.focus();
            }

            if (this.activeInput == 2) {
                this.$refs.code2.focus();
            }

            if (this.activeInput == 3) {
                this.$refs.code3.focus();
            }
        },

        pasteValues(element) {
            var numbers = element.split("");

            for (let index = 0; index < numbers.length; index++) {
                this.codeValidation[index] = numbers[index];
            }
        },
    },

    watch: {
        codeValidation() {
            if (this.codeValidation.length == 4) {
                this.codeValidation.map((number) => {
                    this.mountedCode += number;
                });

                this.$emit("getNumber", this.mountedCode);
                this.mountedCode = "";
            }

            if (this.codeValidation[0]) {
                this.$refs.code1.focus();
            }

            if (this.codeValidation[1]) {
                this.$refs.code2.focus();
            }

            if (this.codeValidation[2]) {
                this.$refs.code3.focus();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.u-code {
    display: flex;
    align-items: center;
    justify-content: center;

    .fieldCode {
        width: 70px;
        height: 90px;
        background: $white-color;
        border: 1px solid $grey3;
        border-radius: 8px;
        text-align: center;
        font-size: 36px;
        margin: 0 6px;
        box-sizing: border-box;

        @media (max-width: 900px) {
            width: 3rem;
            height: 4rem;
        }

        &:focus {
            border-color: $blue5;
            outline: none;
        }
    }
}
</style>