<template>
    <div class="organization-drive">
        <u-title size="2" class="mb-4">Arquivos</u-title>

        <div class="organization-drive-content">
            <div class="organization-drive-header">
                <div class="filter-content">
                    <div @click="openFilter" :class="['drive-filter', {'active' : dialogFilter}]">
                        <img class="icon-filter" src="/icons/arrow-filter.svg"/>

                        {{ filterText }}
                    </div>

                    <div :class="['filter', {'active' : dialogFilter}]" v-if="dialogFilter"> 
                        <div @click="setFilter('created')" class="filter-option">
                            <p> Álbum mais recente </p>
                        </div>

                        <div @click="setFilter('name')" class="filter-option">
                            <p> Título do álbum </p>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="loading" class="organization-drive-loading"> 
                <img src="/images/loading-ummense.gif"/>
            </div>

            <div v-if="!loading" class="albums"> 
                <router-link :to="{name: 'drive-album', params: {selectedAlbum: album.uuid, album: album}}" 
                    class="album" 
                    v-for="(album, index) in albums" 
                    :key="index"
                >
                    <div class="album-cover">
                        <u-thumbnail
                            class="thumb-document"
                            :file="album.file"
                            :isDrive="true"
                        ></u-thumbnail>
                    </div>

                    <p class="album-name"> {{ album.name }} </p>
                    <p class="album-date"> Criado em {{ formatDate(album.createdAt) }} </p>
                    <p class="files-count"> {{ album.filesCount }} arquivos </p>
                </router-link>

                <div v-if="pagination" @click="loadMoreAlbuns" class="load-more">
                    <p> Carregar mais </p>

                    <i class="fas fa-arrow-alt-circle-right"></i>
                </div>
            </div>

            <div class="no-data" v-if="!loading && albums && albums.length == 0">
                <p> Nenhum album de arquivos encontrado </p>
            </div>
        </div>
    </div>
</template>

<script>
    import UThumbnail from '@/assets/js/components/feed/UThumbnail'
    import { mapActions } from 'vuex'
    import moment from 'moment'
    import 'moment/locale/pt-br';

    export default { 
        components: { 
            UThumbnail  
        },

        data() {
            return {
                albums: null,
                loading: false,
                dialogFilter: false,
                currentFilter: 'created',
                pagination: null,
            }
        },

        computed: {
            filterText() { 
                if (this.currentFilter == 'created') return 'Álbum mais recente'

                return 'Título do álbum'
            }
        },

        created() { 
            this.setDriveAlbums()
        },

        methods: { 
            ...mapActions("Drive", [
                "getDriveAlbums"
            ]),

            setDriveAlbums() { 
                this.loading = true

                let payload = { 
                    filter: this.currentFilter
                }

                this.getDriveAlbums(payload).then((data) => {
                    this.albums = data.albums.items
                    this.pagination = data.albums.pagination.nextPage

                    if (this.currentFilter == 'created') { 
                        this.albums.reverse()
                    }

                    if (data.contactAlbum.file) { 
                        this.albums.push(data.contactAlbum)
                    }

                    this.loading = false
                })
            },

            loadMoreAlbuns() { 
                if (this.loading) return

                this.loading = true

                let payload = { 
                    pagination: this.pagination,
                    filter: this.currentFilter
                }

                this.getDriveAlbums(payload).then((data) => {
                    data.albums.items.map((album) => { 
                        this.albums.push(album)
                    })

                    this.pagination = data.albums.pagination.nextPage

                    this.loading = false
                }) 
            },

            formatDate(date) { 
                return moment(date).format('L')
            },

            setFilter(filter) {
                this.dialogFilter = false
                this.currentFilter = filter
                this.setDriveAlbums()
            },

            openFilter() { 
                this.dialogFilter = !this.dialogFilter
            }
        }
    }
</script>

<style lang="scss" scoped>
    .organization-drive-content { 
        width: 100%;
        height: fit-content;
        background-color: $white-color;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 80%;
        padding: 24px 0 24px 0;
        border-radius: 16px;
        min-height: calc(550px - 48px);

        &::-webkit-scrollbar {
            width: 0px;
        }

        &::-webkit-scrollbar-thumb {
            background: #dad7d7;
        }

        .organization-drive-header { 
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 24px;
        }

        .filter-content { 
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            -webkit-user-select: none;  
            -khtml-user-select: none;    
            -moz-user-select: none;     
            -ms-user-select: none;        
            user-select: none;   
        }

        .drive-filter { 
            cursor: pointer;
            color: $grey5;
            font-size: 13px;
            padding: 8px 25px;
            border-radius: 8px;

            .icon-filter { 
                filter: invert(51%) sepia(3%) saturate(1442%) hue-rotate(163deg) brightness(99%) contrast(89%);
                margin-right: 4px;
            }

            &:hover { 
                background-color: $grey1;
            }

            &.active { 
                color: $blue5;

                .icon-filter { 
                    filter: invert(64%) sepia(75%) saturate(5881%) hue-rotate(173deg) brightness(89%) contrast(98%);
                }
            }
        }

        .filter { 
            position: fixed;
            top: 135px;
            width: 170px;
            height: 100px;
            border-radius: 4px;
            background-color: $white-color;
            box-shadow: 0px 0px 6px rgba(16, 35, 34, 0.15);

            .filter-option { 
                cursor: pointer;
                height: 50%;
                display: flex;
                align-items: center;
                padding: 0 16px;

                &:hover { 
                    background-color: $blue1;
                }

                p { 
                    font-size: 15px;
                    color: $blue5;
                }
            }
        }

        .drive-album-title {
            font-weight: 600; 
            font-size: 22px;
            color: $grey8;
        }

        .organization-drive-loading { 
            padding: 0 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: calc(100% - 48px);

            img { 
                margin-top: 28%;
                width: 30px;
            }
        }

        .albums { 
            margin-top: 24px;
            padding: 0 24px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            row-gap: 24px;
        }

        .no-data { 
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            p { 
                color: $grey6;
                font-size: 16px;
                margin-top: 28%;
            }
        }

        .load-more { 
            cursor: pointer;
            margin: 0 8px 8px 8px;
            height: 144px;
            width: 144px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 8px;

            p {
                color: $grey5;
                font-size: 16px;
                margin-bottom: 12px;
            }

            svg { 
                color: $grey5;
                font-size: 20px;
            }

            &:hover{ 
                background-color: $blue1;

                p,svg { 
                    color: $blue5;
                }
            }
        }

        .album { 
            text-decoration: none;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            padding: 0 8px 8px 8px;
            border-radius: 16px;
            width: fit-content;

            &:hover { 
                box-shadow: 1px 2px 2px rgba(16, 35, 51, 0.06);
            }

            .album-cover { 
                height: 144px;
                width: 144px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;

                .thumb-document { 
                    height: 100%;

                    img { 
                        height: 100%;
                    }
                }
                
            }

            .album-name { 
                margin-top: 4px;
                font-size: 16px;
                color: $grey8;
            }

            .files-count, .album-date { 
                margin-top: 4px;
                font-size: 12px;
                color: $grey5;
            }
        }
    }
</style>