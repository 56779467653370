import axios from 'axios'
import store from '@/store/index'
import Cookies from 'js-cookie'

export default {
    updateAvatar: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.post(store.state.Auth.baseAppUri + '/organization/' + store.state.Auth.orgId + '/contacts/' + store.state.Auth.contact.id + '/update-avatar', 
                payload,
                {headers: {'Authorization': 'Bearer ' + store.state.Auth.token}
            }).then((data) => {
                commit('Auth/setContact', data.data.contact, {root: true})
                resolve(data.data)
            })
        })
    },

    updateContact: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.post(store.state.Auth.baseAppUri + '/contacts/' + store.state.Auth.contact.id, 
                payload,
                {headers: {'Authorization': 'Bearer ' + store.state.Auth.token}
            }).then((data) => {
                resolve(data)
            }).catch(({response}) => { 
                reject(response.data)
            })
        })
    },

    deleteAccount: ({commit}, payload) => { 
        return new Promise((resolve, reject) => {
            axios.post(store.state.Auth.baseAppUri + '/organization/' + store.state.Auth.orgId + '/contacts/' + store.state.Auth.contact.id, 
                {reason: payload},
                {headers: {'Authorization': 'Bearer ' + store.state.Auth.token}
            }).then((data) => {
                Cookies.remove('user-token')
                resolve(data)
            })
        })
    }
}