<template>
    <feed-editor
        v-if="isMounted"
        is-response
        placeholder="Adicione um comentário"
        :toolbarId="'toolbar' + post + toolbarName"
        :filteredProject="project.type == 'contacts' ? null : project"
        :parentId="post"
        @updatePosts="newComment"
        :class="noPadding ? 'toolbar-no-padding' : ''"
    ></feed-editor>
</template>

<script>
    import FeedEditor from '@/assets/js/components/feed/FeedEditor'
    import { hasPreference } from "@/assets/js/helpers/index.js";
    import { mapActions, mapGetters } from 'vuex'
    import UAvatar from '@/assets/js/components/UI/avatar/UAvatar'

    export default {
        components: {
            FeedEditor,
            UAvatar,
        },

        props: { 
            post: { 
                required: true,
                type: Number
            },

            toolbarName: { 
                default: '',
                type: String
            },

            noPadding: { 
                default: false,
                type: Boolean
            },

            project: { 
                required: true,
                type: [Object, Array]
            }
        },

        data() { 
            return {
                descriptionComment: null, 
                loading: false,
                isMounted: false,
            }
        },

        computed: {
            ...mapGetters('Auth', {
                appContact: 'getContact',
                preferences: "getPreferences"
            }),
        },

        mounted() {
            this.isMounted = true
        },

        methods: { 
            hasPreference,

            newComment(response) { 
                this.$emit('updateNewComment', response)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .comment-editor {
        width: 100%;
        min-height: 16px;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $grey0;

        .avatar-comment { 
            margin-right: 8px;
        }

        textarea { 
            outline: none;
            border: none;
            resize: none;
            width: 100%;
            font-size: 13px;
            background-color: $grey0;
        }

        p { 
            cursor: pointer;
            font-size: 14px;
            color: $blue4;
        }

        .icon-comment {
            cursor: pointer;
        }
    }
</style>