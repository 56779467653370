<template>
    <div class="u-time-range-picker">
        <v-popover placement="below">
            <div class="u-time-range-picker-field" style="margin-right: 12px; padding-left: 12px;">
                <p v-if="timeRange.startTime">{{ timeRange.startTime }}</p>
                <p v-else>Início</p>
            </div>

            <div slot="popover" v-close-popover class="u-time-range-picker-list" :style="formatedWidth">
                <div v-for="time in timeList" :key="time" @click="setStartTime(time)" class="u-time-range-picker-list-item">
                    <p>{{ time }}</p>
                </div>
            </div>
        </v-popover>

        <div class="u-time-range-picker-center-icon">
            <i class="fas fa-arrow-right"></i>
        </div>

        <v-popover placement="below">
            <div class="u-time-range-picker-field" style="padding-left: 18px">
                <p v-if="timeRange.endTime">{{ timeRange.endTime }}</p>
                <p v-else>Fim</p>
            </div>

            <div slot="popover" v-close-popover class="u-time-range-picker-list" :style="formatedWidth">
                <div v-for="time in timeList" :key="time" @click="setEndTime(time)" class="u-time-range-picker-list-item">
                    <p :style="timeStyle(time)">{{ time }}</p>
                </div>
            </div>
        </v-popover>

        <!-- <u-checkbox
            v-model="allDay"
            label="O dia todo"
            class="mt-7 ml-4"
        ></u-checkbox> -->
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: false
        },

        listWidth: { 
            required: false,
            default: '149px',
        }
    },

    mounted() {
        if (this.value && this.value != '%%empty_data%%') {
            this.timeRange = this.value
        }
    },

    data() {
        return {
            allDay: false,
            timeRange: {
                startTime: null,
                endTime: null
            }
        }
    },

    computed: {
        timeList() {
            let interval = 5
            let times = []
            let startTime = 0

            for (let i = 0; startTime < 24 * 60; i++) {
                let hours = Math.floor(startTime / 60)
                let minutes = (startTime % 60)
                times[i] = ("0" + (hours)).slice(-2) + ':' + ("0" + minutes).slice(-2)
                startTime = startTime + interval
            }

            return times
        },

        formatedWidth() { 
            return `width: ${this.listWidth}`;
        }
    },

    methods: {
        setStartTime(time) {
            this.timeRange.startTime = time

            if (this.timeRange.endTime && !this.isTimeAllowed(this.timeRange.endTime)) {
                return this.timeRange.endTime = null
            }

            if (this.timeRange.endTime) {
                this.$emit('change', this.timeRange)
            }
        },

        setEndTime(time) {
            if (!this.isTimeAllowed(time)) {
                return
            }

            this.timeRange.endTime = time
            this.$emit('change', this.timeRange)
        },

        timeStyle(time) {
            return 'color: ' + (this.isTimeAllowed(time) ? '#253542;' : '#B6BEC5;')
        },

        isTimeAllowed(time) {
            if (this.timeRange.startTime == null) {
                return false
            }

            let startHour = this.timeRange.startTime.substring(0, 2)
            let startMinutes = this.timeRange.startTime.substring(3)
            let endHour = time.substring(0, 2)
            let endMinutes = time.substring(3)

            return endHour > startHour || (endHour == startHour && endMinutes >= startMinutes)
        },        
    }
}
</script>

<style lang="scss" scoped>
// @import "~@sass/_variables.scss";

.u-time-range-picker {
    display: flex;
    align-items: center;
    position: relative;
}

.u-time-range-picker-center-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    position: absolute;
    left: 150px;
    border: 1px solid $grey1;
    background-color: $white-color;
    border-radius: 50%;

    svg {
        font-size: 12px;
        color: $grey6;
    }
}

.u-time-range-picker-field {
    display: flex;
    align-items: center;
    height: 48px;
    width: 149px;
    border: 1px solid $grey1;
    border-radius: 4px;
    cursor: pointer;
}

.u-time-range-picker-list {
    position: relative;
    z-index: 101;
    height: 216px;
    overflow: auto;
    background-color: #fff;
    border: 1px solid $grey1;
    border-radius: 4px;

    &::-webkit-scrollbar {
        width: 8px;
    }
}

.u-time-range-picker-list-item {
    height: 36px;
    padding: 0 12px;
    cursor: pointer;
}

</style>