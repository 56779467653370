<template>
    <div class="user-menu">
        <div @click="showUserBox = !showUserBox" class="user-menu-button">
            <u-avatar :user="user" :size="5"></u-avatar>

            <u-text class="user-menu-button-name">Olá, {{ user.name }}</u-text>

            <i class="icon icon-down-arrow-filled icon8 ml-3"></i>
        </div>
        
        <transition name="fade">
            <div v-if="showUserBox" v-on-clickaway="closeUserBox" class="user-menu-box">
                <div class="user-menu-box-data">
                    <u-avatar class="mb-3" :user="user" :size="2"></u-avatar>

                    <u-title size="3" color="grey8" :margin="false">{{ user.name }}</u-title>

                    <u-text size="3" color="grey6">{{ user.email }}</u-text>
                </div>

                <div @click="goToContactData" class="user-menu-box-item mt-3">
                    <i class="icon icon-user icon16 mr-2"></i>
                    <u-text>Minha conta</u-text>
                </div>

                <div class="user-menu-box-item" @click="leaveOrg">
                    <i class="icon icon-shutdown icon16 mr-2"></i>
                    <u-text>Sair</u-text>
                </div>
            </div>
        </transition>     
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'
import UAvatar from '@/assets/js/components/UI/avatar/UAvatar'

export default {
    mixins: [clickaway],

    components: {
        UAvatar,
    },

    computed: {
        ...mapGetters('Auth', {
            user: 'getContact',
        }),
    },

    data() {
        return {
            showUserBox: false,
        }
    },

    methods: {
        ...mapActions('Auth', ['logout']),

        leaveOrg() {
            this.logout().then((data) => {
                this.$router.push({ name: 'login' })
            })
        },

        goToContactData() {
            this.$router.push({ name: 'contact-data' })
            this.showUserBox = false
        },

        closeUserBox() {
            if (!this.showUserBox) return

            this.showUserBox = false
        }
    },
}
</script>

<style lang="scss" scoped>

.user-menu {
    position: relative;
}

.user-menu-button {
    display: flex;
    align-items: center;
    transition: color .2s;
    cursor: pointer;

    &:hover {
        color: $blue5;
    }

    .user-menu-button-name {
        margin-left: $spacing-3;

        @media (max-width: 425px) {
            display: none;
        }
    }
}

.user-menu-box {
    position: absolute;
    top: 35px;
    right: 0;
    padding: $spacing-4;
    background-color: $white-color;
    border-radius: 4px;
    box-shadow: 0px 0px 6px rgba(16, 35, 34, 0.15);
    z-index: 10;
    min-width: 165px;

    .user-menu-box-data {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .user-menu-box-item {
        display: flex;
        align-items: center;
        padding-top: $spacing-3;
        padding-bottom: $spacing-3;
        color: $grey7;
        transition: color .2s;
        cursor: pointer;

        &:hover {
            color: $blue5;
        }
    }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>