<template>
    <div class="notifications-modal-container">
        <div @click="showNotificationsBox = !showNotificationsBox" class="notifications-modal-icon">
            <i class="icon icon-bell icon20"></i>
        </div>

        <transition name="fade">
            <div v-if="showNotificationsBox" v-on-clickaway="closeNotificationsBox" class="notifications-modal">
                <div class="notifications-modal-header">
                    <u-title size="2" color="grey8" :margin="false">Notificações</u-title>
                </div>

                <div v-if="notifications.length <= 0" class="notifications-modal-empty">
                    <img src="/images/notifications/bell.png"/>

                    <u-title size="2" color="grey8">Você ainda não possui notificações</u-title>
                    <u-title size="3" color="grey5">Suas notificações aparecerão aqui</u-title>
                </div>

                <div v-else class="notifications-modal-list">
                    <div 
                        v-for="notification in notifications" 
                        :key="notification.id" 
                        class="notification"
                        @click="showPost(notification)"
                    >
                        <div>
                            <u-avatar
                                :user="{ name: notification.userName, avatar: notification.userAvatar }"
                                :size="3"
                                class="mr-4"
                            ></u-avatar>
                        </div>

                        <div class="notification-content">
                            <u-title size="3" color="grey8" :margin="false">{{ notification.userName }}</u-title>

                            <div class="d-flex">
                                <u-text size="3" color="grey4" ellipsis>{{ notificationDate(notification.createdAt) }}</u-text>
                                <i v-if="notification.logsModelName" class="icon icon-down-arrow-filled icon6 grey5 mt-1 ml-2 mr-2" style="transform: rotate(265deg);"></i>
                                <u-text v-if="notification.logsModelName" ellipsis size="3" color="grey4">{{ notification.logsModelName }}</u-text>
                            </div>
                            
                            <div class="notification-content-description" v-html="notification.content"></div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import UAvatar from '@/assets/js/components/UI/avatar/UAvatar'
    import moment from 'moment'
    import 'moment/locale/pt-br';
    import { mixin as clickaway } from 'vue-clickaway'
    import { mapActions } from 'vuex'

    export default {
        mixins: [clickaway],

        components: { 
            UAvatar,
        },

        data() { 
            return { 
                notifications: [],
                showNotificationsBox: false,
            }
        },

        mounted() {
            this.fetchNotifications()
        },

        methods: { 
            ...mapActions('Feed', ['getNotifications']),

            notificationDate(date) {
                return moment(date).fromNow()
            },

            fetchNotifications() {
                this.getNotifications().then((data) => {
                    this.notifications = data.result.notifications.items
                })
            },

            closeNotificationsBox() {
                if (!this.showNotificationsBox) return

                this.showNotificationsBox = false
            },

            showPost(notification) {
                this.$router.push({name: 'post', params: { id: notification.id } })
                this.showNotificationsBox = false
            }
        }
    }
</script>

<style lang="scss" scoped>

.notifications-modal-container {
    position: relative;
}

.notifications-modal-icon {
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $grey6;
    margin-right: $spacing-2;
    border-radius: 4px;
    transition: all .2s;
    cursor: pointer;

    &:hover {
        color: $blue5;
        background-color: $blue1;
    }
}

.notifications-modal {
    width: 464px;
    position: absolute;
    top: 35px;
    right: 5px;
    background-color: $white-color;
    border-radius: 4px;
    box-shadow: 0px 0px 6px rgba(16, 35, 34, 0.15);
    z-index: 10;

    @media( max-width: 765px ) { 
        position: fixed;
        right: 58px;
        left: 10px;
        width: 96.5%;
    }
}

.notifications-modal-header {
    padding: $spacing-4;
    box-shadow: inset 0px -1px 0px #F3F4F5;
}

.notifications-modal-list {
    max-height: 70vh;
    overflow: auto;

    &:hover::-webkit-scrollbar-thumb{
        background: $grey2;
    }
    
    &::-webkit-scrollbar {
        margin-top: 3px;
        width: 8px;
        height: 1.2rem !important;
    }

    &::-webkit-scrollbar-track {
        background: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 50px;
        background-clip: padding-box;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $grey5;
    }
}

.notifications-modal-empty {
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img { 
        width: 105px;
        margin-bottom: $spacing-8;
    }
}

.notification {
    display: flex;
    align-items: center;
    padding: $spacing-4;
    transition: all .2s;
    cursor: pointer;

    &:hover {
        background-color: $grey1;
    }

    .notification-content {
        width: 85%;
        overflow: hidden;

        .notification-content-description {
            font-size: $text-2;
            color: $grey5;
            margin-top: $spacing-2;
        }
    }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>