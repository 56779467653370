<template>
    <div class="video-player">
        <video controls disablePictureInPicture controlsList="nodownload" class="video-player-item">
            <source :src="file.link" :type="mimeType">
        </video>
    </div>
</template>

<script>
export default {
    props: {
        file: {
            required: true,
        },
    },

    data() {
        return {
            video: null,
        }
    },

    computed: {
        mimeType() {
            if (this.file.type == 'mp4') {
                return 'video/mp4'
            }

            if (this.file.type == 'webm') {
                return 'video/webm'
            }   

            return 'video/*'
        },
    },
}
</script>

<style lang="scss" scoped>
.video-player {
    margin: 12px 24px 0 24px;

    .video-player-item {
        width: 100%;
        border-radius: 16px;
    }
}
</style>