<template>
    <div class="post">
        <div v-if="loading" class="post-loading">
            <u-ghost-loader height="22px" width="98px" :has-opacity="false" class="mb-4"></u-ghost-loader>
            <u-ghost-loader height="555px" radius="16px"></u-ghost-loader>
        </div>

        <div v-else-if="!post">
            <u-text>Postagem não encontrada.</u-text>
        </div>

        <div v-else>
            <div class="d-flex mb-5">
                <v-popover trigger="hover" placement="bottom-end">
                    <router-link :to="{ name: 'feed' }" class="post-back-button">
                        <i class="icon icon-left-arrow-2"></i>
                    </router-link>

                    <div slot="popover" v-close-popover class="post-back-tooltip">
                        <u-text size="3">{{ currentProjectName }}</u-text>
                    </div>
                </v-popover>
                
                <u-title size="2" color="grey8" :margin="false">Publicação de {{ post.user ? post.user.name : '' }}</u-title>
            </div>

            <post :post="post"></post>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Post from './Post'

export default {
    data() {
        return {
            loading: false,
            post: null,
        }
    },

    components: {
        Post,
    },

    computed: {
        ...mapState('Feed', ['selectedProject']),
        
        currentProjectName() {
            const name = this.selectedProject ? this.selectedProject.name : 'Feed geral'

            return 'Voltar para ' + name
        }
    },

    mounted() {
        this.fetchPost()
    },

    methods: {
        ...mapActions('Feed', [
            'getPost',
            'getPostComments',
        ]),

        fetchPost() {
            this.loading = true

            this.getPost(this.$route.params.id).then(post => {
                this.post = post
                this.fetchComments()
            })
        },

        fetchComments() {
            if (!this.post || (this.post && this.post.commentsTotal == 0)) {
                return this.loading = false
            }

            this.getPostComments(this.post.id).then(comments => {
                this.post.commentsTotal = comments.length
                this.post.comments = comments
            }).finally(() => this.loading = false)
        }
    },

    watch: {
        '$route'(to) {
            if (!to) return

            this.fetchPost()
        }
    }
}
</script>

<style lang="scss" scoped>

.post-back-button {
    height: 28px;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $grey8;
    margin-right: $spacing-4;
    border-radius: 4px;
    transition: all .2s;

    &:hover {
        color: $blue5;
        background-color: $blue0;
    }

    &:active {
        color: $white-color;
        background-color: $blue8;
    }
}

.post-back-tooltip {
    padding: $spacing-4;
    background-color: $white-color;
    box-shadow: 0px 0px 4px rgba(16, 35, 51, 0.15);
    border-radius: 0px 8px 8px 8px;
}

</style>