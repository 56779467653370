<template>
    <div v-if="dialog" class="modal-project-filter">
        <div class="mask"></div>

        <div class="project-filter" v-on-clickaway="closeModal">
            <div v-for="(project, index) in projectsFilter" 
                :key="index" 
                @click="selectProject(project)"
                class="projects"
            >
                <div class="project-info">
                    <p
                        class="project-name"
                        v-html="project.name"
                    ></p>

                    <div class="project-interaction-info">
                        <u-icon
                            v-if="project.status == 'completed'"
                            icon="check-circle"
                            colorClass="green5"
                            class="project-info-icon"
                            :width="12"
                            :height="12"
                        ></u-icon>

                        <p
                            class="last-interaction"
                            v-html="'Última interação ' + 
                            projectDate(project.lastInteraction)"
                        ></p>
                    </div>
                </div>
            </div>

            <div v-if="pagination != null" @click="getProjects" class="button-more-projects">
                <p> Carregar mais projetos </p>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import { mixin as clickaway } from 'vue-clickaway'
    import moment from 'moment'
    import 'moment/locale/pt-br'

    export default { 
        mixins: [ clickaway ],

        props: {
            dialog: {
                required: true,
                type: Boolean
            },
        },

        data() { 
            return { 
                pagination: 1,
                projectsFilter: null,
            }
        },

        created() {
            this.getProjects()
        },

        methods: { 
            ...mapActions("Feed", [
                "getProjects"
            ]),

            getProjects() { 
                if (this.loading) return

                let payload = {
                    pagination: this.pagination,
                }

                this.loading = true

                this.getProjects(payload).then((filters) => {
                    if (this.pagination == 1) { 
                        this.projectsFilter = filters.data.projects.sort((projectA, projectB) => (new Date(projectA.lastInteraction) > new Date(projectB.lastInteraction)) ? 1 : ((new Date(projectB.lastInteraction) > new Date(projectA.lastInteraction)) ? -1 : 0))
                        this.projectsFilter.reverse()
                        this.pagination = filters.data.pagination.nextPage
                        this.loading = false
                        return
                    }

                    this.projectsFilter = this.projectsFilter.concat(filters.data.projects)
                    this.pagination = filters.data.pagination.nextPage
                    this.loading = false
                }) 
            },
            
            selectProject(project) {
                this.$emit('filterProjects', project)
            },

            closeModal() { 
                this.$emit('closeModal')
            },

            projectDate(date) {
                return moment(date).fromNow()
            },
        }
    }
</script>

<style lang="scss" scoped>
    .modal-project-filter {
        display: flex;
        align-items: center;
        justify-content: center;

        .mask { 
            background-color: rgba(0, 0, 0, 0.7);
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        }

        .project-filter { 
            position: absolute;
            top: 80px;
            background-color: $white-color;
            border-radius: 4px;
            width: 640px;
            padding: 15px;
            max-height: calc(600px - 38px);
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1.2rem 0;
            overflow-y: scroll;
            z-index: 50;

            @media (max-width: 900px) {
                width: 80%;
            }

            .projects { 
                cursor: pointer;
                border-radius: 15px;
                background-color: $white-color;
                padding: 20px;
                height: 80px;
                display: flex;
                align-items: center;
                width: calc(90% - 40px);
                margin-top: 4px;
                box-shadow: $shadow-2;

                &:hover { 
                    background-color: $grey1;

                    p, .project-info p { 
                        color: $grey8;
                    }
                }

                &:active { 
                    background-color: $blue5;

                    p, .project-info p { 
                        color: $white-color;
                    }
                }

                .project-info { 
                    display: flex;
                    flex-direction: column;

                    .project-name { 
                        color: $grey8;
                        font-size: 16px;
                    }

                    .last-interaction { 
                        color: $grey4;
                        font-size: 12px;
                    }

                    .project-interaction-info { 
                        margin-top: 2px;
                        display: flex;
                        align-items: center;

                        .project-info-icon { 
                            margin-right: 4px;
                        }
                    }
                }
            }

            .button-more-projects { 
                cursor: pointer;
                margin-top: 8px;
                padding: 10px;
                width: 90%;
                min-height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 15px;
                font-size: 18px;

                &:hover { 
                    background-color: $blue1;
                    color: $blue5;
                }

                &:active { 
                    background-color: $blue8;
                    color: $white-color;
                }
            }

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background: #dad7d7;
            }
        }
    }
</style>