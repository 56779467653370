import { filesFilterDefaultValues } from '../../../assets/js/helpers/index.js'

export default {
    /**
     * Adiciona um novo arquivo no Vuex
     * @param {Object} file arquivo à ser adicionado
     * @version 0.0.1
     */
    addFile: (state, file) => {
        state.files.unshift(file);
    },

    /**
     * Remove um arquivo no Vuex
     * @param {Object} file arquivo à ser removido
     * @version 0.0.1
     */
    removeFile: (state, currentFile) => {
        let fileIndex = state.files.findIndex(
            file => file.id == currentFile.id || file.uuid == currentFile.id
        );
        if (fileIndex >= 0) {
            state.files.splice(fileIndex, 1);
        }
    },

    /**
     * Atualiza o filtro de arquivos no Vuex
     * @version 0.0.1
     * @param {Array} filter dados do filtro atual
     */
    setFilesFilter: (state, filter) => {
        state.filesFilter = null;
        state.filesFilter = filter;
    },

    /**
     * Limpa o filtro de arquivos e atribui os valores padrões
     * @version 0.0.1
     */
    clearFilesFilter: state => {
        state.filesFilter = filesFilterDefaultValues();
    },

    /**
     * Atualiza os dados do arquivo atual no Vuex
     * @version 0.0.1
     * @param {Object} file = arquivo atual
     */
    updateFileData: (state, file) => {
        state.files.map((currentFile, index) => {
            if (currentFile.id == file.id) {
                for (var index in file) {
                    currentFile[index] = file[index];
                }
            }
        });
    },

    setModalLimitReached: state => {
        state.modalLimitReached = !state.modalLimitReached;
    },

    setFilesSent: (state, fileData) => {
        if (fileData.fileMeta.resendUploadId) {
            let fileIndex = state.filesSent.findIndex(
                file => fileData.fileMeta.resendUploadId == file.uploadId
            );

            if (fileIndex > -1) {
                return state.filesSent.splice(fileIndex, 1, fileData);
            }
        }
        
        state.filesSent.push(fileData);
    },

    removeFilesSent: (state, fileData) => {
        let fileIndex = state.filesSent.findIndex(
            file => file.uploadId == fileData.uploadId
        );

        if (fileIndex > -1) {
            state.filesSent.splice(fileIndex, 1);
        }        
    },

    updateFilesSent: (state, fileData) => {
        let fileIndex = state.filesSent.findIndex(
            file => file.uploadId == fileData.uploadId
        );

        if (fileIndex > -1) {
            state.filesSent.splice(fileIndex, 1, fileData);
        }        
    },
};