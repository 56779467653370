var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-menu-header"},[_c('div',{staticClass:"side-menu-header-input-popover"},[_c('div',{class:['side-menu-header-input', { 'side-menu-header-input-focus': _vm.focusInSearchField  }]},[_c('i',{staticClass:"icon icon-search-1 icon16 grey4 ml-4"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],attrs:{"type":"text","placeholder":_vm.placeholder},domProps:{"value":(_vm.search)},on:{"input":[function($event){if($event.target.composing)return;_vm.search=$event.target.value},_vm.searchItem],"focus":function($event){_vm.focusInSearchField = true},"blur":function($event){_vm.focusInSearchField = false}}})]),_c('transition',{attrs:{"name":"fade"}},[(!_vm.search && _vm.focusInSearchField)?_c('div',{staticClass:"side-menu-header-input-popover-box"},[_c('u-text',{attrs:{"size":"3","color":"grey5"}},[_vm._v(_vm._s(_vm.popoverText))])],1):_vm._e()])],1),(_vm.showSortButton || _vm.showSortButton)?_c('div',{staticClass:"side-menu-header-actions"},[(_vm.showSortButton)?_c('v-popover',{attrs:{"placement":"bottom-start","popover-class":"side-menu-header-actions-popover"},on:{"show":function($event){_vm.sortPopoverIsOpen = true},"hide":function($event){_vm.sortPopoverIsOpen = false}}},[_c('div',{class:['side-menu-header-actions-item', { 'side-menu-header-actions-item-open': _vm.sortPopoverIsOpen }]},[_c('i',{staticClass:"icon icon-order-1 icon12"})]),_c('div',{directives:[{name:"close-popover",rawName:"v-close-popover"}],attrs:{"slot":"popover"},slot:"popover"},[_c('div',{staticClass:"side-menu-header-actions-box"},[_c('div',{staticClass:"side-menu-header-actions-box-header"},[_c('u-text',{attrs:{"size":"3","color":"grey4"}},[_vm._v(_vm._s(_vm.sortButtonTitle))])],1),(_vm.isFeedPage)?_c('div',{class:[
                        'side-menu-header-actions-box-item', 
                        { 'side-menu-header-actions-box-item-active': _vm.sortActive == 'lastInteraction' }
                    ],on:{"click":function($event){return _vm.$emit('sort-list', 'lastInteraction')}}},[_c('u-text',{staticClass:"side-menu-header-actions-box-item-title",attrs:{"semibold":""}},[_vm._v("Última interação (padrão)")]),_c('u-text',{staticClass:"side-menu-header-actions-box-item-text",attrs:{"size":"3"}},[_vm._v("Exibe cards com interação mais recente primeiro")])],1):_vm._e(),(_vm.isFeedPage)?_c('div',{class:[
                        'side-menu-header-actions-box-item', 
                        { 'side-menu-header-actions-box-item-active': _vm.sortActive == 'created' }
                    ],on:{"click":function($event){return _vm.$emit('sort-list', 'created')}}},[_c('u-text',{staticClass:"side-menu-header-actions-box-item-title",attrs:{"semibold":""}},[_vm._v("Data de criação")]),_c('u-text',{staticClass:"side-menu-header-actions-box-item-text",attrs:{"size":"3"}},[_vm._v("Exibe cards criados recentemente primeiro")])],1):_vm._e(),(_vm.isFeedPage)?_c('div',{class:[
                        'side-menu-header-actions-box-item', 
                        { 'side-menu-header-actions-box-item-active': _vm.sortActive == 'name' }
                    ],on:{"click":function($event){return _vm.$emit('sort-list', 'name')}}},[_c('u-text',{staticClass:"side-menu-header-actions-box-item-title",attrs:{"semibold":""}},[_vm._v("Título do card")]),_c('u-text',{staticClass:"side-menu-header-actions-box-item-text",attrs:{"size":"3"}},[_vm._v("Exibe os cards em ordem alfabética")])],1):_vm._e(),(_vm.isUdrivePage)?_c('div',{class:[
                        'side-menu-header-actions-box-item', 
                        { 'side-menu-header-actions-box-item-active': _vm.sortActive == 'created' }
                    ],on:{"click":function($event){return _vm.$emit('sort-list', 'created')}}},[_c('u-text',{staticClass:"side-menu-header-actions-box-item-title",attrs:{"semibold":""}},[_vm._v("Álbum mais recente")]),_c('u-text',{staticClass:"side-menu-header-actions-box-item-text",attrs:{"size":"3"}},[_vm._v("Exibe os álbuns criados recentemente primeiro")])],1):_vm._e(),(_vm.isUdrivePage)?_c('div',{class:[
                            'side-menu-header-actions-box-item', 
                            { 'side-menu-header-actions-box-item-active': _vm.sortActive == 'name' }
                    ],on:{"click":function($event){return _vm.$emit('sort-list', 'name')}}},[_c('u-text',{staticClass:"side-menu-header-actions-box-item-title",attrs:{"semibold":""}},[_vm._v("Título do álbum")]),_c('u-text',{staticClass:"side-menu-header-actions-box-item-text",attrs:{"size":"3"}},[_vm._v("Exibe os álbuns em ordem alfabética")])],1):_vm._e()])])]):_vm._e(),(_vm.showFilterButton)?_c('a',{class:['side-menu-header-actions-item', { 'side-menu-header-actions-item-filter': _vm.filterIsActive }, { 'side-menu-header-actions-item-active': _vm.showFilter }],attrs:{"href":""},on:{"click":function($event){$event.preventDefault();_vm.showFilter = !_vm.showFilter}}},[_c('i',{staticClass:"icon icon-filter icon12"}),(_vm.filterIsActive)?_c('div',{staticClass:"side-menu-header-actions-item-clear",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('clear-filter')}}},[_c('i',{staticClass:"icon icon-close-x icon7 grey6"})]):_vm._e()]):_vm._e()],1):_vm._e(),(_vm.isFeedPage && _vm.showFilter)?_c('side-menu-header-filter',{attrs:{"companies":_vm.companies},on:{"close-filter":function($event){_vm.showFilter = false},"clear-filter":function($event){return _vm.$emit('clear-filter')},"refresh-list":function($event){return _vm.$emit('refresh-list')}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }