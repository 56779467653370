import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const drive = { 
    state: {
        selectedAlbum: null,
    },
    namespaced: true,
    mutations,
    actions,
    getters,
}

export default drive