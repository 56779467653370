import axios from 'axios'
import store from '@/store/index'

export default {
    getForms: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.get(store.state.Auth.baseAppUri + '/contacts/' + store.state.Auth.contact.id + '/forms', {
                headers: {'Authorization': 'Bearer ' + store.state.Auth.token}
            }).then((data) => {
                resolve(data.data)
            })
        })
    },

    getFormsResponses: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.get(store.state.Auth.baseAppUri + '/contacts/' + store.state.Auth.contact.id + '/form-responses', {
                headers: {'Authorization': 'Bearer ' + store.state.Auth.token},
                params: payload,
            }).then((data) => {
                resolve(data.data.result)
            })
        })
    },

    getFormResponse: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.get(store.state.Auth.baseAppUri + '/contacts/' + store.state.Auth.contact.id + '/form-responses/' + payload, {
                headers: {'Authorization': 'Bearer ' + store.state.Auth.token}
            }).then((data) => {
                resolve(data.data.result)
            })
        })
    },

    updateResponse: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.post(store.state.Auth.baseAppUri + '/forms/form-response', 
                payload,
                {headers: {'Authorization': 'Bearer ' + store.state.Auth.token}
            }).then((data) => {
                resolve(data.data.result)
            }).catch(() => {
                reject()
            })
        })
    }
}