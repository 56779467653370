<template>
    <div class="contact-change-password">
        <p class="password-title">Alterar senha</p>

        <div class="input-field">
            <p class="label">Insira a nova senha</p>

            <div class="input-field-container">
                <input
                    :type="passwordFieldType"
                    v-model="password"
                    placeholder="******"
                    ref="refPassword"
                />
                <div @click="changeFieldType('password')">
                    <div key="password1" v-if="passwordFieldType == 'password'">
                        <i class="fas fa-eye-slash"></i>
                    </div>
                    <div key="password2" v-else>
                        <i class="fas fa-eye"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="input-field">
            <p class="label">Confirme a nova senha</p>

            <div class="input-field-container">
                <input
                    :type="passwordConfirmFieldType"
                    v-model="passwordConfirm"
                    placeholder="******"
                    ref="refPasswordConfirm"
                />
                <div @click="changeFieldType('passwordConfirm')">
                    <div
                        key="password1"
                        v-if="passwordConfirmFieldType == 'password'"
                    >
                        <i class="fas fa-eye-slash"></i>
                    </div>
                    <div key="password2" v-else>
                        <i class="fas fa-eye"></i>
                    </div>
                </div>
            </div>
        </div>

        <p v-if="errorMessage" class="error-text">{{ errorMessage }}</p>

        <div @click="sendConfirmationCode" class="contact-change-password-save">
            <u-button
                text="Salvar"
                :loading="loading"
                :disabled="!password || !passwordConfirm"
            ></u-button>
        </div>

        <!-- <contact-change-password-confirm-modal
            :password="password"
            @clear-password="clearPassword"
            ref="refContactChangePasswordConfirmModal"
        ></contact-change-password-confirm-modal> -->
    </div>
</template>

<script>
// import ContactChangePasswordConfirmModal from './ContactChangePasswordConfirmModal'
import UButton from '@/assets/js/components/UI/button/UButton'
import { mapActions } from 'vuex'

export default {
    components: {
        // ContactChangePasswordConfirmModal,
        UButton,
    },

    data() {
        return {
            errorMessage: false,
            password: null,
            passwordConfirm: null,
            loading: false,
            passwordFieldType: 'password',
            passwordConfirmFieldType: 'password',
        }
    },

    methods: {
        ...mapActions('Auth', ['updatePassword', 'generatePasswordCode']),

        sendConfirmationCode() {
            if (this.password != this.passwordConfirm) {
                return (this.errorMessage = 'As senhas devem ser iguais')
            }

            if (!this.password || (this.password && this.password.length < 6)) {
                return (this.errorMessage =
                    'A senha deve ter no mínimo 6 caracteres')
            }

            this.loading = true

            this.updatePassword({
                code: this.code,
                password: this.password,
            }).then((message) => {
                this.$toast.open({
                    message: message,
                })
                this.password = null
                this.passwordConfirm = null
            }).catch(() => {
                this.$toast.open({
                    message: 'Não foi possível atualizar a senha',
                    type: 'error',
                })

            })
            .finally(() => (this.loading = false))

            // this.generatePasswordCode()
            //     .then(() =>
            //         this.$refs.refContactChangePasswordConfirmModal.openModal()
            //     )
            //     .finally(() => (this.loading = false))
        },

        // clearPassword() {
        //     this.password = null
        //     this.passwordConfirm = null
        // },

        changeFieldType(field) {
            if (field == 'password' && this.password) {
                this.passwordFieldType =
                    this.passwordFieldType == 'password' ? 'text' : 'password'
                this.$nextTick(() => this.$refs.refPassword.focus())
            }

            if (field == 'passwordConfirm' && this.passwordConfirm) {
                this.passwordConfirmFieldType =
                    this.passwordConfirmFieldType == 'password'
                        ? 'text'
                        : 'password'
                this.$nextTick(() => this.$refs.refPasswordConfirm.focus())
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.contact-change-password {
    margin-top: 24px;
    background-color: $white-color;
    padding: 24px;
    border-radius: 8px;
    width: 370px;
    height: fit-content;
    display: flex;
    flex-direction: column;

    @media (max-width: 900px) { 
        width: calc(100% - 48px);
    }

    .password-title {
        color: $grey8;
        font-weight: 600;
        font-size: 19px;
    }

    .input-field {
        margin-top: 12px;
    }

    .label {
        position: relative;
        top: 10px;
        left: 8px;
        background-color: $white-color;
        width: fit-content;
        padding: 0 5px;
        border-radius: 8px;
        z-index: 1;
    }

    .input-field-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        input {
            width: 100%;
            padding: 14px 41px 14px 11px;
            font-size: 15px;
            color: $grey8;
            border: 1px solid $grey2;
            border-radius: 4px;
            background-color: $white-color;
            min-height: 20px;
            outline: none !important;

            &:focus {
                border-color: $blue5;
            }
        }

        svg {
            position: absolute;
            top: 18px;
            right: 3px;
            color: $grey6;
            margin-right: 8px;
            cursor: pointer;
        }
    }

    .error-text {
        background-color: $pink1;
        border: 1px solid $pink5;
        color: $pink5;
        border-radius: 5px;
        padding: 5px 10px;
        margin-top: 12px;
        margin-bottom: 12px;
        font-size: 14px;
    }

    .contact-change-password-save {
        margin-top: 16px;
        width: 77px;
    }
}
</style>