<template>
    <div :class="['header-menu-nav', { 'header-menu-nav-mobile': mobile }]">
        <router-link :to="{ name: 'feed' }" :class="[{ 'header-menu-nav-item-active': isActive('feed') }]">
            <i :class="['icon icon20 mr-3', { 'icon-home': !isActive('feed') }, { 'icon-home-fill': isActive('feed') }]"></i>
            <u-text class="header-menu-nav-text">Feed</u-text>
        </router-link>
        <router-link :to="{ name: 'make-requests' }" :class="[{ 'header-menu-nav-item-active': isActive('make-requests') || isActive('my-requests') }]">
            <i :class="[
                'icon icon20 mr-3', 
                { 'icon-comment-alt': !isActive('make-requests') && !isActive('my-requests') }, 
                { 'icon-comment-fill': isActive('make-requests') || isActive('my-requests') }
            ]"></i>
            <u-text class="header-menu-nav-text">Solicitações</u-text>
        </router-link>
        <router-link :to="{ name: 'drive-album' }" :class="[{ 'header-menu-nav-item-active': isActive('drive-album') }]">
            <i :class="['icon icon20 mr-3', { 'icon-udrive': !isActive('drive-album') }, { 'icon-udrive-fill': isActive('drive-album') }]"></i>
            <u-text class="header-menu-nav-text">Arquivos</u-text>
        </router-link>
        <router-link :to="{ name: 'settings' }" :class="[{ 'header-menu-nav-item-active': isActive('settings') }]">
            <i :class="['icon icon20 mr-3', { 'icon-company': !isActive('settings') }, { 'icon-company-fill': isActive('settings') }]"></i>
            <u-text class="header-menu-nav-text">Sobre</u-text>
        </router-link>
    </div>
</template>

<script>
export default {
    props: {
        mobile: {
            type: Boolean,
            default: false,
        }
    },

    methods: {
        isActive(page) {
            return this.$route.name == page
        }
    }
}
</script>

<style lang="scss" scoped>

.header-menu-nav {
    display: flex;
    align-items: center;

    @media (max-width: 1024px) {
        display: none;
    }

    &.header-menu-nav-mobile {
        display: none;

        @media (max-width: 1024px) {
            display: flex;
            justify-content: center;
            width: 100%;
            position: fixed;
            bottom: 0;
            right: 0;
            left: 0;
            background-color: $white-color;
            z-index: 10;
        }
        
        @media (max-width: 435px) {
            justify-content: space-between;
        } 
    }

    a {
        height: 52px;
        display: flex;
        align-items: center;
        padding-right: $spacing-5;
        padding-left: $spacing-5;
        color: $grey5;
        cursor: pointer;
        border-radius: 8px;
        transition: all .2s;

        &:hover {
            color: $grey7;
            background-color: $grey1;
        }

        &.header-menu-nav-item-active {
            color: $blue5;
        }

        @media (max-width: 435px) {
            .icon {
                margin-right: 0 !important;
            }

            .header-menu-nav-text {
                display: none;
            }
        }
    }
}

</style>