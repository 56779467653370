<template>
    <div v-if="dialog" class="modal-image" ref="document" @keydown.esc.stop="closeModal" tabindex="0">
        <div class="document-box">
            <div class="document-box-header">
                <div v-popover:close @click="closeModal" class="button close">
                    <u-icon
                        icon="close"
                        colorClass="hover-white-red5"
                        class="clear-button"
                        :width="16"
                        :height="16"
                    ></u-icon>
                    <popover name="close" delay="100" event="hover" width="fit-content">
                        <p>Fechar</p>
                    </popover>
                </div>

                <div class="header-actions">
                    <div v-popover:open-outside @click="openOtherWindow" class="button action">
                        <u-icon
                            icon="open-outside"
                            colorClass="hover-white-blue5"
                            class="clear-button"
                            :width="16"
                            :height="16"
                        ></u-icon>
                        <popover name="open-outside" delay="100" event="hover" width="fit-content">
                            <p>Abrir em nova aba</p>
                        </popover>
                    </div>

                    <div v-popover:download @click="downloadItem" class="button action">
                        <u-icon
                            icon="download-2"
                            colorClass="hover-white-blue5"
                            class="clear-button"
                            :width="16"
                            :height="16"
                        ></u-icon>
                        <popover name="download" delay="100" event="hover" width="fit-content" >
                            <p>Download</p>
                        </popover>
                    </div>

                    <div v-popover:coments @click="showComments" class="button action">
                        <u-icon
                            :icon="commentsOn ? 'dialog-fill' : 'dialog'"
                            colorClass="hover-white-blue5"
                            class="clear-button"
                            :width="16"
                            :height="16"
                        ></u-icon>
                        <popover name="coments" delay="100" event="hover" width="fit-content" >
                            <p> {{ commentsOn ? 'Ocultar' : 'Exibir' }} publicação</p>
                        </popover>
                    </div>
                </div>
            </div>

            <div class="image-mask">
                <img v-if="fileType == 'image'" :src="image.link"/>
            </div>

            <u-thumbnail
                class="thumb-document"
                :file="image"
                v-if="fileType == 'document'"
            ></u-thumbnail>
        </div>

        <div v-if="commentsOn && showSidebar" class="comments-box" id="commentBox">
            <div v-if="!$isMobile()" class="post-description">
                <div class="post-header">
                    <u-avatar
                        :user="post.currentPost.user"
                        :size="4"
                    ></u-avatar>

                    <div class="post-header-info">
                        <p
                            class="post-user"
                            v-html="post.currentPost.user.name"
                        ></p>

                        <p
                            class="post-date"
                            v-html="commentDate(post.currentPost.createdAt)"
                        ></p>
                    </div>
                </div>

                <div v-if="post.currentPost.description" class="post-content">
                    <p
                        :class="['post-text', {'is-activated' : seeActivated}]"
                        v-html="replaceDescription(post.currentPost.description)"
                    ></p>

                    <p
                        v-if="textSize"
                        class="see-more"
                        @click="seeMore"
                        v-html="isActivated"
                    ></p>
                </div>
            </div>

            <div v-if="$isMobile()" class="title-mobile">
                Comentários
            </div>

            <div class="no-comments" v-show="!post.comments.length">
                <p>Esta publicação não possui comentários.</p>
            </div>

            <div class="comment" v-for="(comment, index) in post.comments" :key="index">
                <div class="comment-info">
                    <u-avatar
                        :user="comment.user"
                        :size="5"
                    ></u-avatar>

                    <p
                        class="comment-name"
                        v-html="comment.user.name"
                    ></p>

                    <p
                        class="comment-date"
                        v-html="commentDate(comment.createdAt)"
                    ></p>
                </div>

                <div class="comment-description">
                    <p
                        v-html="comment.description"
                    ></p>
                    <div v-if="comment.file" style="cursor: pointer;">
                        <a  :href="comment.file.link" target="_blank">
                            <u-thumbnail
                                :tinyIcon="true"
                                :file="comment.file"
                                :isDrive="true"         
                                :hasRadius="true"
                                height="64px"
                                width="64px"
                            ></u-thumbnail>
                        </a>
                    </div>
                </div>
            </div>

            <div v-if="!hasPreference('user', 'canReplyPosts') ? true : preferences.user.canReplyPosts" class="new-comment">
                <comment-editor
                    class="modal-editor"
                    :post="post.currentPost.id"
                    :project="post.currentPost.model"
                    toolbarName="modaImage"
                    :noPadding="true"
                    @updateNewComment="updateNewComment"
                ></comment-editor>
            </div>
        </div>
    </div>
</template>

<script>
    import { hasPreference } from "@/assets/js/helpers/index.js"
    import {mapGetters} from 'vuex'
    import axios from 'axios'
    import { mixin as clickaway } from 'vue-clickaway';
    import moment from 'moment'
    import 'moment/locale/pt-br';
    import UThumbnail from '@/assets/js/components/feed/UThumbnail'
    import UAvatar from '@/assets/js/components/UI/avatar/UAvatar'
    import CommentEditor from '@/assets/js/components/feed/CommentEditor'

    export default {
        mixins: [ clickaway ],

        components: {
            CommentEditor,
            UThumbnail,
            UAvatar
        },

        props: {
            dialog: {
                required: true,
                type: Boolean
            },

            image: {
                required: true,
                type: Object
            },

            post: {
                required: false,
                type: Object,
            },

            hasComment: { 
                default: false,
                type: Boolean
            },

            showSidebar: { 
                default: true,
                type: Boolean
            }
        },

        data() {
            return {
                commentsOn: true,
                seeActivated: false,
                textSize: false,
            }
        },

        created() {
            if (this.$isMobile()) {
                this.commentsOn = false
            }

            this.$nextTick(() => {
                this.checkSize()
                this.$refs.document.focus()
            })
        },

        computed: {
            ...mapGetters("Auth", {
                preferences: "getPreferences",
                storageUrl: 'getStorageUrl',
                user: "getContact"
            }),

            fileType() {
                if (this.image.type == 'png'
                || this.image.type == 'jpg'
                || this.image.type == 'png'
                || this.image.type == 'gif'
                || this.image.type == 'svg'
                || this.image.type == 'jpeg'
                || this.image.type == 'bmp') {
                    return 'image'
                }

                return 'document'
            },

            isActivated() {
                if (this.seeActivated) {
                    return "Ver menos"
                }

                return "Ver mais"
            }
        },

        methods: {
            hasPreference,

            closeModal() {
                this.$emit('closeModal')
            },

            showComments() {
                this.commentsOn = !this.commentsOn
            },

            openOtherWindow() {
                window.open(this.image.link, '_blank')
            },

            commentDate(date) {
                return moment(date).fromNow()
            },

            updateNewComment(comment) {
                this.$emit('updateNewComment', comment, true)
            },

            seeMore() {
                this.seeActivated = !this.seeActivated
            },

            checkSize() {
                if (!this.hasComment || !this.post.currentPost.description || this.$isMobile()) return

                var style = document.querySelector('.post-text')

                if (style.offsetHeight >= 286) {
                    this.textSize = true
                    return
                }

                this.textSize = false
            },

            downloadItem () {
                var url = this.image.link.replace(
                    'https://storage-homologation.ummense.com/',
                    'https://s3-sa-east-1.amazonaws.com/' + process.env.VUE_APP_API_BASE_FILE + '.ummense.com/'
                )

                axios.get(url, {responseType: 'blob', headers: {'Pragma': 'no-cache', 'Expires': '0', 'Cache-Control': 'no-cache' }}).then(response => {
                    this.forceDownload(response)
                }).catch((error) => {
                    this.$toast.open({
                        message: 'Não foi possivel efetuar o download',
                        type: 'error',
                    });
                })
            },

            forceDownload(response) {
                var headers = response.headers
                var blob = new Blob([response.data], {type: headers['content-type']})
                var fileLink = document.createElement('a')

                fileLink.href = window.URL.createObjectURL(blob)
                fileLink.download = `${this.image.name}.${this.image.type}`

                fileLink.click()
                fileLink.remove()
            },

            replaceDescription(description) { 
                return description.replace('{nome_do_contato}', this.user.name)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-image {
        position: fixed;
        width: 100%;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 4;

        @media (max-width: 900px) {
            flex-direction: column;
            z-index: 20;
        }

        .document-box {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            .document-box-header  {
                z-index: 20;
                background-color: $grey9;
                opacity: 1;
                height: 48px;
                position: relative;
                top: 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 16px;

                &:window-inactive {
                    display: none;
                }

                .button {
                    cursor: pointer;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;

                    svg {
                        color: $white-color;
                        font-size: 1.2rem;
                    }

                    &.action:hover svg {
                        color: $blue5;
                    }

                    &.close:hover svg {
                        color: $red5;
                    }
                }

                .header-actions {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
        }

        .image-mask {
            width: 100%;
            overflow-y: auto;
            text-align: center;

            img {
                z-index: 10;
                max-height: max-content;
                max-width: 100%;

                @media (max-width: 900px) {
                    max-width: 100%;
                }
            }

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: $grey3;
            }

            &::-webkit-scrollbar-track {
                background: $white-color;
            }
        }

        .thumb-document {
            max-width: 100%;

            @media (max-width: 900px) {
                min-width: 100%;
                max-width: 100%;
            }
        }

        .comments-box {
            overflow-y: auto;
            height: 100%;
            padding-bottom: 6rem;
            background-color: $white-color;
            min-width: 400px;
            max-width: 400px;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow-x: hidden;
            animation: 0.2s ease-out 0s 1 slideInFromRight;

            .no-comments {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 100%;

                p {
                    color: $grey4;
                    font-size: 16px;
                }
            }

            @media (max-width: 900px) {
                position: absolute;
                bottom: 0;
                z-index: 20;
                max-height: 400px;
                min-width: 100%;
            }

            .title-mobile {
                font-weight: 600;
                margin-top: 23px;
                font-size: 20px;
                color: $grey8;
                text-align: left;
                width: calc(100% - 23px);
                padding-left: 23px;
            }

            .post-description {
                display: flex;
                flex-direction: column;
                width: calc(100% - 46px);
                padding: 23px 23px 0 23px;

                .post-header {
                    display: flex;
                    align-items: center;

                    .post-header-info {
                        margin-left: 12px;
                        display: flex;
                        flex-direction: column;

                        .post-user {
                            color: $grey8;
                            font-weight: 600;
                            font-size: 16px;
                        }

                        .post-date {
                            color: $grey4;
                            font-size: 12px;
                        }
                    }
                }

                .post-content {
                    margin-top: 12px;

                    .post-text {
                        color: $grey8;
                        font-size: 16px;
                        line-height: 22px;
                        word-wrap: break-word;
                        display: block;
                        display: -webkit-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 100%;
                        -webkit-line-clamp: 13;
                        -webkit-box-orient: vertical;
                        white-space: pre-wrap;

                        &.is-activated {
                            overflow: visible;
                            -webkit-line-clamp: unset;
                        }
                    }

                    .see-more {
                        line-height: 22px;
                        cursor: pointer;
                        font-size: 16px;
                        color: $blue5;
                    }
                }
            }

            .comment {
                width: calc(100% - 46px);
                padding: 23px 23px 0 23px;
                display: flex;
                flex-direction: column;
            }

            .comment-info {
                display: flex;
                align-items: center;

                p {
                    margin-left: 0.5rem;
                }
            }

            .comment-name {
                font-weight: 600;
                font-size: 16px;
                color: $grey8;
            }

            .comment-date {
                color: $grey4;
                font-size: 12px;
            }

            .comment-description {
                margin-top: 8px;
                background-color: $grey0;
                border-radius: 0px 16px 16px 16px;
                width: fit-content;
                max-width: calc(100% - 23px);
                padding: 12px;

                p {
                    max-width: 100%;
                    word-wrap: break-word;
                }
            }

            .new-comment {
                background-color: $white-color;
                position: absolute;
                bottom: 0;
                width: calc(380px - 40px);

                @media (max-width: 900px) {
                    min-width: calc(100% - 40px);
                    position: fixed;
                    bottom: 0;
                }
            }

            .modal-editor {
                margin-bottom: 1rem;
            }

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: $grey3;
            }
        }
    }

    @keyframes slideInFromRight {
        0% {
            transform: translateX(200%);
        }

        100% {
            transform: translateX(0);
        }
    }

    [data-popover] {
        width: fit-content;
        font-size: 13px;
        color: $grey8;
        background-color: $white-color;
        padding: 12px;
        transform: translate(-50%, -10px );
        box-shadow: $shadow-3;
        text-wrap: nowrap;
        opacity: 1;
        border-radius: 8px 0px 8px 8px;

        &::before {
            display: none;
        }
    }

    [data-popover='close'] {
        transform: translate(40%, -10px );
        border-radius: 0px 8px 8px 8px;
    }
</style>