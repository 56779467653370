import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const forms = { 
    state: { 
    },
    namespaced: true,
    mutations,
    actions,
    getters,
}

export default forms