var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-menu-header-filter"},[_c('div',{staticClass:"side-menu-header-filter-header"},[_c('div',{staticClass:"side-menu-header-filter-header-back",on:{"click":function($event){return _vm.$emit('close-filter')}}},[_c('i',{staticClass:"icon icon-left-arrow-2 icon12"})]),_c('u-title',{attrs:{"size":"2","color":"grey8","margin":false}},[_vm._v("Filtro")])],1),_c('u-title',{staticClass:"mt-4 mb-5",attrs:{"size":"3","color":"grey6"}},[_vm._v("Status")]),_c('div',{staticClass:"side-menu-header-filter-header-status"},[_c('div',{class:[
                'side-menu-header-filter-header-status-item mr-2', 
                { 'side-menu-header-filter-header-status-item-active': _vm.filterCardStatus.includes('active') }
            ],on:{"click":function($event){return _vm.toggleStatus('active')}}},[_c('u-text',{staticClass:"pb-1",attrs:{"size":"3"}},[_vm._v("Ativo")])],1),_c('div',{class:[
                'side-menu-header-filter-header-status-item ml-2 mr-2', 
                { 'side-menu-header-filter-header-status-item-active': _vm.filterCardStatus.includes('completed') }
            ],on:{"click":function($event){return _vm.toggleStatus('completed')}}},[_c('u-text',{staticClass:"pb-1",attrs:{"size":"3"}},[_vm._v("Concluído")])],1),_c('div',{class:[
                'side-menu-header-filter-header-status-item ml-2', 
                { 'side-menu-header-filter-header-status-item-active': _vm.filterCardStatus.includes('canceled') }
            ],on:{"click":function($event){return _vm.toggleStatus('canceled')}}},[_c('u-text',{staticClass:"pb-1",attrs:{"size":"3"}},[_vm._v("Cancelado")])],1)]),_c('div',{staticClass:"d-flex justify-between mt-5"},[_c('div',{staticClass:"side-menu-header-filter-header-button side-menu-header-filter-header-button-cancel",on:{"click":function($event){return _vm.$emit('close-filter')}}},[_c('u-text',{staticClass:"pb-1",attrs:{"color":"grey5"}},[_vm._v("Cancelar")])],1),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"side-menu-header-filter-header-button side-menu-header-filter-header-button-clear",on:{"click":function($event){return _vm.$emit('clear-filter')}}},[_c('u-text',{attrs:{"color":"grey6"}},[_vm._v("Limpar filtro")])],1),_c('div',{staticClass:"side-menu-header-filter-header-button side-menu-header-filter-header-button-apply ml-3",on:{"click":_vm.applyFilter}},[_c('u-text',{attrs:{"color":"white1"}},[_vm._v("Aplicar")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }