<template>    
    <div class="side-menu-cards">
        <side-menu-header 
            v-if="filterIsActive || search || projects.length > 5"
            placeholder="Encontrar card" 
            :sort-active="sortBy"
            :filter-is-active="filterIsActive"
            :companies="companies"
            @search="searchProject"
            @refresh-list="fetchProjects"
            @sort-list="sortList"
            @clear-filter="clearFilter"
        ></side-menu-header>

        <div class="side-menu-cards-scroll">
            <side-menu-item 
                v-if="!search && !loading"
                semibold
                text="Feed geral" 
                :active="!selectedProject"
                @click="updateSelectedProject(null)"
            ></side-menu-item>

            <div v-if="!initialLoading && loading" class="side-menu-cards-loading">
                <img src="/images/loading-ummense.gif"/>                
            </div>

            <side-menu-filter-empty v-else-if="search && !projects.length" @clear-search="clearSearch"></side-menu-filter-empty>
            
            <div v-else>
                <side-menu-item 
                    v-for="project in projects" 
                    :key="project.id" 
                    :text="project.name" 
                    :ticket="project.ticket"
                    :status="project.status"
                    :active="selectedProject && selectedProject.id == project.id"
                    @click="updateSelectedProject(project)"
                ></side-menu-item>
            </div>

            <div v-if="page > 1" @click="fetchProjects(true)" class="side-menu-cards-pagination">
                <u-text v-if="!loadingPagination" size="3" color="blue5">Ver mais</u-text>
                <img v-else class="side-menu-cards-pagination-loading" src="/images/loading-ummense.gif"/>
            </div>
        </div>
    </div>
</template>

<script>
import SideMenuHeader from './SideMenuHeader'
import SideMenuItem from './SideMenuItem'
import SideMenuFilterEmpty from './SideMenuFilterEmpty'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

export default {
    components: {
        SideMenuHeader,
        SideMenuItem,
        SideMenuFilterEmpty,
    },

    computed: {
        ...mapState('Feed', [
            'selectedProject',
            'filterCardStatus',
            'exceptCompanies',
            'defaultFilterCardStatus',
        ]),

        ...mapGetters('Feed', ['filterIsActive']),

        ...mapGetters('Auth', {
            preferences: 'getPreferences'
        }),
    },

    data() {
        return {
            page: 1,
            search: null,
            initialLoading: true,
            loading: false,
            loadingPagination: false,
            projects: [],
            companies: [],
            sortBy: 'lastInteraction',
        }
    },

    mounted() {
        this.fetchProjects()
        this.$emit('set-loading', true)
    },

    methods: {
        ...mapMutations('Feed', [
            'setSelectedProject',
            'setFilterCardStatus',
            'setExceptCompanies',
        ]),

        ...mapActions('Feed', ['getProjects']),

        // fetchCompanies() {
        //     this.loading = true

        //     this.getCompanies().then(data => {
        //         this.companies.push(...data.result.companies)
        //     }).finally(() => {
        //         this.fetchProjects()
        //     })
        // },

        fetchProjects(isPagination = false) {
            if (isPagination) {
                this.loadingPagination = true
            } else {
                this.page = 1
                this.projects = []
                this.loading = true
            }

            this.getProjects({
                page: this.page,
                search: this.search,
                status: this.filterCardStatus,
                sortBy: this.sortBy,
            }).then(data => {
                this.page = data.pagination.nextPage
                this.projects.push(...data.projects)
            }).finally(() => {
                this.loading = false
                this.loadingPagination = false
                this.initialLoading = false
                this.$emit('set-loading', false)
            })
        },

        updateSelectedProject(project) {
            this.setSelectedProject(project)

            if (this.$route.name != 'feed') {
                this.$router.push({name: 'feed'})
            }
        },

        searchProject(search) {
            this.search = search
            this.fetchProjects()
        },

        sortList(order) {
            this.sortBy = order
            this.fetchProjects()
        },

        clearFilter() {
            this.setFilterCardStatus([...this.defaultFilterCardStatus])
            this.fetchProjects()
        },

        clearSearch() {
            this.search = null
            this.fetchProjects()
        }
    }
}
</script>

<style lang="scss" scoped>

.side-menu-cards {
    position: relative;
}

.side-menu-cards-scroll {
    max-height: 75vh;
    overflow: auto;
    scrollbar-width: thin;

    &:hover::-webkit-scrollbar-thumb{
        background: $grey2;
    }
    
    &::-webkit-scrollbar {
        margin-top: 3px;
        width: 8px;
        height: 1.2rem !important;
    }

    &::-webkit-scrollbar-track {
        background: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 50px;
        background-clip: padding-box;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $grey5;
    }
}

.side-menu-cards-loading {
    text-align: center;
    padding-top: $spacing-4;

    img {
        height: 24px;
        width: 24px;
    }
}

.side-menu-cards-pagination {
    text-align: center;
    padding: $spacing-2;
    cursor: pointer;

    .side-menu-cards-pagination-loading {
        height: 18px;
        width: 18px;
    }
}

</style>