<template>
    <div class="side-menu-header">
        <div class="side-menu-header-input-popover">
            <div :class="['side-menu-header-input', { 'side-menu-header-input-focus': focusInSearchField  }]">
                <i class="icon icon-search-1 icon16 grey4 ml-4"></i>
                <input type="text" v-model="search" :placeholder="placeholder" @input="searchItem" @focus="focusInSearchField = true" @blur="focusInSearchField = false">
            </div>

            <transition name="fade">
                <div v-if="!search && focusInSearchField" class="side-menu-header-input-popover-box">
                    <u-text size="3" color="grey5">{{ popoverText }}</u-text>
                </div>
            </transition>
        </div>
                
        <div v-if="showSortButton || showSortButton" class="side-menu-header-actions">
            <v-popover v-if="showSortButton" @show="sortPopoverIsOpen = true" @hide="sortPopoverIsOpen = false" placement="bottom-start" popover-class="side-menu-header-actions-popover">
                <div :class="['side-menu-header-actions-item', { 'side-menu-header-actions-item-open': sortPopoverIsOpen }]">
                    <i class="icon icon-order-1 icon12"></i>
                </div>

                <div slot="popover" v-close-popover>
                    <div class="side-menu-header-actions-box">
                        <div class="side-menu-header-actions-box-header">
                            <u-text size="3" color="grey4">{{ sortButtonTitle }}</u-text>
                        </div>

                        <div 
                            v-if="isFeedPage"
                            @click="$emit('sort-list', 'lastInteraction')"
                            :class="[
                            'side-menu-header-actions-box-item', 
                            { 'side-menu-header-actions-box-item-active': sortActive == 'lastInteraction' }
                        ]">
                            <u-text semibold class="side-menu-header-actions-box-item-title">Última interação (padrão)</u-text>
                            <u-text size="3" class="side-menu-header-actions-box-item-text">Exibe cards com interação mais recente primeiro</u-text>
                        </div>

                        <div 
                            v-if="isFeedPage"
                            @click="$emit('sort-list', 'created')"
                            :class="[
                            'side-menu-header-actions-box-item', 
                            { 'side-menu-header-actions-box-item-active': sortActive == 'created' }
                        ]">
                            <u-text semibold class="side-menu-header-actions-box-item-title">Data de criação</u-text>
                            <u-text size="3" class="side-menu-header-actions-box-item-text">Exibe cards criados recentemente primeiro</u-text>
                        </div>

                        <div 
                            v-if="isFeedPage"
                            @click="$emit('sort-list', 'name')"
                            :class="[
                            'side-menu-header-actions-box-item', 
                            { 'side-menu-header-actions-box-item-active': sortActive == 'name' }
                        ]">
                            <u-text semibold class="side-menu-header-actions-box-item-title">Título do card</u-text>
                            <u-text size="3" class="side-menu-header-actions-box-item-text">Exibe os cards em ordem alfabética</u-text>
                        </div>

                        <div 
                            v-if="isUdrivePage"
                            @click="$emit('sort-list', 'created')"
                            :class="[
                            'side-menu-header-actions-box-item', 
                            { 'side-menu-header-actions-box-item-active': sortActive == 'created' }
                        ]">
                            <u-text semibold class="side-menu-header-actions-box-item-title">Álbum mais recente</u-text>
                            <u-text size="3" class="side-menu-header-actions-box-item-text">Exibe os álbuns criados recentemente primeiro</u-text>
                        </div>

                        <div 
                            v-if="isUdrivePage"
                            @click="$emit('sort-list', 'name')"
                            :class="[
                                'side-menu-header-actions-box-item', 
                                { 'side-menu-header-actions-box-item-active': sortActive == 'name' }
                        ]">
                            <u-text semibold class="side-menu-header-actions-box-item-title">Título do álbum</u-text>
                            <u-text size="3" class="side-menu-header-actions-box-item-text">Exibe os álbuns em ordem alfabética</u-text>
                        </div>
                    </div>
                </div>
            </v-popover>

            <a 
                href="" 
                v-if="showFilterButton" 
                @click.prevent="showFilter = !showFilter" 
                :class="['side-menu-header-actions-item', { 'side-menu-header-actions-item-filter': filterIsActive }, { 'side-menu-header-actions-item-active': showFilter }]"
            >
                <i class="icon icon-filter icon12"></i>

                <div v-if="filterIsActive" @click.prevent.stop="$emit('clear-filter')" class="side-menu-header-actions-item-clear">
                    <i class="icon icon-close-x icon7 grey6"></i>
                </div>
            </a>
        </div>

        <side-menu-header-filter 
            v-if="isFeedPage && showFilter" 
            :companies="companies"
            @close-filter="showFilter = false" 
            @clear-filter="$emit('clear-filter')" 
            @refresh-list="$emit('refresh-list')"
        ></side-menu-header-filter>
    </div>
</template>

<script>
import SideMenuHeaderFilter from './SideMenuHeaderFilter'
import { mapState } from 'vuex'

export default {
    props: {
        placeholder: {
            type: String,
            default: ''
        },

        showSortButton: {
            type: Boolean,
            default: true
        },

        showFilterButton: {
            type: Boolean,
            default: true
        },
        
        sortActive: {
            type: String,
            default: ''
        },

        filterIsActive: {
            type: Boolean,
            default: false
        },

        companies: {
            type: Array,
            default: () => []
        },       
    },

    components: {
        SideMenuHeaderFilter,
    },

    computed: {
        ...mapState('Feed', ['showTicketOnCard']),

        sortButtonTitle() {
            return this.$route.name == 'feed' ? 'Ordenar cards por...' : 'Ordenar álbuns por...'
        },

        isFeedPage() {
            return this.$route.name == 'feed'
        },

        isUdrivePage() {
            return this.$route.name == 'drive-album'
        },

        popoverText() {
            if (this.isUdrivePage) {
                return 'Busque pelo título do álbum.'
            }

            return this.showTicketOnCard ? 'Busque pelo título ou número identificador do card.' : 'Busque pelo título do card.'
        }
    },

    data() {
        return {
            search: null,
            timeout: null,
            sortPopoverIsOpen: false,
            showFilter: false,
            focusInSearchField: false,
        }
    },

    methods: {
        searchItem() {
            clearTimeout(this.timeout)

            this.timeout = setTimeout(() => {
                this.$emit('search', this.search)
            }, 500)
        },
    },

    watch: {
        '$route'() {
            this.showFilter = false
        }
    }
}
</script>

<style lang="scss" scoped>

.side-menu-header {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-4;
}

.side-menu-header-input-popover {
    width: 100%;
    position: relative;

    .side-menu-header-input-popover-box {
        width: 100%;
        position: absolute;
        top: 36px;
        left: 0;
        right: 0;
        padding-top: $spacing-3;
        padding-bottom: $spacing-3;
        padding-right: $spacing-5;
        padding-left: $spacing-5;
        background-color: $white-color;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    }
}

.side-menu-header-input {
    height: 36px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: $grey1;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: all .2s;

    &:hover {
        background-color: $grey2;
    }

    &.side-menu-header-input-focus {
        border-color: $blue5;
        background-color: $white-color;
    }

    input {
        width: 100%;
        border: none;
        outline: none;
        padding: 0;
        margin-left: $spacing-4;
        background-color: transparent;

        &::placeholder {
            color: $grey4;
        }
    }
}

.side-menu-header-actions {
    display: flex;
    align-items: center;
    margin-left: $spacing-3;   
}

.side-menu-header-actions-item {
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $grey6;
    border-radius: 4px;
    cursor: pointer;
    transition: all .2s;
    position: relative;
    z-index: 1;

    &:hover {
        background-color: $grey1;
    }

    &.side-menu-header-actions-item-open {
        color: $white-color;
        background-color: $grey5;
    }

    &.side-menu-header-actions-item-filter,
    &.side-menu-header-actions-item-active {
        color: $white-color;
        background-color: $blue5;
    }
    
    .side-menu-header-actions-item-clear {
        height: 14px;
        width: 14px;
        position: absolute;
        top: -6px;
        right: -6px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white-color;
        border-radius: 50%;
    }
}

.side-menu-header-actions-box {
    background-color: $white-color;   
    box-shadow: 0px 0px 6px rgba(16, 35, 34, 0.15);
    border-radius: 4px;

    .side-menu-header-actions-box-header {
        height: 36px;
        display: flex;
        align-items: center;
        padding: 0 $spacing-4;
        border-bottom: 1px solid $grey1;
    }

    .side-menu-header-actions-box-item {
        height: 54px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 $spacing-4;
        cursor: pointer;
        transition: all .2s;

        &:hover {
            background-color: $grey1;

            .side-menu-header-actions-box-item-text {
                color: $grey8;
            }
        }

        &.side-menu-header-actions-box-item-active .side-menu-header-actions-box-item-title,
        &.side-menu-header-actions-box-item-active .side-menu-header-actions-box-item-text {
            color: $blue5;
        }

        .side-menu-header-actions-box-item-title {
            color: $grey8;
        }

        .side-menu-header-actions-box-item-text {
            color: $grey7;
        }
    }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>