import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const feed = { 
    state: { 
        selectedProject: null,
        showTicketOnCard: false,
        exceptCompanies: [],
        filterCardStatus: ['active', 'completed', 'canceled'],
        defaultFilterCardStatus: ['active', 'completed', 'canceled'],
    },
    namespaced: true,
    mutations,
    actions,
    getters,
}

export default feed