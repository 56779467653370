export default { 
    getSlug: (state) => { 
        return state.slug
    },

    getAppSettings: (state) => {
        return state.uAppSettings
    },

    getStoreLinks: (state) => {
        return {'googlePlayLink': state.googlePlayLink, 'appStoreLink': state.appStoreLink}
    },

    getToken: (state) => {
        return state.token
    },

    getContact: (state) => {
        return state.contact
    },

    getContactCustomFields: (state) => { 
        return state.contactCustomFields
    },

    getOrgId: (state) => {
        return state.orgId
    },

    getTerms: (state) => { 
        return state.terms
    },

    getUserIsBlocked: (state) => {
        return state.blockedUser
    },
    
    getPreferences: (state) => { 
        return state.preferences
    },

    getStorageUrl: (state) => {
        return state.storageUrl
    },
}