<template>
    <div v-if="dialog" class="modal-change-password">
        <div class="content">
            <div @click="closeModal" class="button-close-modal">
                <u-icon
                    icon="close"
                    colorClass="hover-grey5-red5"
                    class="clear-button"
                    :width="16"
                    :height="16"
                ></u-icon>
            </div>

            <p class="content-title"> 
                Altere sua senha de acesso
            </p>

            <p class="content-description"> 
                Uma nova senha de acesso foi gerada para acesso ao nosso aplicativo versão web. Para manter a segurança da sua conta, recomendamos que acesse <strong>Minha conta</strong> e crie uma nova senha. 
            </p>

            <div class="content-actions">
                <u-button
                    text="Não alterar senha"
                    color="transparent"
                    @click="closeModal"
                ></u-button>

                <u-button
                    text="Acessar minha conta"
                    @click="goToContactData"
                ></u-button>
            </div>
        </div>
    </div>
</template>

<script>
    import UButton from '@/assets/js/components/UI/button/UButton'

    export default { 
        components: {
            UButton,
        },
        
        data() { 
            return { 
                dialog: false,
            }
        },

        methods: { 
            openModal() {
                this.dialog = true
            },

            closeModal() { 
                this.dialog = false
            },

            goToContactData() {
                this.closeModal()
                this.$router.push({name: 'contact-data'})
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-change-password { 
        z-index: 200;
        position: fixed;
        width: 100%;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.8);

        .content {
            background-color: $white-color;
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 30px;
            border-radius: 8px;
            max-width: 520px;
            width: 60%;
            height: fit-content;
            overflow-y: auto;
            box-sizing: border-box;
        }

        .content-title { 
            color: $grey8;
            font-size: 19px;
            font-weight: 600;
            margin-bottom: 4px;
        }

        .content-description { 
            color: $grey8;
            font-size: 15px;
        }

        .button-close-modal { 
            position: absolute;
            right: 20px;
            top: 15px;
            cursor: pointer;
            color: $grey8;

            &:hover { 
                color: $red5;
            }
        }

        .content-actions { 
            margin-top: 24px;
            display: flex;
            justify-content: flex-end;
            
            button { 
                font-size: 15px;
                width: fit-content;

                &:first-of-type {
                    color: $grey5;
                }
            }
        }
    }
</style>