import axios from 'axios'
import Cookies from 'js-cookie'

export default {
    login: ({ state, commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios.post(state.baseAppUri + '/organization/' + state.orgId + '/login',
                {
                    ...payload,
                    isUWeb: true,
                },
                { headers: { 'Authorization': 'Bearer ' + state.token } }
            ).then(({ data }) => {
                if (payload.loginWithPassword) {
                    Cookies.set('user-token', data.contact.uuid, {
                        expires: 1,
                    })

                    commit('setContact', data.contact)
                    commit('setContactCustomFields', data.customFields.customFields)
                    commit('setOrganizationUpdatePassword', data.organizationUpdatePassword)
                }

                resolve()
            }).catch(({ response }) => {
                if (response.data.code && response.data.code == "pending_anonymization_request") {
                    reject('Solicitação de exclusão em andamento')
                }

                if (response.data.code && response.data.code == "password_not_registered") {
                    reject(response.data.code)
                }

                if (response.data.message) {
                    reject(response.data.message)
                }

                reject(response.data)
            })
        })
    },

    register: ({ state, commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios.post(state.baseAppUri + '/organization/' + state.orgId + '/contacts', payload,
                { headers: { 'Authorization': 'Bearer ' + state.token } }
            ).then(({ data }) => {
                resolve()
            }).catch(({ response }) => {
                reject()
            })
        })
    },

    checkCode: ({ state, commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            axios.post(state.baseAppUri + '/organization/' + state.orgId + '/contacts/validate-code',
                { email: payload.email, code: payload.code, isUWeb: true },
                { headers: { 'Authorization': 'Bearer ' + state.token } }
            ).then((data) => {
                if (payload.isRegister) {
                    dispatch('validateTerm', data.data.contact)
                }

                Cookies.set('user-token', data.data.contact.uuid, {
                    expires: 1,
                })

                setTimeout(() => {
                    commit('setContact', data.data.contact)
                    resolve()
                }, 500)
            }).catch(({ response }) => {
                reject()
            })
        })
    },

    logout: ({ state, commit }) => {
        return new Promise((resolve, reject) => {
            axios.post(state.baseAppUri + '/organization/contacts/' + state.contact.id + '/logout',
                { contact: state.contact.id },
                { headers: { 'Authorization': 'Bearer ' + state.token } }
            ).then(({ data }) => {
                Cookies.remove('user-token')
                resolve()
            }).catch(({ response }) => {
                reject()
            })
        })
    },

    resendValidationCode: ({ state, commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios.post(state.baseAppUri + '/organization/' + state.orgId + '/resend-email-code',
                { email: payload },
                { headers: { 'Authorization': 'Bearer ' + state.token } }
            ).then(({ data }) => {
                resolve()
            }).catch(({ response }) => {
                reject()
            })
        })
    },

    getContact: ({ state, commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios.post(state.baseAppUri + '/u-app-web/contacts' + state.slug + '/load-contact',
                { uuid: payload },
                { headers: { 'Authorization': 'Bearer ' + state.token } }
            ).then((data) => {
                commit('setContact', data.data.contact)
                commit('setContactCustomFields', data.data.customFields.customFields)
                resolve()
            }).catch((response) => {
                Cookies.remove('user-token')
                reject()
            })
        })
    },

    updateOrganization: ({ state, commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios.get(state.baseAppUri + '/u-app-web/organization' + state.slug, {
                headers: { 'Authorization': 'Bearer ' + state.token }
            }).then((data) => {
                commit('setOrgId', data.data)
                resolve()
            }).catch((response) => {
                reject(response)
            })
        })
    },

    getTerms: ({ state, commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios.get(state.baseAppUri + '/organization/' + state.orgId + '/u-app-current-term', {
                headers: { 'Authorization': 'Bearer ' + state.token }
            }).then((data) => {
                commit('setTerm', data.data)
                resolve(data.data)
            }).catch((response) => {
                reject(response)
            })
        })
    },

    validateTerm: ({ state, commit }, payload) => {
        return new Promise((resolve, reject) => {
            let currentContact = payload ? payload.id : state.contact.id

            axios.post(state.baseAppUri + '/contacts/' + currentContact + '/accept-terms',
                { version: state.terms.version },
                {
                    headers: { 'Authorization': 'Bearer ' + state.token }
                }).then((data) => {
                    commit('setBlockedUser', false)
                    resolve()
                }).catch((response) => {
                    reject()
                })
        })
    },

    declineTerm: ({ state, commit }) => {
        return new Promise((resolve, reject) => {
            axios.post(state.baseAppUri + '/organization/' + state.orgId + '/contacts/' + state.contact.id + '/decline-term',
                { version: state.terms.version },
                {
                    headers: { 'Authorization': 'Bearer ' + state.token }
                }).then(() => {
                    commit('setBlockedUser', false)
                    Cookies.remove('user-token')
                    resolve()
                }).catch((response) => {
                    reject()
                })
        })
    },

    checkIsNewTerm: ({ state, commit }) => {
        return new Promise((resolve, reject) => {
            axios.get(state.baseAppUri + '/contacts/' + state.contact.id + '/check-new-terms',
                {
                    headers: { 'Authorization': 'Bearer ' + state.token }
                }).then((data) => {
                    let result = data.data.result.term

                    if (result == null) {
                        commit('setBlockedUser', true)
                        resolve(result)
                    }

                    if (!("version" in result)) {
                        let metaTerms = []

                        for (var [key, value] of Object.entries(data.data.result.term)) {
                            metaTerms.push(value)
                        }

                        if (metaTerms.length <= 0 || !metaTerms.find(term => term.version == state.terms.version)) {
                            commit('setBlockedUser', true)
                        }

                        resolve(metaTerms)
                    }

                    if (("version" in result)) {
                        resolve(result)
                    }
                }).catch((response) => {
                    reject()
                })
        })
    },

    getPreferences: ({ state, commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            axios.get(state.baseAppUri + '/u-app-web' + state.slug + '/preferences', {
                headers: { 'Authorization': 'Bearer ' + state.token }
            }).then((data) => {
                var payload = {}

                data.data.result.preferences.forEach((preference) => {
                    let preferenceValue = preference.value

                    if (!preference.key == "backgroundIndex" && preference.value == '1' || preference.value == '0') {
                        preferenceValue = preference.value == '1' ? true : false
                    }

                    if (preference.key == 'publishUserId' && preference.value) {
                        preferenceValue = parseInt(preference.value)
                    }

                    if (Object.prototype.hasOwnProperty.call(payload, preference.type)) {
                        payload[preference.type][preference.key] = preferenceValue
                    } else {
                        Object.assign(payload, { [preference.type]: { [preference.key]: preferenceValue } })
                    }

                    if (preference.type == 'app' && preference.key == 'logo') { 
                        dispatch('getFile', preferenceValue)
                    }
                })

                commit('setPreferences', payload)
                resolve()
            })
        })
    },

    getFile: ({ state, commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios.get(state.baseAppUri + '/organization/files/' + payload,
                { headers: { 'Authorization': 'Bearer ' + state.token } }
            ).then(({data}) => {
                commit('setAppLogoPreference', data.result.file)
            }).catch(() => {
                reject()
            })
        })
    },

    deleteAccount: ({ state }, payload) => {
        return new Promise((resolve, reject) => {
            axios.post(state.baseAppUri + '/organization/' + state.orgId + '/contacts/' + state.contact.id, payload,
                { headers: { 'Authorization': 'Bearer ' + state.token } }
            ).then(() => {
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },

    revokeTerm: ({ state }, payload) => {
        return new Promise((resolve, reject) => {
            axios.post(state.baseAppUri + '/contacts/' + state.contact.id + '/revoke-term', payload,
                { headers: { 'Authorization': 'Bearer ' + state.token } }
            ).then(() => {
                Cookies.remove('user-token')
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },

    updatePassword: ({ state }, payload) => {
        return new Promise((resolve, reject) => {
            axios.patch(state.baseAppUri + '/contacts/' + state.contact.id + '/password', {
                // code: payload.code,
                password: payload.password,
            },
                { headers: { 'Authorization': 'Bearer ' + state.token } }
            ).then(({ data }) => {
                resolve(data.message)
            }).catch(() => {
                reject()
            })
        })
    },

    generatePasswordCode: ({ state }) => {
        return new Promise((resolve, reject) => {
            axios.get(state.baseAppUri + '/contacts/' + state.contact.id + '/generate-password-confirm-code',
                { headers: { 'Authorization': 'Bearer ' + state.token } }
            ).then(() => {
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },

    getUserFields: ({ state,  commit}) => {
        return new Promise((resolve, reject) => {
            axios.get(state.baseAppUri + '/organization/' + state.orgId + '/contacts/' + state.contact.id + '/fields',
                { headers: { 'Authorization': 'Bearer ' + state.token } }
            ).then(({ data }) => {
                commit('setContactMeta', data.contact.meta)
                resolve()
            }).catch(() => {
                reject()
            })
        })
    },

    updateSlug: ({ commit }, payload) => {
        commit('setSlug', payload)
    },

    updateAppSettings: ({ commit }, payload) => {
        commit('setAppSettings', payload)
    },

    updateToken: ({ commit }, payload) => {
        commit('setToken', payload)
    },

    updateContact: ({ commit }, payload) => {
        commit('setContact', payload)
    },
}