import axios from 'axios'
import store from '@/store/index'

export default { 
    getDriveAlbums: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            let apiUrl = store.state.Auth.baseAppUri + '/contacts/' + store.state.Auth.contact.id + '/u-drive-albums?page=' + payload.page

            axios.get(apiUrl, {
                params: { listOrder: payload.filter, search: payload.search }, 
                headers: {'Authorization': 'Bearer ' + store.state.Auth.token},
            }).then(({ data }) => {
                let albums = data.result.albums.items
                let filteredAlbums = []
                
                albums.map((album) => { 
                    if (album) { 
                        filteredAlbums.push(album)
                    }
                })
                
                data.result.albums.items = filteredAlbums
                resolve(data.result)
            })
        })
    },

    getAlbumFiles: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            let apiUrl = store.state.Auth.baseAppUri + '/contacts/' + store.state.Auth.contact.id + '/albums/' + payload.project + '/u-drive-files'

            if (payload && payload.pagination != null) { 
                apiUrl = apiUrl + '?page=' + payload.pagination
            }

            axios.get(apiUrl,
                {headers: {'Authorization': 'Bearer ' + store.state.Auth.token},
            }).then((data) => {
                resolve(data.data.result)
            })
        })
    },

    getContactAlbumFiles: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            let apiUrl = store.state.Auth.baseAppUri + '/contacts/' + store.state.Auth.contact.id + '/albums/u-drive-files'
            
            if (payload && payload.pagination != null) { 
                apiUrl = apiUrl + '?page=' + payload.pagination
            }

            axios.get(apiUrl,
                {headers: {'Authorization': 'Bearer ' + store.state.Auth.token},
            }).then((data) => {
                resolve(data.data.result)
            })
        })
    },
}