<template>
    <div class="login">
        <div v-if="appSettings" class="login-container">
            <!-- CAPA -->
            <div class="login-cover" :style="coverBackground">
                <div class="mask-logo">
                    <u-avatar :size="2" :organization="appSettings"></u-avatar>
                </div>

                <p
                    v-if="
                        hasPreference('loginScreen', 'title') &&
                        preferences.loginScreen.show
                    "
                    class="login-welcome"
                >
                    {{ preferences.loginScreen.title }}
                </p>

                <p
                    v-if="
                        hasPreference('loginScreen', 'description') &&
                        preferences.loginScreen.show
                    "
                    class="login-description"
                >
                    {{ preferences.loginScreen.description }}
                </p>
            </div>

            <!-- LOGIN -->
            <div class="login-form">
                <div class="app-login">
                    <div
                        v-if="showCode || showPassword"
                        @click="backToLogin"
                        class="app-login-back"
                    >
                        <i class="fas fa-arrow-left"></i>
                        <p>Voltar</p>
                    </div>

                    <p class="app-login-header-title">
                        Entrar na área do cliente
                    </p>

                    <p class="app-login-header-description" v-if="showPassword"> 
                        Se ainda não possui senha cadastrada é
                        necessário efetuar o login ao ambiente
                        utilizando o código de acesso e
                        cadastrar uma senha em "Minha conta".
                    </p>

                    <div
                        key="login-body"
                        v-if="!showCode"
                        class="app-login-fields"
                        v-shortkey="['enter']"
                        @shortkey="showPasswordField"
                    >
                        <u-input
                            class="email-field"
                            placeholder="Digite o e-mail"
                            :model="true"
                            :autocomplete="true"
                            name="email"
                            @getModel="getEmail"
                        ></u-input>

                        <div v-if="showPassword" 
                            v-shortkey="['enter']"
                            @shortkey="loginApp" 
                            style="display: flex; align-items: center; margin-bottom: 12px;"
                        >
                            <u-input
                                class="email-field"
                                placeholder="Digite a senha"
                                :model="true"
                                :autocomplete="true"
                                name="password"
                                type="password"
                                @getModel="getPassword"
                                ref="refPasswordField"
                                style="width: 100%; margin-bottom: 0;"
                            ></u-input>
                        </div>

                        <p
                            class="error-text"
                            v-if="errorMsg"
                            v-html="errorMsg"
                        ></p>

                        <p v-if="showPassword" class="forgot-password">
                            Esqueceu a senha?
                            <strong @click="toCode">
                                Entre com o código de acesso
                            </strong>
                        </p>

                        <div v-if="passwordError" class="error-text-password">
                            <p>
                                <strong
                                    >Você não possui uma senha
                                    cadastrada.</strong
                                >
                            </p>
                            <p>
                                Acesse o ambiente utilizando o código de acesso
                                e cadastre uma senha em "Minha conta".
                            </p>
                        </div>

                        <div v-if="showPassword">
                            <div @click="loginApp">
                                <u-button
                                    text="Continuar"
                                    :loading="loginPasswordLoading"
                                ></u-button>
                            </div>
                        </div>

                        <div class="actions" v-else>
                            <div @click="showPasswordField">
                                <u-button
                                    text="Entrar com a minha senha"
                                ></u-button>
                            </div>

                            <u-text style="margin-top: 16px;">
                                ou
                            </u-text>

                            <div @click="toCode" style="margin-top: 16px">
                                <u-button
                                    text="Entrar com código de acesso"
                                    color="#D4EDFA"
                                    textColor="#038DD2"
                                ></u-button>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="showCode"
                        class="app-login-fields"
                        v-shortkey="['enter']"
                        @shortkey="validateCode"
                    >
                        <p class="login-tip">
                            Digite o código que enviamos para o seu e-mail.
                        </p>

                        <u-code
                            class="app-code"
                            @getNumber="getNumber"
                        ></u-code>

                        <div class="button-resend-code" @click="resendCode">
                            <p>Reenviar o código</p>
                        </div>

                        <p
                            class="error-text"
                            v-if="errorMsg"
                            v-html="errorMsg"
                        ></p>

                        <div @click="validateCode">
                            <u-button text="Continuar"></u-button>
                        </div>
                    </div>

                    <div
                        v-if="
                            !hasPreference('app', 'allowNewUsers')
                                ? true
                                : preferences.app.allowNewUsers
                        "
                        class="app-login-footer"
                    >
                        <div class="title-new-user">
                            <div></div>

                            <p>
                                Novo usuário?
                            </p>

                            <div></div>
                        </div>
                        
                        <router-link :to="{ name: 'register' }">
                            <u-button
                                type="slim"
                                text="Criar uma conta"
                                color="#D4EDFA"
                                textColor="#038DD2"
                            ></u-button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="storeLinks.appStoreLink && storeLinks.googlePlayLink" class="login-footer">
            <u-text size="2" class="grey7"> Também disponível: </u-text>
            
            <div class="footer-images">
                <a :href="storeLinks.appStoreLink" target="_blank">
                    <img class="left" src="/images/app-store.png"/>
                </a>

                <a :href="storeLinks.googlePlayLink" target="_blank">
                    <img class="right" src="/images/google-play.png"/>
                </a>
            </div>
        </div>

        <loading
            color="#208fcf"
            :active.sync="isLoading"
            :width="100"
            :height="100"
        ></loading>
    </div>
</template>

<script>
import { hasPreference } from '@/assets/js/helpers/index.js'
import { mapGetters, mapActions } from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import UAvatar from '@/assets/js/components/UI/avatar/UAvatar'
import UInput from '@/assets/js/components/UI/input/UInput'
import UCode from '@/assets/js/components/UI/input/UCode'
import UButton from '@/assets/js/components/UI/button/UButton'

export default {
    components: {
        UAvatar,
        UInput,
        UCode,
        UButton,
        Loading,
    },

    data() {
        return {
            userEmail: null,
            userPassword: null,
            showCode: false,
            validateNumber: null,
            isLoading: false,
            errorMsg: null,
            passwordError: false,
            showPassword: false,
            loginPasswordLoading: false,
        }
    },

    computed: {
        ...mapGetters('Auth', {
            appSettings: 'getAppSettings',
            slug: 'getSlug',
            preferences: 'getPreferences',
            storageUrl: 'getStorageUrl',
            orgId: 'getOrgId',
            storeLinks: 'getStoreLinks'
        }),

        formatSlug() { 
            let formatedSlug = this.slug.replace("/", "")
            return formatedSlug
        },

        coverBackground() {
            let preferences = this.preferences.appearance
            let defaultBackground =
                'background: url(../images/cover-image-small.jpg)'

            if (!preferences) {
                return defaultBackground
            }

            if (preferences.hasCustomAppearance) {
                return 'background: url("https://ummense-objects.s3.amazonaws.com/uapp/custom/org_' + this.orgId + '_login_bg.jpg")'
            }

            if (preferences.solidColor) {
                return 'background-color: ' + preferences.backgroundHex
            }

            if (!preferences.backgroundHex || !preferences.backgroundIndex) {
                return defaultBackground
            }

            return (
                'background: url(' +
                this.storageUrl +
                preferences.backgroundIndex +
                '/portrait_' +
                preferences.backgroundColor +
                '.jpg)'
            )
        },
    },

    methods: {
        hasPreference,

        ...mapActions('Auth', ['login', 'checkCode', 'resendValidationCode']),

        toCode() {
            this.showPassword = false

            if (this.isLoading) return

            if (!this.userEmail) return (this.errorMsg = 'Insira o email')

            this.errorMsg = null
            this.isLoading = true
            this.loginApp()
        },

        showPasswordField() {
            if (this.isLoading) return

            if (!this.userEmail) return (this.errorMsg = 'Insira o email')

            this.showPassword = true

            this.$nextTick(() =>
                this.$refs.refPasswordField.$refs.refInput.focus()
            )
        },

        loginApp() {
            if (this.showPassword) {
                this.loginPasswordLoading = true
            }

            this.login({
                email: this.userEmail,
                password: this.userPassword,
                loginWithPassword: this.showPassword,
            })
                .then(() => {
                    if (this.showPassword) {
                        this.$router.push({ name: this.getHomeScreen() })
                        return
                    }

                    this.showCode = true
                    this.isLoading = false
                })
                .catch((response) => {
                    this.isLoading = false

                    if (response == 'password_not_registered') {
                        this.passwordError = true
                        return
                    }

                    this.errorMsg = response ? response : 'E-mail inválido'
                })
                .finally(() => {
                    this.loginPasswordLoading = false
                })
        },

        validateCode() {
            if (this.isLoading) return

            this.isLoading = true

            let payload = {
                email: this.userEmail,
                code: this.validateNumber,
            }

            this.checkCode(payload)
                .then(() => {
                    this.isLoading = false
                    this.$router.push({ name: this.getHomeScreen() })
                })
                .catch(() => {
                    this.isLoading = false
                    this.errorMsg = 'Código inválido'
                })
        },

        getHomeScreen() {
            return hasPreference('app', 'homeScreen') &&
                this.preferences.app.homeScreen == 'form'
                ? 'make-requests'
                : 'feed'
        },

        resendCode() {
            this.isLoading = true

            this.resendValidationCode(this.userEmail).then(() => {
                this.$toast.open({
                    message: 'Código enviado com sucesso',
                })

                this.isLoading = false
            })
        },

        backToLogin() {
            this.errorMsg = null
            this.userEmail = null
            this.passwordError = false
            this.showCode = false
            this.showPassword = false
        },

        getEmail(model) {
            this.userEmail = model
            this.errorMsg = null
            this.passwordError = false
        },

        getPassword(model) {
            this.userPassword = model
            this.errorMsg = null
            this.passwordError = false
        },

        getNumber(number) {
            this.validateNumber = number
            this.errorMsg = null
            this.passwordError = false
        },
    },
}
</script>

<style lang="scss" scoped>
.login {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 1400px ){
        margin-top: 8%;
    }
    @media (min-width: 2600px ){
        margin-top: 30%;
    }

    @media (max-width: 900px) {
        justify-content: flex-start;
    }

    .login-container {
        display: flex;
        align-items: center;
        border-radius: 8px;
        overflow: hidden;

        @media (max-width: 900px) {
            flex-direction: column;
            max-width: 100%;
            max-height: 80%;
            padding: $spacing-4;
        }

        .login-cover {
            width: 360px;
            height: 550px;
            padding: 30px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            border-radius: 8px 0px 0px 8px;
            margin-right: 3px;
            background-size: cover !important;
            background-position-x: center !important;
            background-repeat: no-repeat !important;

            @media (max-width: 900px) {
                padding: 0;
                width: 100%;
                border-radius: 8px 8px 0 0;
                min-height: 265px;
            }

            .mask-logo {
                border: 2px solid #fff;
                border-radius: 50%;
            }

            .login-welcome {
                margin-top: 12px;
                font-size: 22px;
            }

            .login-welcome,
            .login-description {
                max-width: 100%;
                word-break: break-word;
            }

            p {
                text-align: center;
                padding: 10px;
                color: $white-color;
            }
        }

        .login-form {
            width: 420px;
            height: 575px;
            display: flex;
            align-items: center;
            background-color: #fff;
            box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1);

            @media (max-width: 900px) {
                background-color: transparent;
                box-shadow: none;
                max-width: 100%;
            }

            .app-login {
                width: 100%;
                padding: 50px;

                @media (max-width: 900px) {
                    padding: 20px 0 0 0 !important;
                }

                .app-login-back {
                    display: flex;
                    align-items: center;
                    margin-bottom: 16px;
                    cursor: pointer;

                    svg {
                        font-size: 14px;
                        color: $grey6;
                        margin-right: 8px;
                    }

                    p {
                        font-size: 14px;
                        color: $grey6;
                    }

                    &:hover svg,
                    &:hover p {
                        color: $blue5;
                        transition: all 0.2s;
                    }
                }

                .app-login-header-title {
                    font-size: 18px;
                    font-weight: 600;
                    padding-bottom: 8px;
                }
                
                .app-login-header-description { 
                    font-size: 13px;
                    color: $grey7;
                    line-height: 22px;
                    padding-bottom: 8px;
                }

                .app-login-fields {
                    display: flex;
                    flex-direction: column;
                    margin: 14px 0;

                    .login-tip {
                        margin-top: 14px;
                        margin-bottom: 18px;
                        font-size: 14px;
                    }

                    .email-field {
                        margin-bottom: 12px;
                    }

                    .login-recaptcha {
                        margin: 30px 0;
                        width: 100%;
                    }

                    .app-code {
                        margin: 12px 0;
                    }

                    .button-resend-code {
                        cursor: pointer;
                        margin-bottom: 12px;
                        display: flex;
                        align-items: center;

                        p {
                            color: $blue5;

                            &:hover {
                                color: $blue3;
                            }
                        }
                    }

                    .error-text {
                        background-color: $pink1;
                        border: 1px solid $pink5;
                        color: $pink5;
                        border-radius: 5px;
                        padding: 5px 10px;
                        margin-bottom: 12px;
                        font-size: 14px;
                    }

                    .forgot-password{
                        margin-bottom: 12px;

                        strong{ 
                            cursor: pointer;
                            color: $blue5;
                            font-weight: 400;
                        }
                    } 

                    .actions { 
                        width: 100%;
                        display: flex;
                        align-items: center;
                        flex-direction: column;

                        >div { 
                            width: 100%;
                            display: flex;
                            justify-content: center;
                        }
                    }

                    .error-text-password {
                        font-size: 13px;
                        color: $grey8;
                        padding: 8px 12px;
                        margin-top: 4px;
                        margin-bottom: 16px;
                        border: 1px solid $yellow5;
                        border-top: 1px solid $yellow2;
                        border-right: 1px solid $yellow2;
                        border-bottom: 1px solid $yellow2;
                        border-left: 1px solid $yellow5;
                        border-radius: 4px;
                    }
                }

                .app-login-footer {
                    margin-top: 24px;
                    
                    p {
                        font-size: 14px;
                        color: $grey5;
                    }

                    .title-new-user { 
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 16px;
                        
                        >div { 
                            height: 2px;
                            width: 30%;
                            background-color: $grey2;
                            border-radius: 4px;
                        }
                    }

                    a {
                        cursor: pointer;
                        color: $blue5;
                        text-decoration: none;

                        &:hover {
                            color: $grey8;
                        }
                    }
                }
            }
        }
    }

    .login-footer {
        width: 100%;
        height: 100%;
        padding: 30px 0px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .footer-images { 
            display: flex;
            align-items: center;

            img { 
                cursor: pointer;
                width: 98px;
                margin: 0 3px;

                &.left { 
                    padding-top: 6px;
                }
            }
        }
    }
}
</style>