<template>
    <div v-if="loading">
        <u-ghost-loader height="22px" width="98px" :has-opacity="false" class="mb-4"></u-ghost-loader>
        <u-ghost-loader height="555px" radius="16px"></u-ghost-loader>
    </div>

    <div v-else class="drive-album">
        <div class="drive-album-header mb-4">
            <u-title size="2" color="grey8" :margin="false">{{ title }}</u-title>

            <div class="action-view-mode">
                <div @click="setViewMode('blocks')" :class="['button-view-mode', { active: modeView == 'blocks' }]">
                    <i :class="['icon icon16', { 'icon-block-ordenation': modeView != 'blocks' }, { 'icon-block-ordenation-fill': modeView == 'blocks' }]"></i>
                </div>

                <div @click="setViewMode('list')" :class="['button-view-mode ml-2', { active: modeView == 'list' }]">
                    <i :class="['icon icon16', { 'icon-view-list-2': modeView != 'list' }, { 'icon-view-list-fill': modeView == 'list' }]"></i>
                </div>
            </div>
        </div>
        
        <div class="drive-album-content">
            <div v-if="loading" class="drive-album-loading">
                <img src="/images/loading-ummense.gif" />
            </div>

            <div v-if="!loading">
                <div v-for="(groupedFiles, index) in groupFilesByDate" :key="index">
                    <span class="date-title">
                        {{ formatMonthLabel(groupedFiles.dateLabel) }}
                    </span>
                    <div
                        :class="[
                            'files',
                            { blocks: modeView == 'blocks' },
                            { list: modeView == 'list' },
                            ]"
                        >
                            <div
                                class="file"
                                v-for="(file, index) in groupedFiles.items"
                                :key="index"
                                @click="openImage(file)"
                            >
                            <div class="file-cover">
                                <u-thumbnail
                                class="thumb-document"
                                :file="file"
                                :isDrive="true"
                                ></u-thumbnail>
                            </div>

                            <div class="file-info">
                                <p class="file-name">{{ file.name }}.{{ file.type }}</p>

                                <p v-if="modeView == 'list'" class="file-date">
                                    Enviado em: {{ formatDate(file.sentAt) }}
                                    <i class="fas fa-caret-right"></i> {{ formatSize(file.size) }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="pagination != null && !loading" class="drive-album-footer">
                <div @click="getFilesPagination" class="button-next-page">
                Carregar mais arquivos
                </div>
            </div>
        </div>

        <modal-image
            v-if="selectedFile && dialogFile"
            :dialog="dialogFile"
            :image="selectedFile"
            :hasComment="false"
            @closeModal="dialogFile = false"
        ></modal-image>
    </div>
</template>

<script>
    import UThumbnail from '@/assets/js/components/feed/UThumbnail'
    import ModalImage from '@/assets/js/modules/feed/ModalImage'
    import { mapState, mapActions } from 'vuex'
    import moment from 'moment'
    import 'moment/locale/pt-br';

    export default { 
        components: { 
            ModalImage,
            UThumbnail
        },

        data() {
            return {
                albumFiles: null,
                loading: false,
                dialogFile: false,
                selectedFile: false,
                pagination: null,
                modeView: 'list',
                
            }
        },

        created() { 
            this.getFiles()
        },

        computed: {
            ...mapState('Drive', ['selectedAlbum']),

            title() {
                return this.selectedAlbum ? this.selectedAlbum.name : 'Todos os arquivos'
            },

            groupFilesByDate() {
                if(!this.albumFiles.items) {
                    return 
                }

                const albumFiles = this.albumFiles.items
                const groupedFiles = []

                albumFiles.forEach((file,_,fileArray) => {
                    const fileSentDateMonth = moment(file.sentAt).locale('en').format('MMMM')
                    const fileSentDateYear = moment(file.sentAt).locale('en').format('YYYY')

                    const dateAlreadyGrouped = groupedFiles.find( element => element.dateLabel == `${fileSentDateMonth}/${fileSentDateYear}` )

                    if(dateAlreadyGrouped) {
                        return
                    }

                    const updatedFileArray = fileArray.filter(filteredArray => { 

                        const filteredFileSentMonth = moment(filteredArray.sentAt).locale('en').format('MMMM')
                        const filteredFileSentYear = new Date(filteredArray.sentAt).getFullYear()

                        return filteredFileSentMonth == fileSentDateMonth && filteredFileSentYear == fileSentDateYear
                    })

                    groupedFiles.push({
                        dateLabel: `${fileSentDateMonth}/${fileSentDateYear}`,
                        items: updatedFileArray
                    })

                })

                return groupedFiles.sort((a,b) => {
                    const splitedDateA = a.dateLabel.split('/')
                    const splitedDateB = b.dateLabel.split('/')

                    const [monthA, yearA]  = splitedDateA

                    const [monthB, yearB]  = splitedDateB
                    
                    
                    return monthA > monthB && yearA > yearB
                })                
            },            
        },

        methods: { 
            ...mapActions("Drive", [
                "getAlbumFiles",
                "getContactAlbumFiles"
            ]),

            formatMonthLabel(date) {
                const dateSplited = date.split('/')
                const monthList = 
                {
                  January: 'Janeiro',
                  February:'Fevereiro',
                  March: 'Março',
                  April: 'Abril',
                  May: 'Maio',
                  June:'Junho',
                  July: 'Julho',
                  August: 'Agosto',
                  September: 'Setembro',
                  October: 'Outubro',
                  November: 'Novembro',
                  December: 'Dezembro'
                }
                const month = dateSplited[0]
                const year = dateSplited[1]
                return `${monthList[month]} de ${year}`
            },

            getFiles() {
                this.loading = true

                let payload = { 
                    pagination: this.pagination,
                    project: this.selectedAlbum ? this.selectedAlbum.id : null
                }

                if (!this.selectedAlbum) { 
                    this.getContactAlbumFiles().then((data) => {
                        this.pagination = data.files.pagination.nextPage
                        this.albumFiles = data.files
                        this.loading = false
                    })
                    return
                }

                this.getAlbumFiles(payload).then((data) => {
                    this.pagination = data.files.pagination.nextPage
                    this.albumFiles = data.files
                    this.loading = false
                })
            },

            getFilesPagination() { 
                if (this.loading) return

                this.loading = true

                let payload = { 
                    pagination: this.pagination,
                    project: this.selectedAlbum ? this.selectedAlbum.id : null
                }

                if (!this.selectedAlbum) { 
                    this.getContactAlbumFiles(payload).then((data) => {
                        this.pagination = data.files.pagination.nextPage
                        this.albumFiles.items.push(...data.files.items)
                        this.loading = false
                    })

                    return
                }

                this.getAlbumFiles(payload).then((data) => {
                    this.pagination = data.files.pagination.nextPage
                    this.albumFiles.items.push(...data.files.items)
                    this.loading = false
                })
            },

            openImage(file) { 
                this.dialogFile = true
                this.selectedFile = file
            },

            setViewMode(mode) { 
                this.modeView = mode
            },

            formatDate(date) { 
                return moment(date).format('lll')
            },

            formatSize(bytes, decimals = 2) {
                if (bytes === 0) return '0 Bytes';

                const k = 1024;
                const dm = decimals < 0 ? 0 : decimals;
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

                const i = Math.floor(Math.log(bytes) / Math.log(k));

                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
            }           
        },

        watch: {
            selectedAlbum() {
                this.pagination = 1
                this.getFiles()
            }
        }
    }
</script>

<style lang="scss" scoped>

.drive-album-content {
    width: 100%;
    height: fit-content;
    background-color: $white-color;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 85%;
    padding: 24px 0 24px 0;
    border-radius: 16px;
    min-height: calc(530px - 48px);

    &::-webkit-scrollbar {
        width: 0px;
    }

    &::-webkit-scrollbar-thumb {
        background: #dad7d7;
    }
}

.drive-album-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .action-view-mode {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.button-view-mode {
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: $grey6;
    cursor: pointer;
    transition: all .2s;

    &:hover {
        color: $blue5;
        background-color: $blue1;
    }

    &:active {
        color: $white-color;
        background-color: $blue8;
    }

    &.active i {
        color: $blue5;
    }
}

.drive-album-footer {
    position: relative;
    bottom: -20px;
    padding-bottom: 24px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .button-next-page {
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;

    &:hover {
        color: $blue5;
    }
    }
}

.date-title {
    text-transform: uppercase;
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: $blue5;
    margin-left: 24px;
    margin-bottom: $spacing-4;
}

.drive-album-loading {
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: calc(100% - 48px);

    img {
    margin-top: 28%;
    width: 30px;
    }
}

.files {
    padding: 0 24px;
    margin-bottom: 24px;

    &.blocks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    row-gap: 12px;
    }

    &.list {
        display: flex;
        flex-direction: column;
        

        .file {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: unset;
            padding: 0;
            margin-bottom: 8px;
            border-radius: 8px;

            .file-info {
                margin-left: 12px;
                display: flex;
                flex-direction: column;
            }

            .file-date {
                color: $grey6;
                font-size: 12px;
                margin-top: 4px;
            }

            .file-cover {
                height: 64px;
                width: 64px;
                min-width: 64px;
            }
        }
    }
}

.file {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding: 0 8px 8px 8px;
    border-radius: 16px;

    &:hover {
    box-shadow: 1px 2px 2px rgba(16, 35, 51, 0.06);
    }

    .file-cover {
    height: 88px;
    width: 88px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    .thumb-document {
        height: 100%;

        img {
        height: 100%;
        }
    }
    }

    .file-info {
    max-width: 100%;
    }

    .file-name {
        margin-top: 8px;
        font-size: 16px;
        color: $grey8;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>