<template> 
    <div :class="['u-avatar', avatarSize, { 'u-avatar-border': hasBorder }]">
        <div v-if="link" class="u-avatar-link">
            <img :src="link"/>
        </div>

        <div v-if="user" class="u-avatar-initials">
            <img v-if="user.avatar" :src="user.avatar" />
            <p v-if="!user.avatar && initials">{{ initials }}</p>
        </div>

        <div v-if="organization" class="u-avatar-logo">
            <img v-if="organization.app_icon || preferenceLogo" :src="preferenceLogo || organization.app_icon" />
            <p v-if="!organization.app_icon && !preferenceLogo && initials">{{ initials }}</p>
        </div>
    </div>
</template>

<script>
    import { hasPreference } from "@/assets/js/helpers/index.js";
    import { mapGetters } from "vuex"

    export default { 
        props: {
            user: { 
                type: Object,
                required: false
            },

            organization: {
                type: Object,
                required: false
            },

            size: { 
                type: Number,
                default: 4
            },

            link: { 
                type: String,
                required: false
            },

            hasBorder: { 
                type: Boolean,
                default: false
            },
        },

        computed: { 
            ...mapGetters("Auth", {
                preferences: "getPreferences"
            }),

            avatarSize() { 
                return 'avatar-' + this.size
            },

            initials() {
                let initials = null

                if (this.user) {
                    initials = this.user.name ? this.user.name.charAt(0) : ''
                    initials += this.user.lastname ? this.user.lastname.charAt(0) : ''
                }

                if (this.organization) {
                    let name = hasPreference('organization', 'name') 
                        ? this.preferences.organization.name 
                        : this.organization.company_name
                    
                    initials = this.orgInitials = name.charAt(0) + name.charAt(1)
                }

                return initials
            },

            preferenceLogo() { 
                if (!this.preferences.app && this.preferences.app.logo) return

                return this.preferences.app.logo
            }
        },

        methods: {
            hasPreference,
        },
    }
</script>

<style lang="scss" scoped>
    .u-avatar { 
        border-radius: 50%;
        overflow: hidden;
        -webkit-touch-callout: none;  
        -webkit-user-select: none;  
        -khtml-user-select: none;    
        -moz-user-select: none;     
        -ms-user-select: none;        
        user-select: none;  
        box-sizing: border-box;

        &.u-avatar-border {
            border: 2px solid $white-color;
        }

        &.avatar-1 { 
            width: 120px;
            height: 120px;

            p { 
                font-size: 36px;
            }
        }

        &.avatar-2 {
            width: 64px;
            height: 64px;

            p { 
                font-size: 24px;
            }
        }

        &.avatar-3 { 
            width: 48px;
            height: 48px;

            p { 
                font-size: 20px;
            }
        }

        &.avatar-4 { 
            width: 36px;
            height: 36px;
            
            p { 
                font-size: 16px;
            }
        }

        &.avatar-5 { 
            width: 28px;
            height: 28px;
            
            p { 
                font-size: 12px;
            }
        }

        img { 
            width: 100%;
        }

        .u-avatar-initials { 
            background-color: $grey2;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            p { 
                font-weight: 600;
            }
        }

        .u-avatar-logo {
            background-color: $grey9;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            p { 
                text-transform: uppercase;
                color: $white-color;
                font-weight: 600;
            }
        }

        .u-avatar-link,
        .u-avatar-initials,
        .u-avatar-logo { 
            max-width: 100%;
            max-height: 100%;
        }
    }
</style>