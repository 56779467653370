<template>
    <div class="u-emoji-picker" v-on-clickaway="closeEmojiModal">
        <template>
            <span @click.prevent="showEmojiPicker = !showEmojiPicker" class="u-emoji-picker-button">
                <i class="far fa-grin"></i>
            </span>
        </template>

        <div v-show="showEmojiPicker" class="u-emoji-picker-modal">
            <emoji-picker ref="refEmojiPicker"></emoji-picker>
        </div>
    </div>
</template>

<script>
import 'emoji-picker-element';
import { mixin as clickaway } from 'vue-clickaway'

export default {
    mixins: [clickaway],

    mounted() {
        this.setEmojiPicker()
    },

    data() {
        return {
            showEmojiPicker: false
        }
    },

    methods: {
        setEmojiPicker() {
            document.querySelector('emoji-picker').addEventListener('emoji-click', event => {                
                if (event.detail.emoji.unicode == '☺️') {
                    event.detail.emoji.unicode = '😊'
                }
                
                this.$emit('select-emoji', event.detail.emoji.unicode)
                this.closeEmojiModal()
            });

            let picker = this.$refs.refEmojiPicker.shadowRoot

            if (!picker) {
                return
            }

            let pickerHeader = picker.querySelector('.search-row')
            let flagsCategory = picker.querySelector('button[aria-controls="tab-9"]')
            let indicator = picker.querySelector('.indicator-wrapper')

            if (pickerHeader) {
                pickerHeader.remove()
            }

            if (flagsCategory) {
                flagsCategory.remove()
            }

            if (indicator) {
                indicator.remove()
            }

            const style = document.createElement('style');
            style.textContent = '.picker{border-radius: 8px;} .tabpanel::-webkit-scrollbar{margin-top: 3px; width: 6px;} .tabpanel::-webkit-scrollbar-track {background: #e4e6e7;} .tabpanel::-webkit-scrollbar-thumb {background: #aaadaf;border-radius: 50px;background-clip: padding-box;} .tabpanel::-webkit-scrollbar-thumb:hover {background: #a5aab0;} .nav-button {border-bottom: 1px solid #F3F4F5;} .nav-button[aria-selected=true] {border-color: #038DD2;} .favorites {flex-wrap: wrap;}'
            picker.appendChild(style);

            const showMoreElement = picker.querySelector('.favorites');
            const showMoreTag = document.createElement('p');
            showMoreTag.style.cssText = "width: 100%;margin: 0;padding: 3px 5px;font-size: 13px;font-family: Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;"
            showMoreTag.innerHTML = 'Mais usados'
            showMoreElement.appendChild(showMoreTag)             
            
            const navButtons = picker.querySelectorAll('.nav .nav-button')

            for (let index = 0; index < navButtons.length; index++) {
                const element = navButtons[index];
                element.querySelector('.emoji').innerHTML = "<img src='/images/emoji-picker/tab-" + index + ".svg' style='height: 16px;'>"
            }            
        },

        closeEmojiModal() {
            if (!this.showEmojiPicker) {
                return
            }

            this.showEmojiPicker = false
        },
    }
}
</script>

<style lang="scss" scoped>
    emoji-picker {
        --num-columns: 6;
        --emoji-size: 20px;
        --border-color: #F3F4F5;
        --indicator-height: 2px;
        width: 100%;
        height: 100%;
    }

    .u-emoji-picker {
        display: inline;
        position: relative;

        .u-emoji-picker-modal {
            position: absolute;
            height: 350px;
            top: 20px;
            right: -10px;
        }

        .u-emoji-picker-button svg {
            color: $grey8;
            z-index: 0;
            font-size: 16px;

            &:hover {
                color: $blue5;
            }
        }
    }
</style>