<template>
    <div v-if="loading">
        <u-ghost-loader height="22px" width="98px" :has-opacity="false" class="mb-4"></u-ghost-loader>
        <u-ghost-loader height="555px" radius="16px"></u-ghost-loader>
    </div>

    <div v-else class="my-requests">
        <div class="requests-header mb-3">
            <u-title size="2" color="grey8" :margin="false">Minhas solicitações</u-title>
            
            <div v-if="!currentRequest" class="d-flex">
                <p 
                    v-if="!openFilter && $refs.refMyRequestsFilter && $refs.refMyRequestsFilter.filterCounter" 
                    @click="$refs.refMyRequestsFilter.clearFilter()"
                    class="requests-header-filter-clear"
                >
                    Limpar filtro
                </p>
                <div class="requests-header-filter" @click="openFilter = !openFilter">
                    <u-icon
                        icon="filter"
                        :colorClass="$refs.refMyRequestsFilter && $refs.refMyRequestsFilter.filterCounter ? 'blue5' : 'grey6'"
                        :width="16"
                        :height="16"
                        class="requests-header-filter-icon"
                    ></u-icon>
                </div>
                <p v-if="$refs.refMyRequestsFilter && $refs.refMyRequestsFilter.filterCounter" class="requests-header-filter-counter">
                    {{ $refs.refMyRequestsFilter.filterCounter }}
                </p>
            </div>
        </div>
        
        <div class="my-requests-container">
            <transition name="slide-fade">
                <my-requests-filter 
                    v-show="!currentRequest && openFilter" 
                    :form-responses="responses"
                    :open-filter="openFilter"
                    @update-responses="updateResponses" 
                    @close-filter="openFilter = false" 
                    ref="refMyRequestsFilter"
                ></my-requests-filter>
            </transition>            
            
            <div v-if="loading" class="loading-image">
                <img src="/images/loading-ummense.gif"/>
            </div>

            <div v-if="responses.length > 0 && !currentRequest && !loading" :class="['box-responses', { 'box-responses-filter-active': !currentRequest && openFilter }]">
                <div v-for="(response, index) in responses" 
                    :key="index" 
                    class="response"
                    @click="openRequest(response)"
                >
                    <div class="response-info">
                        <p class="form-name">{{ response.postFormResponse.uWebFormName }}</p>
                        <div class="d-flex">
                            <p class="form-text-data">Solicitação em {{ formatDate(response.createdAt) }}</p>    
                            <div v-if="response.model && response.model.lastInteraction" class="response-info-dot"></div>                   
                            <p v-if="response.model && response.model.completedAt" class="form-text-data">Concluído em {{ formatDate(response.model.completedAt) }}</p>
                            <p v-else-if="response.model && response.model.endedAt" class="form-text-data">Cancelado em {{ formatDate(response.model.endedAt) }}</p>
                            <p v-else-if="response.model && response.model.lastInteraction" class="form-text-data">Última interação em {{ formatDate(response.model.lastInteraction) }}</p>
                        </div>
                        <div v-if="response.model && response.model.name && response.model.type == 'projects'" class="d-flex" style="margin-top: 4px;">
                            <u-icon
                                v-if="response.model.status == 'completed'"
                                icon="check-circle"
                                colorClass="green5"
                                :width="12"
                                :height="12"
                                style="margin-right: 4px;"
                            ></u-icon>
                            <u-icon
                                v-if="response.model.status == 'canceled'"
                                icon="cancel-circle"
                                colorClass="grey5"
                                :width="12"
                                :height="12"
                                style="margin-right: 4px;"
                            ></u-icon>
                            <p class="form-text-data">{{ response.model.name }}</p>    
                        </div>
                        
                    </div>

                    <div class="form-date">
                        <i class="icon icon-right-arrow grey4 icon12"></i>
                    </div>
                </div>
            </div>

            <div v-if="currentRequest && !loading" class="selected-response">
                <div class="response-header">
                    <div class="header-info">
                        <u-avatar
                            class="header-info-avatar"
                            :user="currentRequest.user"
                            :size="4"
                        ></u-avatar>

                        <div>
                            <p
                                class="user-name"
                                v-html="currentRequest.user.name"
                            ></p>

                            <p
                                class="user-date"
                                v-html="postDate(currentRequest.createdAt)"
                            ></p>
                        </div>
                    </div>
                </div>

                <div v-for="(response, index) in currentRequest.postFormResponse.response" :key="index" class="response">
                    <p
                        class="response-question"
                        v-html="response.field_label"
                    ></p>

                    <p
                        v-if="!responseArray(response)"
                        class="response-awnser"
                        v-html="formatFieldResponse(response)"
                    ></p>

                    <div v-if="responseArray(response)" class="archives">
                        <a
                            :href="archive.link"
                            class="archive-link"
                            target="_blank"
                            v-for="(archive, index) in response.field_value" :key="index"
                            v-html="archive.name"
                        ></a>
                    </div>
                </div>

                <div v-if="currentRequest.comments.length > 0" class="box-comments">
                    <div class="comment" v-for="(comment, index) in currentRequest.comments" :key="index">
                        <div class="comment-header no-align">
                            <div class="comment-user-logo">
                                <u-avatar
                                    :size="5"
                                    :user="comment.user"
                                ></u-avatar>
                            </div>

                            <div class="comment-info">
                                <div class="comment-info-header">
                                    <p 
                                        class="comment-user-name"
                                        v-html="comment.user.name"
                                    ></p>

                                    <p 
                                        class="comment-user-date"
                                        v-html="postDate(comment.createdAt)"
                                    ></p>
                                </div>

                                <div class="description-box">
                                    <p 
                                        class="comment-description"
                                        v-html="comment.description"
                                    ></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!hasPreference('user', 'canReplyPosts') ? true : preferences.user.canReplyPosts" class="new-comment">
                    <comment-editor
                        class="comment-editor"
                        :post="currentRequest.id"
                        :project="currentRequest.model"
                        toolbarName="my-requests"
                        @updateNewComment="updateNewComment"
                    ></comment-editor>
                </div>
            </div>

            <div class="no-response" v-if="responses.length == 0 && !loading">
                <p> Nenhuma solicitação encontrada. </p>
            </div>
        </div>

        <loading
            :active.sync="loadingResponse"
            color="#208fcf"
            :width="100"
            :height="100"
        ></loading>
    </div>
</template>

<script>
    import { hasPreference } from "@/assets/js/helpers/index.js"
    import { mapActions, mapGetters } from 'vuex'
    import moment from 'moment'
    import 'moment/locale/pt-br' 
    import UAvatar from '@/assets/js/components/UI/avatar/UAvatar'
    import 'vue-loading-overlay/dist/vue-loading.css';
    import Loading from 'vue-loading-overlay';
    import CommentEditor from '@/assets/js/components/feed/CommentEditor'
    import MyRequestsFilter from './MyRequestsFilter'

    export default {
        components: { 
            UAvatar,
            Loading,
            CommentEditor,
            MyRequestsFilter,
        },

        data() { 
            return { 
                responses: [],
                currentRequest: null,
                loadingResponse: false,
                loading: false,
                openFilter: false,
            }
        },

        computed: { 
            ...mapGetters("Auth", {
                preferences: "getPreferences"
            }),

            ...mapGetters("Auth", {
                slug: "getSlug",
            }),

            filterCounter() {
                if (!this.openFilter && this.$refs.refMyRequestsFilter && this.$refs.refMyRequestsFilter.filterCounter) {
                    return this.$refs.refMyRequestsFilter.filterCounter
                }

                return 0
            }
        },

        created() { 
            this.updateResponses()
        },

        methods: {
            hasPreference,

            ...mapActions("Forms", [
                "getFormsResponses",
                "getFormResponse"
            ]),
            
            responseArray(response) {
                return Array.isArray(response.field_value)
            },

            updateResponses() { 
                this.loading = true
                let filter = null

                if (this.$refs.refMyRequestsFilter) {
                    filter = this.$refs.refMyRequestsFilter.filter
                }

                this.getFormsResponses(filter).then((data) => { 
                    this.responses = data.formResponses
                    this.loading = false
                }).catch((error) => { 
                    this.loading = false
                })
            },

            updateNewComment() {
                this.openRequest(this.currentRequest)
            },

            postDate(date) {
                return moment(date).fromNow()
            },

            formatDate(date) {
                return moment(date).format('DD/MM/YY');
            },

            openRequest(item) { 
                this.loadingResponse = true

                this.getFormResponse(item.id).then((data) => {
                    this.currentRequest = data.formResponse
                    this.loadingResponse = false
                })
            },

            formatFieldResponse(field) {
                if (typeof field.field_value == 'boolean') {
                    return field.field_value ? 'marcado' : 'desmarcado'
                }

                if (field.field_value != null && typeof field.field_value == 'object' && field.field_value.startDate) {
                    return moment(field.field_value.endDate).format('DD/MM/YY') + ' - ' + moment(field.field_value.startDate).format('DD/MM/YY')
                }

                if (field.field_value != null && typeof field.field_value == 'object' && field.field_value.startTime) {
                    return field.field_value.startTime + ' - ' + field.field_value.endTime
                }

                return field.field_value
            },
        },
    }
</script>

<style lang="scss" scoped>
    .my-requests {
        @media( max-width: 900px ) { 
            width: 100%;
        }
    }

    .requests-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .requests-header-filter-clear {
            font-size: 15px;
            color: $grey5;
            padding: 8px 16px;
            border-radius: 4px;
            transition: all .2s;
            cursor: pointer;

            &:hover {
                background-color: $grey0;
            }
        }

        .requests-header-filter {
            height: 36px;
            width: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            cursor: pointer;
            transition: all .2s;

            &:hover {
                background-color: $blue1;
            }

            &:active {
                background-color: $blue8;
            }

            &:hover .requests-header-filter-icon::v-deep svg path,
            &:active .requests-header-filter-icon::v-deep svg path {
                fill: $blue5;
                color: $blue5;
            }
        }

        .requests-header-filter-counter {
            font-size: 11px;
            padding: 1px 4px;
            margin-left: 2px;
            border-radius: 4px;
            color: $white-color;
            background-color: $blue6;
        }
    }

    .my-requests-container {
        width: 100%;
        min-height: calc(550px - 48px);
        height: fit-content;
        background-color: $white-color;
        padding: 24px 0 24px 0;
        border-radius: 16px;
        max-height: calc(100% - 5rem);
        overflow: scroll;
        
       @media (max-width: 900px) {
            width: 100% !important;
            max-width: 100% !important;
            border-radius: 0px;
            height: calc(100% - 100px);
            position: absolute;
            max-height: unset;
        }

        .loading-image { 
            width: 100%;
            height: 23rem;
            max-height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img { 
                width: 40px;
            }
        }

        .box-responses { 
            -webkit-touch-callout: none;  
            -webkit-user-select: none;  
            -khtml-user-select: none;    
            -moz-user-select: none;     
            -ms-user-select: none;        
            user-select: none; 
            height: 81vh; 
            overflow-y: auto;
            scrollbar-width: thin;

            &.box-responses-filter-active {
                height: 45vh; 
            }

            &::-webkit-scrollbar {
                margin-top: 3px;
                width: 8px;
                height: 1.2rem !important;
            }

            &::-webkit-scrollbar-track {
                background: #fff;
            }

            &::-webkit-scrollbar-thumb {
                background: $grey4;
                border-radius: 50px;
                background-clip: padding-box;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: $grey5;
            }

            .response { 
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 96px;
                padding: 0 24px;
                border-bottom: 1px solid $grey1;

                .response-info { 
                    width: 70%;
                }

                .response-info-dot {
                    height: 4px;
                    width: 4px;
                    margin: 0 8px;
                    border-radius: 50%;
                    background-color: $grey6;
                }

                .form-name { 
                    margin-bottom: 4px;
                    font-weight: 600;
                    font-size: 16px;
                    color: $grey8;
                    max-width: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }

                .form-text-data {
                    font-size: 12px;
                    color: $grey5;
                }

                .form-date { 
                    display: flex;
                    align-items: center;

                    p { 
                        margin-right: 12px;
                    }

                    svg {
                        color: $grey4;
                    }
                }

                &:hover { 
                    background-color: $grey1;
                }

                &:active { 
                    background-color: $blue1;

                    p, svg { 
                        color: $blue5;
                    }
                }
            }
        }

        .selected-response { 
            padding: 0 24px;

            .response-header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;

                .header-info { 
                    display: flex;
                    align-items: center;

                    .header-info-avatar { 
                        margin-right: 12px;
                    }

                    div { 
                        display: flex;
                        flex-direction: column;
                    }

                    .user-name { 
                        color: $grey8;
                        font-weight: 600;
                        font-size: 16px;
                    }

                    .user-date {
                        color: $grey4;
                        font-size: 12px;
                    }
                }

                .button-feed { 
                    cursor: pointer;
                    background-color: $blue1;
                    outline: none;
                    border: 0;
                    border-radius: 4px;
                    width: fit-content;
                    padding: 8px 12px;
                    color: $blue5;

                    &:hover { 
                        color: $white-color;
                        background-color: $blue5;
                    }
                }
            }

            .response { 
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;

                p { 
                    font-size: 16px;
                }

                .response-question {
                    color: $grey7;
                    font-weight: 600;
                    margin-bottom: 12px;
                }

                .response-awnser { 
                    color: $grey7;
                }

                .archives { 
                    display: flex;
                    justify-content: center;
                    flex-direction: column;

                    svg { 
                        display: none;
                    }

                    .archive-link {
                        text-decoration: none;
                        margin-bottom: 8px;
                        color: $blue5;
                        font-size: 16px;

                        &:hover { 
                            color: $blue3;
                        }
                    }
                }
            }

            .box-comments { 
                border-top: 1px solid $grey1;
                width: calc(100% - 24px);
                margin-right: 24px;
                padding-top: 23px;

                .comment { 
                    margin-bottom: 14px;
                }

                .comment-header { 
                    padding-bottom: 16px;
                    display: flex;
                    align-items: center;

                    &.no-align { 
                        margin-bottom: 0;
                        align-items: flex-start;
                    }

                    .comment-user-logo { 
                        margin-right: 12px;

                        img { 
                            height: 100%;
                        }
                    }

                    .comment-info {
                        display: flex; 
                        flex-direction: column;


                        .comment-info-header { 
                            display: flex;
                            align-items: center;

                            .comment-user-date  {
                                color: $grey4;
                                font-size: 12px;
                            }
                        }
                        
                        .comment-user-name { 
                            color: $grey8;
                            font-size: 16px;
                            font-weight: 600;
                            margin-right: 8px;
                        }

                        .comment-date {
                            color: $grey5;
                            font-size: 12px;
                        }
                    }
                }

                .description-box { 
                    margin-top: 8px;
                    background-color: $grey0;
                    max-width: calc(100% - 24px);
                    width: fit-content;
                    border-radius: 0px 16px 16px 16px;
                    padding: 12px;

                    .comment-description { 
                        font-size: 16px;
                        color: $grey8;
                        max-width: 100%;
                        word-wrap: break-word;
                        overflow-wrap: anywhere;
                    }
                }
            }

            .new-comment { 
                max-width: 100%;
                margin-bottom: 24px;

                .comment-editor { 
                    max-width: calc(100% - 28px);
                }
            }
        }

        .no-response {
            padding: 0 24px;
            display: flex;
            align-items: center;

            p { 
                font-size: 16px;
                color: $grey5;
            }
        }

        &::-webkit-scrollbar {
            width: 0; 
        }

        &::-webkit-scrollbar-thumb {
            background: $grey1;
        }
    }

    .slide-fade-enter-active,
    .slide-fade-leave-active {
        transition: all .3s ease;
    }
    
    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateY(-20%);
        opacity: 0;
    }
</style>