<script>
    export default {
        props: {
            tag: {
                type: String,
                default: 'h2'
            },

            size: {
                type: [Number, String],
                default: 1
            },

            color: {
                type: String,
                default: ''
            },

            isResponsive: {
                type: Boolean,
                default: false
            },

            margin: {
                type: Boolean,
                default: true
            },
        },

        render(h) {
            var self = this

            return h('div', [
                h(this.tag, {
                    'class': [this.sizeClass, this.color, this.marginClass],
                    on: {
                        click: function(event) {
                            self.$emit('click')
                        }
                    }
                }, this.$slots.default)
            ])
        },

        computed: {
            sizeClass() {
                return this.isResponsive
                        ? 'mobile-title-' + this.size
                        : 'title-' + this.size
            },

            marginClass() {
                return this.margin
                        ? ''
                        : 'no-margin'
            },
        }
    }
</script>

<style lang="scss" scoped>

h1, h2, h3, h4 {
    font-weight: 600 !important;
}

.title-1 {
    margin-bottom: 0.6rem;
}

.title-2,
.title-3 {
    margin-bottom: 0.4rem;
}

.no-margin {
    margin-bottom: 0;
}

</style>