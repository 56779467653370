<script>
    export default {
        props: {
            tag: {
                type: String,
                default: 'p',
            },

            size: {
                type: [Number, String],
                default: 2,
            },

            color: {
                type: String,
                default: '',
            },

            alignment: {
                type: String,
                default: null,
            },

            weigth: {
                type: String,
                default: null,
            },

            isResponsive: {
                type: Boolean,
                default: false,
            },

            lineHeight: {
                type: Boolean,
                default: true,
            },

            semibold: {
                type: Boolean,
                default: false,
            },

            bold: {
                type: Boolean,
                default: false,
            },

            underline:{
                type: Boolean,
                default: false,
            },

            inline: {
                type: Boolean,
                default: true,
            },

            noMargin:{
                type: Boolean,
                default: true,
            },

            html: {
                type: String,
                default: null,
            },

            uppercase: {
                type: Boolean,
                default: false,
            },

            italic: {
                type: Boolean,
                default: false,
            },

            ellipsis: {
                type: Boolean,
                default: false,
            },
        },

        render(createElement) {
            var self = this
            return createElement('div', [
                createElement(this.tag, {
                    class: [this.sizeClass, this.colorClass, this.alignmentClass, this.weigthClass, this.underlineClass, this.italicClass, this.uppercaseClass, this.inlineClass, this.ellipsisClass, this.noMarginClass],
                    on: {
                        click: function(event) {
                            self.$emit('click')
                        }
                    }
                }, this.$slots.default)
            ])
        },

        computed: {
            sizeClass() {
                if (this.isResponsive) {
                    return 'mobile-text-' + this.size
                }

                if (this.semibold) {
                    return 'text-' + this.size + ' semi-bold'
                }

                if (this.bold) {
                    return 'text-' + this.size + ' bold'
                }

                return 'text-' + this.size
            },

            colorClass() {
                return this.color
            },

            alignmentClass() {
                return this.alignment ? 'text-' + this.alignment : ''
            },

            weigthClass() {
                return this.weigth || ''
            },

            underlineClass() {
                if (this.underline) {
                    return 'underline'
                }
            },

            italicClass() {
                if (this.italic) {
                    return 'italic'
                }
            },

            uppercaseClass() {
                if (this.uppercase) {
                    return 'uppercase'
                }
            },

            inlineClass() {
                if(this.inline) {
                    return 'line-height'
                }
            },

            noMarginClass() {
                if (this.noMargin) {
                    return 'no-margin'
                }
            },

            ellipsisClass() {
                if (this.ellipsis) {
                    return 'ellipsis'
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

.semi-bold {
    font-weight: 600;
}
.bold {
    font-weight: bold;
}
.underline{
    text-decoration: underline;
}
.italic{
    font-style: italic;
}
.uppercase{
  text-transform: uppercase;
}
.line-height{
    line-height: normal;
}
.no-margin{
    margin-bottom: 0 !important;
}
.ellipsis{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

</style>