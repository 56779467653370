<template>
    <div class="request-sucess-message">
        <u-avatar 
            :organization="appSettings"
            :size="1"
            class="mb-7"
        ></u-avatar>

        <p
            v-html="selectedForm.feedback"
        ></p>

        <div class="actions">
            <u-button
                @click="toFeed()"
                text="Voltar para o feed"
                color="#D4EDFA"
                textColor="#208fcf"
            ></u-button>

            <u-button
                class="ml-5"
                @click="closeForm()"
                text="Efetuar nova solicitação"
            ></u-button>
        </div>
    </div>
</template>

<script>
    import UButton from '@/assets/js/components/UI/button/UButton'
    import UAvatar from '@/assets/js/components/UI/avatar/UAvatar'
    import { mapGetters } from 'vuex'

    export default { 
        props: { 
            selectedForm: { 
                required: true,
                type: [Object, Array]
            },
        },

        components: { 
            UButton,
            UAvatar,
        },  

        computed: { 
            ...mapGetters('Auth', {
                appSettings: "getAppSettings",
            }),
        },

        methods: { 
            closeForm() { 
                this.$emit('closeForm')
            },

            toFeed() { 
                this.$router.push({name: 'feed'})
            }
        }
    }
</script>

<style lang="scss" scoped> 
    .request-sucess-message { 
        margin-top: 126px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .actions {
            width: 80%;
            display: flex;
            align-items: center;
        }

        p { 
            margin-bottom: 24px;
        }
    }
</style>