<template>
    <div class="loading">
        <p v-if="!organizationNotFound">
            Verificando Conecta Web...
        </p>

        <div v-else class="organization-not-found">
            <img src="/images/conecta-icon.png"/>

            <h1> Organização não encontrada </h1>

            <p> 
                Não foi possível se Conectar. Verifique o URL inserido ou entre em <br/>
                contato com a organização para solicitar um novo acesso.
            </p>
        </div>
    </div>
</template>

<script>
    import { hasPreference } from "@/assets/js/helpers/index.js";
    import { mapActions, mapGetters, mapMutations } from "vuex"
    import Cookies from 'js-cookie'
    import axios from 'axios'

    export default {
        data() {
            return {
                organizationNotFound: false,
                baseAppUri: process.env.VUE_APP_API_BASE_URI,
                requestTokenBody: {
                    grant_type: process.env.VUE_APP_API_GRANT_TYPE,
                    client_id: process.env.VUE_APP_API_CLIENT_ID,
                    client_secret: process.env.VUE_APP_API_CLIENT_SECRET,
                    scope: process.env.VUE_APP_API_SCOPE,
                },
            }
        },

        computed: {
            ...mapGetters("Auth", {
                slug: "getSlug",
                accessToken: "getToken",
                preferences: "getPreferences"
            }),

            slugOrganization() {
                return this.$route.path
            }
        },

        created() {
            this.updateCurrentSlug()
        },

        methods: {
            hasPreference,

            ...mapActions("Auth", [
                "updateSlug",
                "updateAppSettings",
                "updateToken",
                "updateOrganization",
                "getContact",
                "getTerms",
                "getPreferences"
            ]),


            ...mapMutations('Feed', ['setShowTicketOnCard']),
            ...mapMutations('Auth', ['setStoreLinks']),

            updateCurrentSlug() {
                this.updateSlug(this.slugOrganization).then(() => {
                    this.getApp()
                })
            },

            async getAccessToken() {
                await axios.post(this.baseAppUri + '/oauth/token', this.requestTokenBody)
                .then(({data}) => {
                    this.updateToken(data.access_token)
                })
            },

            async getApp() {
                await this.getAccessToken()

                if (!this.accessToken) return

                this.updateOrganization().then(() => {
                    this.getTerms()
                    this.getAppSettings()
                }).catch(({response}) => {
                    this.organizationNotFound = true
                    return
                })
            },

            getAppSettings() { 
                axios.get(this.baseAppUri + '/api/u-app/v14/u-app-web' + this.slug, {
                    headers: {'Authorization': 'Bearer ' + this.accessToken}
                }).then(({data}) => {
                    this.setStoreLinks({'googlePlayLink': data.result.googlePlayLink, 'appStoreLink': data.result.appStoreLink})
                    this.setAppSettings(data.result.settings)
                    this.setShowTicketOnCard(data.result.showTicketOnCard)
                })
            },

            setAppSettings(settings) {
                if (settings.hasOwnProperty('hasUWeb')) {
                    this.setOrgSettings(settings)
                    return
                }

                this.updateAppSettings(settings).then(() => {
                    this.updatePreferences()
                })
            },
        
            updatePreferences() {
                this.getPreferences().then(() => {
                    if (Cookies.get('user-token')) {
                        this.alredyLogged()
                        return
                    }

                    this.$router.push({name: "login"})
                })
            },

            setOrgSettings(data) {
                let settings = {
                    app_icon: data.logo,
                    company_name: data.name,
                }

                if (data.hasUWeb == false) { 
                    this.updateAppSettings(settings).then(() => {
                        this.$router.push({name: 'unavailable'})
                    })
                    
                    return
                }

                this.setAppSettings(settings)
            },

            alredyLogged() {
                this.getContact(Cookies.get('user-token')).then((data) => {
                    let homeScreen = 
                        hasPreference('app', 'homeScreen') && this.preferences.app.homeScreen == 'form' 
                        ? 'make-requests' 
                        : 'feed'
                    
                    this.$router.push({name: homeScreen ? homeScreen : 'feed'})
                }).catch(() => {
                    this.$router.push({name: "login"})
                })
            },
        },
    }
</script>

<style lang='scss' scoped>
    .loading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .organization-not-found { 
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h1 { 
                font-size: 19px;
                color: $grey8;
            }

            p { 
                text-align: center;
                font-size: 15px;
                color: $grey8;
            }

            img { 
                margin-bottom: 1rem;
                max-width: 8rem;
                opacity: 0.7;
            }
        }
    }
</style>