import store from "../../../../../store/index";
import axios from 'axios'

const PENDING_UPLOAD_STATUS = 'pending';
const SENDING_UPLOAD_STATUS = 'sending';
const COMPLETED_UPLOAD_STATUS = 'completed';
const ERROR_UPLOAD_STATUS = "error";
const CANCELED_REQUEST_UPLOAD_STATUS = 'canceled';

export function S3MultiUpload(file, fileMeta) {
    this.PART_SIZE = 5 * 1024 * 1024 * 1024; // Minimum part size defined by aws s3 is 5 MB, maximum 5 GB
    this.START_UPLOAD_URI =  process.env.VUE_APP_API_BASE_URI + "/api/organization/files/start-upload";
    this.PRE_SIGNED_UPLOAD_URI = process.env.VUE_APP_API_BASE_URI + "/api/organization/files/get-signed-url";
    this.COMPLETE_UPLOAD_URI = process.env.VUE_APP_API_BASE_URI + "/api/organization/files/complete-upload";
    this.ABORT_UPLOAD_URI = process.env.VUE_APP_API_BASE_URI + "/api/organization/files/abort-upload";
    this.completed = false;
    this.storeFile = null;
    this.file = file;
    this.fileMeta = {
        name: this.file.name,
        type: this.file.type,
        size: this.file.size,
        ...fileMeta
    };
    this.sendBackData = null;
    this.uploadXHR = [];
    this.byterate = [];
    this.lastUploadedSize = [];
    this.lastUploadedTime = [];
    this.loaded = [];
    this.total = [];
}

/**
 * Creates the multipart upload
 */
S3MultiUpload.prototype.createMultipartUpload = function() {
    var self = this;
    axios
        .post(self.START_UPLOAD_URI, {
            organizationId: self.fileMeta.organizationId,
            name: self.fileMeta.name,
            size: self.fileMeta.size,
            type: self.fileMeta.type,
            model: self.fileMeta.model
        })
        .then(data => {
            self.sendBackData = data.data.result;
            self.storeFile = {
                uploadId: self.sendBackData.uploadId,
                uploadKey: self.sendBackData.key,
                file: self.file,
                fileMeta: self.fileMeta,
                status: PENDING_UPLOAD_STATUS,
                uploadedSize: 0,
                totalSize: 0,
                byterate: 0
            };

            store.commit("Files/setFilesSent", self.storeFile, {
                root: true
            });
            self.uploadParts();
        })
        .catch(({ response }) => {
            let errorMessage = response.data.message || "Não foi possível realizar o upload";
            store.commit(
                "setAlertMessage",
                {
                    message: errorMessage,
                    error: true
                },
                { root: true }
            );
            this.uploadError(response);
        });
};

/**
 * Call this function to start uploading to server
 */
S3MultiUpload.prototype.start = function() {
    this.createMultipartUpload();
};

/** private */
S3MultiUpload.prototype.uploadParts = function() {
    var blobs = (this.blobs = []),
        promises = [];
    var start = 0;
    var parts = 0;
    var end, blob;
    var partNum = 0;
    var filePart = 0;
    var filePartsSize = [];

    while (start < this.file.size) {
        end = Math.min(start + this.PART_SIZE, this.file.size);
        filePart = this.file.slice(start, end);
        // this is to prevent push blob with 0Kb
        if (filePart.size > 0) {
            blobs.push(filePart);
            filePartsSize.push(filePart.size);
        }

        start = this.PART_SIZE * ++partNum;
    }

    axios
        .post(this.PRE_SIGNED_UPLOAD_URI, {
            sendBackData: this.sendBackData,
            filePartsSizes: filePartsSize
        })
        .then(({ data }) => {
            this.sendAll(data.result.urlList);
        })
        .catch(error => {
            this.setStateUploadError();
            this.uploadError(error);
        })
        .finally(() => {
            this.onPrepareCompleted();
        });
};

S3MultiUpload.prototype.sendAll = function(presignedUrlList) {
    var blobs = this.blobs;
    var length = blobs.length;

    if (length == 1) {
        let presignedUrl = presignedUrlList[0];
        return this.sendToS3(presignedUrl, blobs[0], 0);
    }

    for (var i = 0; i < length; i++) {
        let presignedUrl = presignedUrlList[i];
        this.sendToS3(presignedUrl, blobs[i], i);
    }
};

S3MultiUpload.prototype.sendToS3 = function(presignedUrl, blob, index) {
    var self = this;
    var size = blob.size;
    var request = (self.uploadXHR[index] = new XMLHttpRequest());
    request.onreadystatechange = function() {
        if (request.readyState === 4) {
            // 4 is DONE
            if (request.status !== 200) {
                self.uploadError(request);
                self.setStateUploadError();
                return;
            }

            self.updateProgress(request);
        }
    };

    request.upload.onprogress = function(e) {
        if (self.storeFile.status == CANCELED_REQUEST_UPLOAD_STATUS) {
            return self.cancel();
        }

        if (e.lengthComputable) {
            self.total[index] = size;
            self.loaded[index] = e.loaded;
            if (self.lastUploadedTime[index]) {
                var time_diff =
                    (new Date().getTime() - self.lastUploadedTime[index]) /
                    1000;
                if (time_diff > 0.005) {
                    // 5 miliseconds has passed
                    var byterate =
                        (self.loaded[index] - self.lastUploadedSize[index]) /
                        time_diff;
                    self.byterate[index] = byterate;
                    self.lastUploadedTime[index] = new Date().getTime();
                    self.lastUploadedSize[index] = self.loaded[index];
                }
            } else {
                self.byterate[index] = 0;
                self.lastUploadedTime[index] = new Date().getTime();
                self.lastUploadedSize[index] = self.loaded[index];
            }
            // Only send update to user once, regardless of how many
            // parallel XHRs we have (unless the first one is over).
            if (index == 0 || self.total[0] == self.loaded[0])
                self.updateProgress(request);
        }
    };

    request.open("PUT", presignedUrl, true);
    request.send(blob);
};

S3MultiUpload.prototype.completeMultipartUpload = function() {
    if (this.completed) return;

    let vm = this;
    this.completed = true;

    axios
        .post(vm.COMPLETE_UPLOAD_URI, {
            sendBackData: vm.sendBackData,
            fileMeta: vm.fileMeta
        })
        .then(({ data }) => {
            vm.onUploadCompleted(data.result);
        })
        .catch(error => {
            vm.uploadError(error);
            vm.setStateUploadError();
        });
};

S3MultiUpload.prototype.updateProgress = function(request) {
    var total = 0;
    var loaded = 0;
    var byterate = 0.0;
    var complete = 1;
    for (var i = 0; i < this.total.length; ++i) {
        loaded += +this.loaded[i] || 0;
        total += this.total[i];
        if (this.loaded[i] != this.total[i]) {
            // Only count byterate for active transfers
            byterate += +this.byterate[i] || 0;
            complete = 0;
        }
    }
    if (complete && request.readyState === 4 && request.status === 200 ) {
        this.completeMultipartUpload();
    }

    this.onProgressChanged(loaded, byterate);
};



S3MultiUpload.prototype.onProgressChanged = function(uploadedSize, byterate) {    
    this.storeFile.status = SENDING_UPLOAD_STATUS;
    this.storeFile.uploadedSize = uploadedSize;
    this.storeFile.totalSize = this.fileMeta.size;
    this.storeFile.byterate = byterate;

    store.commit("Files/updateFilesSent", this.storeFile, {
        root: true
    });
};

S3MultiUpload.prototype.onUploadCompleted = function(serverData) {
    this.storeFile.status = COMPLETED_UPLOAD_STATUS;
    this.storeFile.file = serverData.file;

    store.commit("Files/updateFilesSent", this.storeFile, {
        root: true
    });

    this.uploadSuccess(serverData.file);
};

S3MultiUpload.prototype.cancel = function() {
    for (var i = 0; i < this.uploadXHR.length; ++i) {
        this.uploadXHR[i].abort();
    }

    // store.commit("Files/removeFilesSent", this.storeFile, {
    //     root: true
    // });

    axios.post(this.ABORT_UPLOAD_URI, this.sendBackData)
};

S3MultiUpload.prototype.setStateUploadError = function() {
    if (this.storeFile.status == CANCELED_REQUEST_UPLOAD_STATUS) {
        return;
    } 
    
    this.storeFile.status = ERROR_UPLOAD_STATUS;

    store.commit("Files/updateFilesSent", this.storeFile, {
        root: true
    });
};

S3MultiUpload.prototype.onPrepareCompleted = function() {};

S3MultiUpload.prototype.uploadSuccess = function() {};

S3MultiUpload.prototype.uploadError = function() {};
