export default {
    setSelectedProject(state, value) {
        state.selectedProject = value
    },

    setShowTicketOnCard(state, value) {
        state.showTicketOnCard = value
    },

    setFilterCardStatus(state, value) {
        state.filterCardStatus = value
    },

    setExceptCompanies(state, value) {
        state.exceptCompanies = value
    },

    toogleExceptCompanies(state, companyId) {
        const index = state.exceptCompanies.findIndex(selectedCompanyId => selectedCompanyId == companyId)

        if (index > -1) {
            state.exceptCompanies.splice(index, 1)
            return
        }

        state.exceptCompanies.push(companyId)
    },    
};
