<template>
    <div class="projects-carroussel"> 
        <div @click="selectProject('clear')" :class="['project general-feed', {'selected' : projectSelected == null}]"> 
            <u-icon
                icon="feed"
                class="feed-icon"
                :colorClass="projectSelected == null ? 'grey8' : 'grey5'"
                :width="20"
                :height="20"
            ></u-icon>

            <p> Feed geral </p>
        </div>

        <div @click="selectProject(project)" 
            v-for="(project, index) in projects" 
            :key="index" 
            :class="['project', {'selected' : projectSelected == project.id}]"
        >
            <p
                v-html="project.name"
            ></p>
        </div>

        <div @click="getProjects" :class="['button-more-projects', {'disable-pagination' : pagination == null}]">
            <u-icon
                icon="more-options-h"
                colorClass="blue5"
                class="option-icon"
                :width="20"
                :height="20"
            ></u-icon>
        </div>
    </div>
</template>

<script>
    export default { 
        data() { 
            return { 
                projectSelected: null,
            }
        },

        props: { 
            projects: { 
                required: true,
                type: [Object, Array]
            },

            pagination: { 
                required: true,
            }
        },

        methods: { 
            selectProject(project) {
                this.projectSelected = project.id

                this.$emit('filterProjects', project)
            },

            getProjects() { 
                this.$emit('getProjects')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .projects-carroussel { 
        display: flex;
        align-items: center;
        overflow-x: scroll;
        -webkit-touch-callout: none;  
        -webkit-user-select: none;  
        -khtml-user-select: none;    
        -moz-user-select: none;     
        -ms-user-select: none;        
        user-select: none;  

        &:hover { 
            &::-webkit-scrollbar-thumb {
                background: $grey3;
            }
        }

        &::-webkit-scrollbar {
            width: 0px;
            height: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background: $grey1;
            border-radius: 10px;
        }

        .project { 
            cursor: pointer;
            height: 100px;
            min-width: 168px;
            max-width: 168px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background-color: $white-color;
            margin-right: 8px;
            border-radius: 16px;

            &.selected { 
                p, svg { 
                    color: $grey8;
                }
            }

            &.general-feed { 
                flex-direction: column;
                justify-content: center;
                min-width: 100px;
                width: 100px;

                .feed-icon { 
                    margin-bottom: 8px;
                }
            }

            &:hover { 
                background-color: $grey0;
            }

            p { 
                margin: 0 12px;
                color: $grey5;
                font-size: 14px;
                max-height: 100%;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
                display: inline-block;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }

        .button-more-projects { 
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 30px;
            min-height: 30px;
        }
    }
</style>