<template>
    <div class="contact-data">
        <div class="page-column">
            <contact-info   
            ></contact-info>

            <!-- <contact-notification-preferences
            ></contact-notification-preferences> -->
        </div>

        <div class="page-column right">
            <div class="change-contact-data">
                <p class="contact-data-title"> Gerenciar meus dados </p>

                <p class="contact-data-description"> 
                    Editar ou incluir novos dados e definir quais
                    dados são compartilhados com a organização.
                </p>

                <router-link class="button-contact" :to="{name: 'contact-data-management'}">
                    <u-button
                        text="Gerenciar dados"
                        color="transparent"
                    ></u-button>
                </router-link>
            </div>

            <div style="margin-top: 24px;" class="terms-and-politics">
                <p class="contact-data-title"> Termos e políticas </p>

                <div class="terms-status">
                    <i class="far fa-file-alt"></i>
                </div>
                
                <p class="terms-version"> 
                    Versão {{ currentTerms.version }}
                </p>

                <u-button
                    @click="dialogTerms = true"
                    class="button-terms"
                    text="Visualizar ou revogar termos"
                    color="transparent"
                ></u-button>
            </div>

            <contact-change-password
            ></contact-change-password>
        </div>

        <modal-terms
            v-if="dialogTerms"
            :dialog="dialogTerms"
            :isLogged="true"
            :onlyDecline="true"
            :hasCloseButton="true"
            @closeModal="dialogTerms = false"
        ></modal-terms>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import ContactInfo from './ContactInfo'
    import ContactNotificationPreferences from './ContactNotificationPreferences'
    import ContactChangePassword from './ContactChangePassword'
    import UButton from '@/assets/js/components/UI/button/UButton'
    import ModalTerms from '@/assets/js/modules/auth/ModalTerms'

    export default {
        components: { 
            ContactInfo,
            ContactNotificationPreferences,
            ContactChangePassword,
            UButton,
            ModalTerms
        },

        data() { 
            return { 
                dialogTerms: false,
            }
        },

        computed: { 
            ...mapGetters("Auth", { 
                currentTerms: "getTerms"
            })
        }
    }
</script>

<style lang="scss" scoped>
    .contact-data { 
        width: 100%;
        display: flex;
        justify-content: center;
        overflow: auto;

        @media (max-width: 900px) { 
            flex-direction: column;
            margin-top: 0;
            padding: 24px 0;
            justify-content: flex-start;
        }

        .page-column { 
            display: flex;
            flex-direction: column;
            align-items: center;

            &.right { 
                margin-left: 24px;

                @media (max-width: 900px) { 
                    margin-left: 0;
                }
            }
        }

        .change-contact-data,
        .terms-and-politics { 
            width: 370px;
            padding: 24px;
            background-color: $white-color;
            border-radius: 8px;
            display: flex;
            flex-direction: column;

            @media (max-width: 900px) { 
                width: calc(100% - 48px);
            }

            .contact-data-title { 
                font-size: 19px;
                font-weight: 600;
                color: $grey8;
            }

            .contact-data-description { 
                margin-top: 16px;
                font-size: 13px;
                color: $grey8;
            }

            .button-contact {
                text-decoration: none;

                button { 
                    margin-top: 18px; 
                    color: $blue5;

                    &:hover { 
                        background-color: $blue1;
                    }
                }
            }

            .terms-status { 
                margin-top: 16px;
                width: 100%;
                display: flex;
                justify-content: center;

                svg { 
                    font-size: 40px;
                    color: $blue5;
                }
            }

            .terms-version { 
                font-weight: 600;
                margin-top: 14px;
                color: $grey8;
                font-size: 15px;
                text-align: center;
            }

            .button-terms { 
                margin-top: 18px; 
                color: $blue5;

                &:hover { 
                    background-color: $blue1;
                }
            }
        }
    }
</style>