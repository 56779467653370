<template>
    <div class="feed">
        <div v-if="loadingFeed">
            <u-ghost-loader height="22px" width="98px" :has-opacity="false" class="mb-4"></u-ghost-loader>
            <u-ghost-loader height="555px" radius="16px"></u-ghost-loader>
        </div>

        <div v-else>
            <div class="mb-4">
                <u-title size="2" color="grey8" :margin="false">{{ feedTitle }}</u-title>

                <div v-if="selectedProject" class="feed-card-data">
                    <v-popover v-if="showTicketOnCard" trigger="hover" placement="bottom-end">
                        <div class="feed-card-data-ticket" @click="copyCardTicket">
                            <i class="icon icon-ticket icon10 grey4 mr-2"></i>
                            <u-text size="3" color="grey4">{{ selectedProject.ticket }}</u-text>
                        </div>

                        <div slot="popover" v-close-popover class="feed-card-ticket-popover">
                            <u-text size="3">Copiar número identificador.</u-text>
                        </div>
                    </v-popover>

                    <div 
                        v-if="selectedProject.status == 'completed' || selectedProject.status == 'canceled'" 
                        :class="[
                            'feed-card-data-status',
                            { 'bg-green1': selectedProject.status == 'completed' },
                            { 'bg-grey2': selectedProject.status == 'canceled' },
                        ]"
                    >
                        <i :class="[
                                'icon icon12 mr-1',
                                { 'icon-check-circle-fill-1 green5': selectedProject.status == 'completed' },
                                { 'icon-cancel-circle-fill grey4': selectedProject.status == 'canceled' },
                            ]"
                        >
                        </i>

                        <u-text size="3" class="ml-2" :color="selectedProject.status == 'completed' ? 'green5' : 'grey4'">{{ selectedProject.status == 'completed' ? 'Concluído' : 'Cancelado' }}</u-text>
                    </div>
                </div>
            </div>

            <feed-editor
                class="feed-create-post mb-4"
                :filteredProject="selectedProject"
                :projectsFilter="projectsFilter"
                @updatePosts="addNewPost"
            ></feed-editor>

            <post v-for="post in timelinePosts" :key="post.id" :post="post"></post>

            <div class="empty-posts" v-if="!timelinePosts.length">
                <u-text>Nenhum post foi encontrado</u-text>
            </div>

            <div v-if="pagination" @click="getCurrentPosts(true)" class="feed-loading-pagination">
                <u-text v-if="!loading" size="3" color="blue5">Carregar mais posts</u-text>
                <img v-else src="/images/loading-ummense.gif"/>
            </div>
        </div>            
    </div>
</template>

<script>
    import {mapGetters, mapActions, mapState} from 'vuex'
    import FeedEditor from '@/assets/js/components/feed/FeedEditor'
    import ModalProjectFilter from './ModalProjectFilter'
    import ProjectsCarroussel from './ProjectsCarroussel'
    import Post from './Post'

    export default {
        components: {
            ModalProjectFilter,
            ProjectsCarroussel,
            FeedEditor,
            Post
        },
        
        computed: {
            ...mapGetters('Auth', {
                appContact: 'getContact',
                appSettings: "getAppSettings",
                preferences: "getPreferences",
                accessToken: 'getToken'
            }),

            ...mapState('Auth', [
                'contact'
            ]),

            ...mapState('Feed', [
                'selectedProject', 
                'showTicketOnCard'
            ]),

            feedTitle() {
                if (this.selectedProject) {
                    return this.selectedProject.name ?? 'Card sem nome'
                }

                return 'Feed geral'
            },
        },

        data() { 
            return {
                timelinePosts: [],
                pagination: null,
                paginationProjects: 1,
                dialogProjectFilter: false,
                project: null,
                projectsFilter: [],
                loading: false,
                modalComments: [],
                loadingFeed: false,
            }
        },

        mounted() {
            document.querySelector('.app-body').addEventListener('scroll', this.nextPage)
            this.getCurrentPosts()
        },

        destroyed() {
            document.querySelector('.app-body').removeEventListener('scroll', this.nextPage)
        },

        methods: { 
            ...mapActions("Feed", [
                "getPosts",
                "getPostsFiltered",
                "getProjects",
                "getPostComments",
            ]),

            getCurrentPosts(isPagination = false) {
                if (this.loading || this.loadingFeed) {
                    return
                }

                if (isPagination) {
                    this.loading = true
                } else {
                    this.timelinePosts = []
                    this.pagination = 1
                    this.loadingFeed = true
                }
                
                let payload = {
                    pagination: this.pagination,
                    projectId: this.selectedProject ? this.selectedProject.id : null
                }

                this.getPosts(payload).then((data) => {
                    data.posts.forEach(post => {
                        if (!post.id && !post.file) {
                            post.file = { 
                                type: 'jpg',
                                link: '/images/cover-image-large.jpg'
                            }
                        }

                        if (!post.id && !post.user.avatar) { 
                            post.user.avatar = this.preferences.app.logo ?? this.appSettings.app_icon
                        }

                        post.user = post.user ?? {
                            name: 'Umme-bot'
                        }

                        if (post.comments) { 
                            post.comments.forEach(comment => {
                                comment.user = comment.user ?? {
                                    name: 'Desconhecido'
                                }
                            })
                        }
                    })
                    
                    this.timelinePosts.push(...data.posts)
                    this.pagination = data.pagination.nextPage
                    this.loading = false
                }).finally(() => {
                    this.loading = false
                    this.loadingFeed = false
                })
            },

            nextPage() {
                if (this.loading || !this.pagination) {
                    return
                }

                const element = document.querySelector('.app-body')

                if (element && element.scrollTop == element.scrollHeight - element.offsetHeight ) {
                    this.getCurrentPosts(true)
                }
            },

            addNewPost(posts) { 
                this.timelinePosts.unshift(posts)
            },

            filterProjects(project) { 
                if (project == 'clear') {
                    this.pagination = null
                    this.project = null
                    this.getCurrentPosts()
                    this.dialogProjectFilter = false
                    return
                }

                this.dialogProjectFilter = false
                this.project = project
                this.pagination = null

                this.getCurrentPosts()
            }, 

            copyCardTicket() {
                this.$copyText(this.selectedProject.ticket)
                this.$toast.open({ message: 'Identificador do card copiado com sucesso!' })
            }
        },

        watch: {
            selectedProject() {
                this.getCurrentPosts()
            }
        }
    }
</script>

<style lang='scss' scoped>

.feed-card-data {
    display: flex;
    align-items: center;
    margin-top: $spacing-3;

    .feed-card-data-status {
        height: 24px;
        display: flex;
        align-items: center;
        padding: 0 6px;
        border-radius: 50px;
    }

    .feed-card-data-ticket {
        display: flex;
        align-items: center;
        padding: 0 $spacing-2;
        margin-right: $spacing-3;
        border-radius: 4px;
        transition: all .2s;
        cursor: pointer;

        &:hover {
            background-color: $grey2;
        }
    }
}

.feed-loading-pagination {
    text-align: center;
    margin-top: $spacing-2;
    cursor: pointer;

    img {
        height: 24px;
        width: 24px;
    }
}

.feed-card-ticket-popover {
    padding: $spacing-4;
    background-color: $white-color;
    box-shadow: 0px 0px 4px rgba(16, 35, 51, 0.15);
    border-radius: 0px 8px 8px 8px;
}

</style>