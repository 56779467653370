<template>
    <div :class="['loading', { 'loading-opacity': hasOpacity }]" :style="loadingStyle"></div>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            default: '100%'
        },
        height: {
            type: String,
            default: '30px'
        },

        radius: {
            type: String,
            default: '8px'
        },

        hasOpacity: {
            type: Boolean,
            default: true
        },
    },
    computed: {
        loadingStyle() {
            return `width: ${this.width}; height: ${this.height}; border-radius: ${this.radius};`
        }
    },
}
</script>


<style lang="scss" scoped>

.loading {
    cursor: pointer;
    background-color: #fff;
        
    &.loading-opacity {
        background-color: transparent;
        background: linear-gradient(180deg, #fff 0%, #fbfcfd 15%, #F6F6F7 40%, #fff 80%);
        animation: loadingAnimation .8s infinite linear;
    }
}

@keyframes loadingAnimation {
    0% {
        background-position: 0 -300px;
    }

    50% {
        background-position: 0 100px;
    }

    100% {
        background-position: 0 500px;
    }
}

</style>
