import axios from 'axios'
import store from '@/store/index'

export default { 
    getProjects: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            let apiUrl = store.state.Auth.baseAppUri + '/contacts/' + store.state.Auth.contact.id + '/projects?page=' + payload.page

            axios.get(apiUrl, { 
                params: { 
                    search: payload.search, 
                    status: payload.status,
                    sortBy: payload.sortBy,
                    exceptCompanies: payload.exceptCompanies,
                }, 
                headers: {'Authorization': 'Bearer ' + store.state.Auth.token }
            })
            .then(({ data }) => {
                resolve(data)
            }).catch((error) => {
                reject({error})
            })
        })
    },  

    sendPost: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.post(store.state.Auth.baseAppUri + '/contacts/' + store.state.Auth.contact.id + '/new-post', payload,
                {headers: {'Authorization': 'Bearer ' + store.state.Auth.token}
            }).then((data) => {
                resolve(data.data)
            }).catch((error) => {
                console.log('catch', error)
                reject({error})
            })
        })
    },
    
    sendComment: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.post(store.state.Auth.baseAppUri + '/contacts/' + store.state.Auth.contact.id + '/new-comment', 
                {comment: payload.comment, postId: payload.postId},
                {headers: {'Authorization': 'Bearer ' + store.state.Auth.token}
            }).then((data) => {
                resolve(data.data)
            })
        })
    },

    getPosts: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            let apiUrl = store.state.Auth.baseAppUri + '/contacts/' + store.state.Auth.contact.id + '/posts'

            if (payload.pagination != null) { 
                apiUrl = apiUrl + '?page=' + payload.pagination
            }

            axios.get(apiUrl, 
                { params: { projectId: payload.projectId, isUWeb: true }, 
                headers: {'Authorization': 'Bearer ' + store.state.Auth.token }}
            ).then((data) => {
                resolve(data.data)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    getPost: ({commit}, logId) => {
        return new Promise((resolve, reject) => {
            let apiUrl = store.state.Auth.baseAppUri + '/organizations/' + store.state.Auth.orgId + '/posts/' + logId

            axios.get(apiUrl, {
                headers: {'Authorization': 'Bearer ' + store.state.Auth.token }}
            ).then(({data}) => {
                resolve(data.result.post)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    getPostComments: ({commit}, logId) => {
        return new Promise((resolve, reject) => {
            let apiUrl = store.state.Auth.baseAppUri + '/contacts/' + store.state.Auth.contact.id + '/posts/' + logId + 'comments'

            if (payload.pagination != null) { 
                apiUrl = apiUrl + '?page=' + payload.pagination
            }

            axios.get(apiUrl, {
                headers: {'Authorization': 'Bearer ' + store.state.Auth.token }}
            ).then(({data}) => {
                resolve(data.result)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    getNotifications: ({commit}, payload) => { 
        return new Promise((resolve, reject) => {
            axios.get(store.state.Auth.baseAppUri + '/contacts/' + store.state.Auth.contact.id + '/notifications', {
                headers: {'Authorization': 'Bearer ' + store.state.Auth.token}
            }).then((data) => {
                resolve(data.data)
            })
        })
    },

    getPostComments: ({commit}, payload) => { 
        return new Promise((resolve, reject) => {
            axios.get(store.state.Auth.baseAppUri + '/contacts/' + store.state.Auth.contact.id + '/posts/' + payload + '/comments', {
                headers: {'Authorization': 'Bearer ' + store.state.Auth.token}
            }).then((data) => {
                resolve(data.data.result.comments)
            })
        })
    } ,

    getCompanies: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            let apiUrl = store.state.Auth.baseAppUri + '/contacts/' + store.state.Auth.contact.id + '/companies'

            axios.get(apiUrl, { 
                headers: {'Authorization': 'Bearer ' + store.state.Auth.token }
            })
            .then(({ data }) => {
                resolve(data)
            }).catch((error) => {
                reject({error})
            })
        })
    }, 
}