<template>
    <div :class="['u-thumbnail', {'u-thumb-radius' : hasRadius}, {'is-drive' : isDrive}]">
        <div  v-if="image" :style="imageSizeStyle">
            <div class="image-container" @mouseenter="showArrow = true" @mouseleave="showArrow = false" v-on-clickaway="closeDropdown">

                <div v-if="showArrow && isFeed" @click.stop="clickDropdown" class="arrow-drop">
                    <u-icon icon="arrow-down" colorClass="white" :width="12" :height="12"></u-icon>
                </div>

                <transition name="fade" v-if="showImageDropdown">
                    <ul class="file-dropdown">
                        <li @click="openFile">
                            <u-icon icon="eye" colorClass="grey5" :width="14" :height="14"></u-icon>
                            <p>Visualizar</p>
                        </li>
                        <li @click.stop="openOnNewWindow">
                            <u-icon icon="open-outside" colorClass="grey5" :width="14" :height="14"></u-icon>
                            <p>Abrir em nova aba</p>
                        </li>
                        <li @click.stop="downloadFile">
                            <u-icon icon="download-2" colorClass="grey5" :width="14" :height="14"></u-icon>
                            <p>Download</p>
                        </li>
                    </ul>
                </transition>

                <img :src="file.link" :class="{ 'h-100' : isCommentFeed }"/>
            </div>
        </div>

        <div v-if="!isFeed && !isDrive && file.type == 'pdf'" class="file-viewer" ref="pdfViewer">
            <iframe :src="file.link" frameborder="0"></iframe>
        </div>

        <div v-show="!this.$refs.pdfViewer" v-if="!image" :class="['is-document', {'is-feed' : isFeed}]" :style="applyBackground">
            <div class="is-document-icon-name">
                <u-icon
                    :icon="getThumbnailIcon(file, file.type, tinyIcon)"
                    colorClass="white"
                    class="option-icon"
                    :width="44"
                    :height="44"
                ></u-icon>

                <u-text v-if="isFeed" size="3" color="white1" class="ml-3">{{ fileName }}</u-text>
                <p class="text" style="color: #fff;" v-if="!isFeed && !isDrive && file.type != 'pdf'"> {{ file.name + '.' + file.type }} </p>
            </div>

            <div style="position: relative;" v-on-clickaway="closeDropdown">
                <div @click.stop="clickDropdown" v-if="hasButton" class="arrow-drop">
                    <u-icon icon="arrow-down" colorClass="white" :width="12" :height="12"></u-icon>
                </div>
    
                <transition name="fade" v-if="showFileDropdown">
                    <ul class="file-dropdown">
                        <li @click="openFile">
                            <u-icon icon="eye" colorClass="grey5" :width="14" :height="14"></u-icon>
                            <p>Visualizar</p>
                        </li>
                        <li @click.stop="openOnNewWindow">
                            <u-icon icon="open-outside" colorClass="grey5" :width="14" :height="14"></u-icon>
                            <p>Abrir em nova aba</p>
                        </li>
                        <li @click.stop="downloadFile">
                            <u-icon icon="download-2" colorClass="grey5" :width="14" :height="14"></u-icon>
                            <p>Download</p>
                        </li>
                    </ul>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import UButton from '@/assets/js/components/UI/button/UButton'
    import { mixin as clickaway } from 'vue-clickaway';
    import axios from 'axios'

    export default {
        mixins: [clickaway],

        components: { 
            UButton
        },

        props: {
            file: {
                required: true,
            },

            tinyIcon: {
                default: false,
                type: Boolean
            },

            hasRadius: { 
                default: false,
                type: Boolean,
            },

            hasButton: {
                default: false,
                type: Boolean,
            },

            isFeed: { 
                default: false,
                type: Boolean,
            },

            isCommentFeed: { 
                default: false,
                type: Boolean,
            },

            isDrive: { 
                default: false,
                type: Boolean,
            },

            width: { 
                default: '',
                type: String,
            },

            height: { 
                default: '',
                type: String,
            },
        },

        data() {
            return {
                fileType: null,
                fileBackground: '#59abda',
                showFileDropdown: false,
                showImageDropdown: false,
                showArrow: false,
                allowClickAway: false,
            }
        },

        computed: { 
            fileName() {
                return this.file.name + '.' + this.file.type
            },

            image() { 
                if (this.file.type == 'png' 
                || this.file.type == 'jpg' 
                || this.file.type == 'png'
                || this.file.type == 'gif'
                || this.file.type == 'svg'
                || this.file.type == 'jpeg'
                || this.file.type == 'bmp') {
                    return true
                } 
                
                return false
            },

            imageSizeStyle() {
                let width = this.width ? `width: ${this.width};` : ''
                let height = this.height ? `height: ${this.height};` : ''
                return width + height
            },

            applyBackground() {
                let width = this.width ? `width: ${this.width};` : ''
                let height = this.height ? `height: ${this.height};` : ''
                let background = this.fileBackground ? `background-color: ${this.fileBackground};` : ''
                return background + width + height
            }
        },

        methods: {
            getThumbnailIcon(file, type, tinyIcon) {
                switch (type) {
                    case '7z': case 'tgz': case 'zip':
                        this.fileType = 'zip'
                        this.fileBackground = '#FFA800;'
                        break
                    case 'rar':
                        this.fileType = 'rar'
                        this.fileBackground = '#8D4598;'
                        break
                    case 'eps':
                        this.fileType = 'eps'
                        this.fileBackground = '#ECBC13;'
                        break
                    case 'ods': case 'xls': case 'xlsx':
                        this.fileType = 'excel'
                        this.fileBackground = '#00733A;'
                        break
                    case 'csv':
                        this.fileType = 'csv'
                        this.fileBackground = '#00733A;'
                        break
                    case 'odt': case 'xps' : case 'txt':
                        this.fileType = 'txt'
                        this.fileBackground = '#0F9D9E;'
                        break
                    case 'doc': case 'docx':
                        this.fileType = 'word'
                        this.fileBackground = '#2E589A;'
                        break
                    case 'pdf':
                        this.fileType = 'pdf'
                        this.fileBackground = '#E94748;'
                        break
                    case 'odp': case 'ppt': case 'pps': case 'pptx': case 'pot':
                        this.fileType = 'powerpoint'
                        this.fileBackground = '#D0482A;'
                        break
                    case 'indd':
                        this.fileType = 'indesign'
                        this.fileBackground = '#FF365D;'
                        break
                    case 'ai':
                        this.fileType = 'illustrator'
                        this.fileBackground = '#F88800;'
                        break
                    case 'cdr':
                        this.fileType = 'corel-draw'
                        this.fileBackground = '#5FAA45;'
                        break
                    case 'psd':
                        this.fileType = 'photoshop'
                        this.fileBackground = '#01499B;'
                        break
                    case 'xd':
                        this.fileType = 'xd'
                        this.fileBackground = '#FF26BE;'
                        break
                     case 'amv': case 'avi': case 'f4a': case 'f4b': case 'f4v': case 'flv': case 'm4a': case 'm4b': case 'm4r': case 'm4v': case 'mkv': case 'mov': case 'mp2': case 'mp4': case 'mpe': case 'mpeg': case 'mpg': case 'mpg2': case 'mpg4': case 'mpv': case 'webm': case 'wmv':
                        this.fileType = 'video'
                        this.fileBackground = '#D0482A;'
                        break
                    case '.3gp': case '.aa': case '.aac': case '.aax': case '.act': case '.amr': case '.ape': case '.au': case '.awb': case '.dct': case '.dss': case '.dvf': case '.flac': case '.gsm': case '.iklax': case '.ivs': case '.m4a': case '.m4b': case '.m4p': case '.mmf': case '.mp3': case '.mpc': case '.msv': case '.nmf':case ' .nsf': case '.ogg': case '.vox': case '.wav': case '.wma': case '.wv': case '.webm': case '.8svx':  
                        this.fileType = 'music'
                        this.fileBackground = '#FF005C;'
                        break                                         
                }

                if (this.fileType) {
                    if (tinyIcon) {
                        return this.fileType
                    } else {
                        return this.fileType
                    }
                }

                if (!this.fileType) {
                    if (tinyIcon) {
                        return 'icon icon16 far fa-file text-white'
                    } else {
                        return 'icon icon20 far fa-file text-white'
                    }
                }
            },

            getColor(file, type, tinyIcon) {
                switch (type) {
                    case '7z': case 'tgz': case 'zip':
                        this.fileType = 'zip'
                        break
                    case 'rar':
                        this.fileType = 'rar'
                        break
                    case 'eps':
                        this.fileType = 'eps'
                        break
                    case 'ods': case 'xls': case 'xlsx':
                        this.fileType = 'excel'
                        break
                    case 'csv':
                        this.fileType = 'csv'
                        break
                    case 'odt': case 'xps' : case 'txt':
                        this.fileType = 'txt'
                        break
                    case 'doc': case 'docx':
                        this.fileType = 'word'
                        break
                    case 'pdf':
                        this.fileType = 'pdf'
                        break
                    case 'odp': case 'ppt': case 'pps': case 'pptx': case 'pot':
                        this.fileType = 'powerpoint'
                        break
                    case 'indd':
                        this.fileType = 'indesign'
                        break
                    case 'ai':
                        this.fileType = 'illustrator'
                        break
                    case 'cdr':
                        this.fileType = 'corel-draw'
                        break
                    case 'psd':
                        this.fileType = 'photoshop'
                        break
                    case 'xd':
                        this.fileType = 'xd'
                        break
                    case 'svg':
                        this.fileType = 'svg'
                        break
                }

                if (this.fileType) {
                    if (tinyIcon) {
                        return this.fileType
                    } else {
                        return this.fileType
                    }
                }

                if (!this.fileType) {
                    if (tinyIcon) {
                        return 'icon icon16 far fa-file text-white'
                    } else {
                        return 'icon icon20 far fa-file text-white'
                    }
                }
            },

            openOnNewWindow() {
                this.showFileDropdown = false
                this.showImageDropdown = false
                window.open(this.file.link, '_blank')
            },

            openFile() {
                this.showFileDropdown = false
                this.showImageDropdown = false
                this.$emit('openFile')
            },

            downloadFile () {
                this.showFileDropdown = false
                this.showImageDropdown = false

                var url = this.file.link.replace(
                    'https://storage-homologation.ummense.com/',
                    'https://s3-sa-east-1.amazonaws.com/' + process.env.VUE_APP_API_BASE_FILE + '.ummense.com/'
                )

                axios.get(url, {responseType: 'blob', headers: {'Pragma': 'no-cache', 'Expires': '0', 'Cache-Control': 'no-cache' }}).then(response => {
                    this.forceDownload(response)
                }).catch((error) => {
                    this.$toast.open({
                        message: 'Não foi possivel efetuar o download:',
                        type: 'error',
                    });
                })
            },
            forceDownload(response) {
                var headers = response.headers
                var blob = new Blob([response.data], {type: headers['content-type']})
                var fileLink = document.createElement('a')

                fileLink.href = window.URL.createObjectURL(blob)
                fileLink.download = `${this.file.name}.${this.file.type}`

                fileLink.click()
                fileLink.remove()
            },
            closeDropdown() {
                if (this.allowClickAway) {
                    this.showFileDropdown = false
                    this.showImageDropdown = false
                }
                return
            },
            clickDropdown() {
                this.showFileDropdown = !this.showFileDropdown
                this.showImageDropdown = !this.showImageDropdown
                setTimeout(() => {
                    this.allowClickAway = true
                }, 200)
            }
        }
    }
</script>

<style lang="scss" scoped>

    .text {
        margin-top: 1rem;
    }

    .u-thumbnail { 
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .file-viewer {
            height: 100%;
            width: 100%;

            iframe {
                height: 100%;
                width: 100%;
            }
        }

        &.u-thumb-radius { 
            img, .is-document { 
                border-radius: 1rem;
            }
        }

        &.is-drive { 
            img { 
                max-width: 100%;
            }

            .is-document { 
                p {
                    color: $grey8;
                    font-size: 14px;
                }

                svg { 
                    color: $grey8;
                    font-size: 28px;
                    margin-bottom: 0;
                }
            }
        }

        .image-container {
            position: relative;

            .arrow-drop {
                position: absolute;
                top: 20px;
                right: 20px;
                height: 24px;
                width: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;

                &:hover {
                    background-color: #00000030;
                }
            }
            img { 
                max-width: 100%;
            }

            .file-dropdown {
                position: absolute;
                top: 50px;
                right: 18px;
                background: $grey1;
                padding: 0px;
                border-radius: 4px;
                box-shadow: $shadow-4;
                color: $grey7;
                z-index: 1;
                text-wrap: nowrap;
                min-width: fit-content;

                p { 
                    font-size: 15px;
                    font-weight: 400;
                }

                li { 
                    display: flex;
                    align-items: center;
                    padding: 12px;
                    gap: 8px;

                    &:hover { 
                        background-color: $grey2;
                        border-radius: 4px;
                        color: $blue5;
                    }
                }
            }
        }

        .is-document { 
            width: 100%;
            height: 30rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @media (max-width: 900px) {
                height: 15rem;
            }

            &.is-feed {
                height: 96px;
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                padding: 0 $spacing-5;

                .is-document-icon-name {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                }
            }

            .is-document-icon-name {
                display: flex;
                align-content: center;
                flex-direction: column;
            }

            .option-icon {
                justify-content: center;
            }

            svg { 
                font-size: 2rem;
                margin-bottom: 8px;
            }

            p { 
                font-size: 16px;
                font-weight: 600;
            }
            
            .file-dropdown {
                position: absolute;
                top: 28px;
                right: 0px;
                background: $grey1;
                padding: 0px;
                border-radius: 4px;
                box-shadow: $shadow-4;
                color: $grey7;
                z-index: 1;
                text-wrap: nowrap;
                min-width: fit-content;

                p { 
                    font-size: 15px;
                    font-weight: 400;
                }

                li { 
                    display: flex;
                    align-items: center;
                    padding: 12px;
                    gap: 8px;

                    &:hover { 
                        background-color: $grey2;
                        border-radius: 4px;
                        color: $blue5;
                    }
                }
            }

            .arrow-drop { 
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                transition: all .2s;
                height: 24px;
                width: 24px;

                &:hover {
                    background-color: #00000020;
                }
            }
        }
    }

</style>