<template>
    <div class="modal-form">
        <div class="form-box">
            <div class="modal-box-header">
                <div @click="closeModal" class="button close">
                    <u-icon
                        icon="close"
                        colorClass="hover-white-red5"
                        class="clear-button"
                        :width="20"
                        :height="20"
                    ></u-icon>
                </div>

                <div class="header-actions">
                    <div @click="showComments" class="button action">
                        <u-icon
                            :icon="commentsOn ? 'dialog-fill' : 'dialog'"
                            colorClass="hover-white-blue5"
                            class="clear-button"
                            :width="20"
                            :height="20"
                        ></u-icon>
                    </div>
                </div>
            </div>

            <div class="modal-response">
                <div class="response-content">
                    <div class="response-header">
                        <div class="header-info">
                            <u-avatar
                                class="header-info-avatar"
                                :user="form.user"
                                :size="4"
                            ></u-avatar>

                            <div>
                                <p
                                    class="user-name"
                                    v-html="form.user.name"
                                ></p>

                                <p
                                    class="user-date"
                                    v-html="commentDate(form.createdAt)"
                                ></p>
                            </div>
                        </div>
                    </div>

                    <div v-for="(response, index) in form.postFormResponse.response" :key="index" class="response">
                        <p
                            class="response-question"
                            v-html="response.field_label"
                        ></p>

                        <p
                            v-if="!responseArray(response)"
                            class="response-awnser"
                            v-html="response.field_value"
                        ></p>

                        <div v-if="responseArray(response)" class="archives">
                            <a
                                :href="archive.link"
                                class="archive-link"
                                v-for="(archive, index) in response.field_value" :key="index"
                                v-html="archive.name"
                            ></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="comments-box" id="commentBox" v-if="commentsOn">
            <div v-if="!$isMobile()" class="post-description">
                <div class="post-header">
                    <u-avatar
                        :user="form.user"
                        :size="4"
                    ></u-avatar>

                    <div class="post-header-info">
                        <p
                            class="post-user"
                            v-html="form.user.name"
                        ></p>

                        <p
                            class="post-date"
                            v-html="commentDate(form.createdAt)"
                        ></p>
                    </div>
                </div>
            </div>

            <div v-if="$isMobile()" class="title-mobile"> 
                Comentários 
            </div>

            <div class="comment" v-for="(comment, index) in form.comments" :key="index">
                <div class="comment-info">
                    <u-avatar
                        :user="comment.user"
                        :size="5"
                    ></u-avatar>

                    <p
                        class="comment-name"
                        v-html="comment.user.name"
                    ></p>

                    <p
                        class="comment-date"
                        v-html="commentDate(comment.createdAt)"
                    ></p>
                </div>

                <div class="comment-description">
                    <p
                        v-html="comment.description"
                    ></p>
                </div>
            </div>

            <div v-if="!hasPreference('user', 'canReplyPosts') ? true : preferences.user.canReplyPosts" class="new-comment">
                <comment-editor
                    class="modal-editor"
                    :post="form.id"
                    :project="form.model"
                    toolbarName="modaForm"
                    :noPadding="true"
                    @updateNewComment="updateNewComment"
                ></comment-editor>
            </div>
        </div>
    </div>
</template>

<script>
    import { hasPreference } from "@/assets/js/helpers/index.js"
    import {mapGetters} from 'vuex'
    import UAvatar from '@/assets/js/components/UI/avatar/UAvatar'
    import CommentEditor from '@/assets/js/components/feed/CommentEditor'
    import moment from 'moment'
    import 'moment/locale/pt-br';

    export default {
        props: { 
            dialog: { 
                required: true,
                type: Boolean
            },

            form: { 
                required: true,
                type: [Array, Object]
            }
        },

        components: {
            UAvatar,
            CommentEditor
        },

        data() { 
            return {
                commentsOn: true,
            }
        },

        computed: {
            ...mapGetters("Auth", {
                preferences: "getPreferences"
            }),
        },

        created() {
            if (this.$isMobile()) { 
                this.commentsOn = false
            }
        },

        methods: { 
            hasPreference,

            commentDate(date) {
                return moment(date).fromNow()
            },

            updateNewComment(comment) { 
                this.$emit('updateNewComment', comment, true)
            },

            showComments() { 
                this.commentsOn = !this.commentsOn
            },

            closeModal() { 
                this.$emit('closeModal')
            },

            responseArray(response) {
                if (Array.isArray(response.field_value)) { 
                    return true
                }

                return false
            },
        },
    }
</script>

<style lang="scss" scoped>
    .modal-form {
        position: fixed;
        width: 100%;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 4;
        
        @media (max-width: 900px) { 
            flex-direction: column;
            z-index: 20;
        }
        
        .form-box { 
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            .modal-box-header  {
                z-index: 20;
                background-color: $grey9;
                opacity: 0.8;
                height: 48px;
                position: absolute;
                top: 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 16px;

                &:window-inactive {
                    display: none;
                }

                .button { 
                    cursor: pointer;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                }

                .header-actions {
                    display: flex;
                    align-items: center;
                }
            }
        
            .modal-response { 
                background-color: $white-color;
                width: 100%;
                height: 100%;
                display: flex; 
                justify-content: center;
                flex-direction: column;
                align-items: center;
                padding-top: 80px;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 5px;
                }

                &::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background: $grey1;
                }

                &::-webkit-scrollbar-thumb {
                    background: $grey1;
                }

                .response-content { 
                    max-height: 100%;
                    max-width: calc(100% - 60px);
                    padding: 0 30px;
                }

                .response-header {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;

                    .header-info { 
                        display: flex;
                        align-items: center;

                        .header-info-avatar { 
                            margin-right: 12px;
                        }

                        div { 
                            display: flex;
                            flex-direction: column;
                        }

                        .user-name { 
                            color: $grey8;
                            font-weight: 600;
                            font-size: 16px;
                        }

                        .user-date {
                            color: $grey4;
                            font-size: 12px;
                        }
                    }
                }

                .response { 
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px;

                    p { 
                        font-size: 16px;
                    }

                    .response-question {
                        color: $grey7;
                        font-weight: 600;
                        margin-bottom: 12px;
                    }

                    .response-awnser { 
                        color: $grey7;
                        word-break: break-word;
                    }

                    .archives { 
                        display: flex;
                        justify-content: center;
                        flex-direction: column;

                        svg { 
                            display: none;
                        }

                        .archive-link {
                            text-decoration: none;
                            margin-bottom: 8px;
                            color: $blue5;
                            font-size: 16px;

                            &:hover { 
                                color: $blue3;
                            }
                        }
                    }
                }
            }
        }

        .comments-box { 
            overflow-y: auto;
            height: 100%;
            padding-bottom: 6rem;
            background-color: $white-color;
            min-width: 400px;
            max-width: 400px;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow-x: hidden;
            animation: 0.2s ease-out 0s 1 slideInFromRight;

            @media (max-width: 900px) { 
                position: absolute;
                bottom: 0;
                z-index: 20;
                max-height: 400px;
                min-width: 100%;
            }

            .title-mobile { 
                font-weight: 600;
                margin-top: 23px;
                font-size: 20px;
                color: $grey8;
                text-align: left;
                width: calc(100% - 23px);
                padding-left: 23px;
            }

            .post-description {
                display: flex;
                flex-direction: column;
                width: calc(100% - 46px);
                padding: 23px 23px 0 23px;
                
                .post-header {
                    display: flex;
                    align-items: center;

                    .post-header-info {
                        margin-left: 12px;
                        display: flex;
                        flex-direction: column;

                        .post-user {
                            color: $grey8;
                            font-weight: 600;
                            font-size: 16px;
                        }

                        .post-date {
                            color: $grey4;
                            font-size: 12px;
                        }
                    }
                }
            }

            .comment { 
                width: calc(100% - 46px);
                padding: 23px 23px 0 23px;
                display: flex;
                flex-direction: column;
            }

            .comment-info { 
                display: flex;
                align-items: center;

                p { 
                    margin-left: 0.5rem;
                }
            }

            .comment-name { 
                font-weight: 600;
                font-size: 16px;
                color: $grey8;
            }

            .comment-date { 
                color: $grey4;
                font-size: 12px;
            }

            .comment-description { 
                margin-top: 8px;
                background-color: $grey0;
                border-radius: 0px 16px 16px 16px;
                width: fit-content;
                max-width: calc(100% - 23px);
                padding: 12px;

                p { 
                    max-width: 100%;
                    word-wrap: break-word;
                }
            }

            .new-comment { 
                padding: 1rem 20px 0 20px;
                background-color: $white-color;
                position: absolute;
                bottom: 0;
                width: calc(380px - 40px);

                @media (max-width: 900px) { 
                    position: fixed;
                    bottom: 0;
                    min-width: calc(100% - 40px);
                }
            }

            .modal-editor { 
                width: calc(100% - 28px);
                margin-bottom: 1rem;
            }

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: $grey3;
            }
        }
    }

    @keyframes slideInFromRight {
        0% {
            transform: translateX(200%);
        }
        
        100% {
            transform: translateX(0);
        }
    }
</style>